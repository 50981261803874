<template>
	<div>
		<div class="flex h-screen overflow-hidden bg-gray-100">
			<AdminSidebar v-if="loggedIn" />
			<div class="flex w-0 flex-1 flex-col overflow-hidden">
				<main class="relative flex-1 overflow-y-auto focus:outline-none">
					<router-view />
				</main>
			</div>
		</div>
		<FileUploader v-if="uploadFile" />
		<AlertComponent />
		<SessionTimeoutDialog />
	</div>
</template>
<script>
import AlertComponent from '@/components/utilities/AlertComponent.vue'
import AdminSidebar from '@/views/layout/AdminSidebar'
import FileUploader from '@/components/media/FileUploader'
import SessionTimeoutDialog from '@/components/utilities/SessionTimeoutDialog'
import { mapStores } from 'pinia'
import { useMediaStore } from './stores/media'
import { useUsersStore } from './stores/users'
import { defineComponent } from 'vue'
import { useMainStore } from './stores/main'

export default defineComponent({
	name: 'App',
	async mounted() {
		// this.$store.dispatch('getConfig')
	},
	data: () => ({
		headerRef: null
	}),
	components: {
		AlertComponent,
		AdminSidebar,
		FileUploader,
		SessionTimeoutDialog
	},
	computed: {
		...mapStores(useMediaStore, useUsersStore, useMainStore),
		config() {
			return {}
			// return this.$store.getters.config
		},
		loggedIn() {
			return this.usersStore.isLoggedIn()
		},
		uploadFile() {
			return this.mediaStore.showFileUploader
		}
	},
	methods: {
		setHeader(value) {
			this.headerRef = value
		}
	}
})
</script>
<style>
img {
	image-rendering: -webkit-optimize-contrast;
}

.accordion div:last-of-type .box-title {
	margin-bottom: -1px;
}

.box-content > p {
	margin-bottom: 1rem;
}

/* .box-content ul {
  list-style: none;
  margin-bottom: 1rem;
  margin-left: 1rem;
	--
} */

input:checked + .box .box-content,
input:checked + .box .box-close {
	display: inline-block;
}

input:checked + section.box .box-title .arrow {
	transform: rotate(90deg);
}

.page-body > p {
	margin-bottom: 1rem;
}

.content {
	--link-text-color: rgba(33, 94, 159);
	--link-text-color-hover: rgba(36, 69, 130);
}

.content h1 {
	font-family: 'Cabin', sans-serif;
	line-height: 1;
	font-weight: bold;
	font-size: 1.375rem;
	margin-bottom: 0.5rem;
}
.content h2 {
	line-height: 1;
	margin-bottom: 0.5rem;
	font-weight: bold;
	font-size: 1rem;
}
.content h3 {
	font-family: 'Arimo', sans-serif;
	line-height: 1;
	margin-bottom: 0.5rem;
	font-size: 0.8125rem;
	text-transform: uppercase;
}
.content h4 {
	line-height: 1;
	font-weight: bold;
	margin-bottom: 0.5rem;
	font-size: 0.8125rem;
}
.content h5 {
	line-height: 1;
	font-weight: bold;
	margin-bottom: 0.5rem;
	font-size: 0.75rem;
}
.content h6 {
	line-height: 1;
	font-weight: bold;
	margin-bottom: 0.5rem;
	font-size: 0.625rem;
}

.content a {
	color: var(--link-text-color);
	text-decoration: underline;
}
.content a:hover {
	color: var(--link-text-color-hover);
}

.content > p {
	margin-bottom: 1rem;
}

.content ol {
	list-style: decimal;
	margin-left: 2rem;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.content ul {
	list-style-type: disc;
	margin-left: 2rem;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}
.content li p {
	position: inline;
}

.content table {
	font-size: 0.9rem;
}

.content table td {
	border: 1px solid #ccc;
	min-width: 5rem;
	padding: 0.25rem 0.5rem;
}
.content table th {
	border: 1px solid #333333;
	font-weight: 600;
	min-width: 5rem;
	padding: 0.25rem 0.5rem;
}
</style>
