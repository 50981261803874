import { Amenity } from '@/types/amenities'

export type State = {
	serviceAreas: ServiceArea[]
	amenities: Amenity[]
	active: ServiceArea
}

class Image {
	id?: string | null = null
	path?: string | null = null
}

class Position {
	lat: number | null = null
	lng: number | null = null
}

export class ServiceArea {
	id?: number
	name?: string = ''
	image?: Image = new Image()
	imageId?: number | null = null
	imagePosition?: 'top' | 'center' | 'button' = 'center'
	mile?: string = ''
	content?: string = ''
	position?: Position = new Position()
	amenities?: Amenity[] = []
	published?: string | null = null
}
