<template>
	<div
		class="relative z-10 mx-auto w-full max-w-7xl flex-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
	>
		<div class="mx-auto w-1/2">
			<svg viewBox="0 0 577 525" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M559.078 524.741C568.345 524.741 575.91 517.31 576.051 508.042C576.762 461.849 580.483 393.713 493.49 374.87C423.091 359.621 464.83 234.513 275.238 242.596C139.141 248.398 175.298 363.112 69.4781 372.665C-0.935216 379.022 -1.54321 468.077 1.16212 508.881C1.75412 517.808 9.15815 524.741 18.1035 524.741H559.078Z"
					fill="#CAE6FF"
				/>
				<g opacity="0.550003">
					<g opacity="0.550003">
						<path
							opacity="0.550003"
							d="M493.49 374.87C423.091 359.62 464.831 234.512 275.239 242.595C242.87 243.976 220.256 251.524 203.147 262.346C208.91 261.592 215.028 261.059 221.547 260.782C411.14 252.698 369.402 377.806 439.799 393.055C525.866 411.698 523.14 478.584 522.387 524.74H559.078C568.346 524.74 575.91 517.31 576.052 508.042C576.762 461.848 580.483 393.714 493.49 374.87Z"
							fill="#71B6FF"
						/>
					</g>
				</g>
				<path
					d="M192.028 218.399H195.021V-6.50879e-06H192.028V218.399Z"
					fill="#5AAAFF"
				/>
				<path
					d="M406.659 251.948H409.652V-7.50864e-06H406.659V251.948Z"
					fill="#5AAAFF"
				/>
				<path
					d="M409.366 237.262L186.77 211.28C177.257 210.17 168.645 216.982 167.534 226.494L150.921 368.834C149.81 378.346 156.622 386.958 166.135 388.068L388.73 414.051C398.243 415.16 406.855 408.348 407.965 398.836L424.579 256.496C425.69 246.984 418.878 238.372 409.366 237.262Z"
					fill="#EBF5FF"
				/>
				<path
					d="M422.935 270.586L424.579 256.497C425.69 246.985 418.878 238.371 409.366 237.262L186.77 211.281C177.256 210.17 168.646 216.982 167.535 226.494L165.89 240.583L422.935 270.586Z"
					fill="#007BFF"
				/>
				<path
					d="M227.779 330.49L231.419 299.304C231.445 299.088 231.154 298.993 231.047 299.182L214.375 328.6C214.082 329.118 214.413 329.77 215.005 329.84L226.854 331.222C227.311 331.276 227.726 330.948 227.779 330.49ZM201.903 326.772L230.121 277.057C231.927 273.872 235.467 272.077 239.105 272.502C244.105 273.086 247.685 277.613 247.101 282.613L241.231 332.901L241.482 332.93C244.947 333.334 247.427 336.47 247.023 339.934C246.619 343.4 243.482 345.88 240.018 345.477L239.767 345.446L238.698 354.605C238.261 358.346 234.874 361.026 231.133 360.589C227.391 360.153 224.713 356.765 225.149 353.024L226.218 343.865L205.905 341.494C202.434 341.089 199.949 337.948 200.354 334.477L200.893 329.862C201.019 328.774 201.363 327.724 201.903 326.772"
					fill="#5AAAFF"
				/>
				<path
					d="M352.309 345.026L355.947 313.839C355.974 313.623 355.683 313.527 355.575 313.718L338.905 343.134C338.611 343.654 338.942 344.305 339.534 344.375L351.383 345.758C351.839 345.811 352.255 345.483 352.309 345.026ZM326.433 341.307L354.649 291.591C356.457 288.407 359.995 286.613 363.633 287.038C368.633 287.621 372.214 292.147 371.63 297.147L365.761 347.437L366.011 347.465C369.475 347.87 371.957 351.005 371.553 354.47C371.149 357.935 368.011 360.415 364.547 360.011L364.295 359.982L363.226 369.141C362.79 372.882 359.403 375.561 355.662 375.123C351.921 374.687 349.241 371.301 349.678 367.558L350.747 358.399L330.434 356.03C326.962 355.623 324.478 352.483 324.883 349.011L325.422 344.397C325.549 343.31 325.893 342.259 326.433 341.307"
					fill="#5AAAFF"
				/>
				<path
					d="M278.818 345.681C278.086 351.954 280.568 354.658 284.958 355.172C289.35 355.685 292.388 353.624 293.121 347.35L298.656 299.928C299.388 293.654 296.906 290.949 292.516 290.436C288.124 289.924 285.086 291.985 284.353 298.258L278.818 345.681ZM270.452 297.525C272.092 283.474 280.429 276.309 293.98 277.89C307.529 279.472 313.993 288.365 312.354 302.417L307.024 348.082C305.382 362.134 297.044 369.3 283.494 367.718C269.945 366.137 263.481 357.244 265.121 343.192L270.452 297.525Z"
					fill="#5AAAFF"
				/>
				<path
					d="M192.131 230.95C191.832 233.506 189.519 235.338 186.961 235.039C184.405 234.741 182.575 232.426 182.873 229.87C183.171 227.313 185.485 225.482 188.043 225.781C190.599 226.079 192.429 228.394 192.131 230.95Z"
					fill="white"
				/>
				<path
					d="M214.459 233.556C214.16 236.112 211.846 237.944 209.29 237.645C206.734 237.347 204.903 235.032 205.2 232.476C205.499 229.919 207.814 228.088 210.37 228.387C212.927 228.685 214.758 231 214.459 233.556Z"
					fill="#5AAAFF"
				/>
				<path
					d="M237.603 236.258C237.305 238.814 234.991 240.645 232.434 240.346C229.878 240.049 228.047 237.734 228.346 235.177C228.643 232.621 230.958 230.79 233.515 231.089C236.071 231.387 237.902 233.702 237.603 236.258Z"
					fill="#FFBF4D"
				/>
				<path
					d="M148.058 398.389C148.058 398.389 145.051 411.719 145.553 425.351C146.001 437.552 144.55 517.763 144.55 517.763H154.347C154.347 517.763 168.294 454.941 168.815 449.98C169.335 445.02 176.351 395.408 176.351 395.408L148.058 398.389Z"
					fill="#0056B1"
				/>
				<path
					d="M154.572 394.731C154.572 394.731 156.827 411.869 160.662 424.047C164.495 436.224 183.338 517.763 183.338 517.763H193.135C193.135 517.763 191.331 454.941 190.655 449.981C189.978 445.02 185.016 395.408 185.016 395.408L154.572 394.731Z"
					fill="#007BFF"
				/>
				<path
					d="M184.137 517.747V524.585H202.788C203.847 524.585 204.261 523.213 203.379 522.625C200.652 520.809 196.283 518.306 192.221 517.747H184.137Z"
					fill="#007BFF"
				/>
				<path
					d="M145.049 517.747V524.585H163.699C164.758 524.585 165.173 523.213 164.291 522.625C161.563 520.809 157.194 518.306 153.133 517.747H145.049Z"
					fill="#007BFF"
				/>
				<path
					d="M178.699 315.007C178.699 315.007 185.114 305.267 177.749 304.316C172.094 303.587 169.767 305.271 169.767 305.271C169.767 305.271 163.257 304.553 159.694 307.643C157.251 309.759 148.29 326.173 162.782 332.112C177.274 338.051 174.185 322.847 174.185 322.847L178.699 315.007Z"
					fill="#0056B1"
				/>
				<path
					d="M177.749 304.316C172.094 303.586 169.767 305.27 169.767 305.27C169.767 305.27 163.257 304.553 159.693 307.642C157.251 309.758 148.29 326.173 162.782 332.112C169.594 334.904 172.521 333.022 173.71 330.246C159.961 334.114 158.47 317.542 160.737 312.826C163.041 308.036 168.57 310.246 168.57 310.246C170.731 304.804 177.778 305.394 180.683 305.817C180.154 305.061 179.235 304.508 177.749 304.316Z"
					fill="#05556D"
				/>
				<path
					d="M169.671 319.758C169.671 319.758 167.771 317.858 166.345 319.758C164.921 321.659 165.87 325.935 168.246 326.173C168.246 326.173 167.533 332.35 162.782 332.113V337.101H173.949V333.299C173.949 333.299 180.838 330.449 180.599 324.747C180.362 319.046 178.699 315.007 178.699 315.007C178.699 315.007 174.898 319.283 169.671 319.758Z"
					fill="#F98D3D"
				/>
				<path
					d="M171.38 334.897C172.486 335.357 172.292 336.055 172.342 337.1H173.948V333.3C173.948 333.3 171.242 334.16 167.279 331.901C167.279 331.901 169.586 334.149 171.38 334.897"
					fill="#ED701B"
				/>
				<path
					d="M173.665 310.665C173.665 310.665 167.877 308.36 167.201 310.089C166.759 311.221 167.884 312.196 169.429 313.181L168.067 313.405C167.764 313.455 167.655 313.841 167.893 314.035C168.273 314.341 169.323 314.548 171.511 314.624L173.665 310.665Z"
					fill="#F98D3D"
				/>
				<path
					d="M147.453 391.387L145.489 387.096L139.234 373.428L149.613 361.804L147.748 387.312L147.453 391.387ZM214.993 325.161C203.957 316.983 173.814 310.444 173.814 310.444L171.332 314.969C171.332 314.969 198.696 325.575 196.889 327.155C195.828 328.087 182.456 334.215 177.929 336.276C176.957 336.717 175.897 336.933 174.817 336.915L160.962 336.836C160.962 336.836 150.968 335.647 143.104 344.681C136.073 352.771 124.124 371.504 124.124 371.504L144.252 401.107L146.481 404.376L146.501 404.337L146.481 404.612C146.481 404.612 147.197 404.691 148.464 404.808C154.934 405.408 175.672 407.047 186.648 404.72L186.953 404.651L186.432 356.089C203.957 342.216 212.322 331.504 215.317 328.136C216.104 327.244 215.956 325.879 214.993 325.161"
					fill="#FFBF4D"
				/>
				<path
					d="M145.489 387.096L147.453 391.387L146.501 404.338L146.481 404.376L144.252 401.107L145.489 387.096Z"
					fill="#EFAA3A"
				/>
				<g opacity="0.289993">
					<g opacity="0.289993">
						<path
							opacity="0.289993"
							d="M181.75 354.344C179.886 355.698 180.358 367.708 179.897 375.217C179.126 387.792 178.375 398.673 178.93 405.689C181.77 405.501 184.414 405.194 186.647 404.72L186.953 404.65L186.431 356.09C203.958 342.216 212.323 331.505 215.318 328.137C216.103 327.244 215.955 325.878 214.994 325.161C214.994 325.161 201.139 340.264 181.75 354.344Z"
							fill="#F98D2B"
						/>
					</g>
				</g>
				<path
					d="M190.654 449.981C190.166 446.401 187.448 419.575 185.97 404.913L178.93 405.689C178.93 405.689 189.201 490.131 189.877 517.763H193.134C193.134 517.763 191.33 454.942 190.654 449.981Z"
					fill="#0056B1"
				/>
				<path
					d="M145.508 321.143L143.386 317.337C144.45 315.33 145.058 313.045 145.058 310.615C145.058 302.677 138.622 296.241 130.683 296.241C122.743 296.241 116.308 302.677 116.308 310.615C116.308 318.555 122.743 324.991 130.683 324.991C134.679 324.991 138.292 323.358 140.898 320.725L145.508 321.143Z"
					fill="#007BFF"
				/>
				<path
					d="M123.205 309.317H123.765V311.321C123.765 311.934 123.948 312.198 124.378 312.198C124.716 312.198 124.952 312.029 125.262 311.645V309.317H125.816V312.596H125.357L125.31 312.084H125.289C124.986 312.441 124.662 312.677 124.21 312.677C123.514 312.677 123.205 312.232 123.205 311.396V309.317Z"
					fill="white"
				/>
				<path
					d="M128.952 312.212C129.492 312.212 129.891 311.72 129.891 310.909C129.891 310.188 129.647 309.702 129.027 309.702C128.749 309.702 128.473 309.857 128.149 310.146V311.868C128.447 312.117 128.736 312.212 128.952 312.212ZM128.149 312.873V313.98H127.595V309.317H128.055L128.101 309.694H128.121C128.419 309.445 128.783 309.237 129.168 309.237C130.011 309.237 130.463 309.89 130.463 310.909C130.463 312.022 129.795 312.677 129.047 312.677C128.743 312.677 128.44 312.536 128.135 312.298L128.149 312.873Z"
					fill="white"
				/>
				<path
					d="M131.925 311.853C132.208 312.083 132.498 312.245 132.89 312.245C133.321 312.245 133.537 312.016 133.537 311.732C133.537 311.395 133.146 311.247 132.788 311.112C132.322 310.943 131.81 310.72 131.81 310.167C131.81 309.64 132.229 309.236 132.937 309.236C133.349 309.236 133.706 309.405 133.956 309.607L133.693 309.957C133.47 309.789 133.234 309.668 132.944 309.668C132.533 309.668 132.344 309.891 132.344 310.14C132.344 310.443 132.701 310.565 133.072 310.707C133.544 310.881 134.07 311.077 134.07 311.699C134.07 312.231 133.645 312.677 132.869 312.677C132.404 312.677 131.958 312.481 131.648 312.224L131.925 311.853Z"
					fill="white"
				/>
				<path
					d="M135.803 311.827C136.019 311.827 136.201 312.002 136.201 312.259C136.201 312.501 136.019 312.677 135.803 312.677C135.581 312.677 135.398 312.501 135.398 312.259C135.398 312.002 135.581 311.827 135.803 311.827Z"
					fill="white"
				/>
				<path
					d="M138.157 311.827C138.373 311.827 138.554 312.002 138.554 312.259C138.554 312.501 138.373 312.677 138.157 312.677C137.934 312.677 137.752 312.501 137.752 312.259C137.752 312.002 137.934 311.827 138.157 311.827Z"
					fill="white"
				/>
				<path
					d="M90.3932 493.992C90.3932 493.992 87.3732 491.172 89.3999 480.473C91.4265 469.775 96.5679 459.5 94.0465 447.895C91.5239 436.287 71.7465 407.02 71.6799 405.075C71.6132 403.129 65.5785 439.121 75.8065 453.299C86.0332 467.477 87.2639 472.397 86.8105 479.853C86.3559 487.309 86.9159 493.313 88.0372 495.047L90.3932 493.992Z"
					fill="#5AAAFF"
				/>
				<path
					d="M88.5215 472.474C87.3321 477.247 86.6148 489.214 88.5148 492.078C88.6921 492.346 88.9468 492.194 89.2641 491.699C88.6855 489.722 88.3055 486.254 89.4001 480.474C91.4268 469.775 96.5681 459.501 94.0468 447.894C91.5241 436.286 71.7455 407.021 71.6801 405.074C71.6801 405.074 74.6601 429.458 84.6975 446.241C91.8828 458.257 89.7108 467.702 88.5215 472.474Z"
					fill="#5AAAFF"
				/>
				<path
					d="M94 493.25C94 493.25 88.1907 478.743 98.0373 466.182C107.884 453.622 123.632 437.495 123.945 431.106C123.945 431.106 127.993 461.441 115.176 470.027C102.359 478.613 93.3693 479.983 95.592 493.195L94 493.25Z"
					fill="#5AAAFF"
				/>
				<path
					d="M107.834 463.928C93.542 474.331 92.374 486.314 94.5913 491.871C94.7647 492.303 95.09 492.567 95.522 492.708C93.606 479.956 102.521 478.506 115.175 470.027C127.993 461.44 123.946 431.106 123.946 431.106C123.946 431.106 122.126 453.526 107.834 463.928Z"
					fill="#5AAAFF"
				/>
				<path
					d="M84.444 493.575C84.444 493.575 86.308 485.894 85.316 477.602C84.3253 469.309 75.6613 464.643 67.568 455.883C63.4026 451.378 62.1706 427.545 64.7533 425.33C64.7533 425.33 50.4626 442.649 52.6666 455.329C54.8706 468.01 78.184 476.25 80.3413 482.378C82.5 488.503 82.3213 493.649 82.3213 493.649L84.444 493.575Z"
					fill="#5AAAFF"
				/>
				<path
					d="M59.9232 432.147C55.2005 444.938 60.0419 460.429 75.7312 470.05C87.2832 477.134 83.6045 491.863 83.6045 491.863L85.4912 486.053C85.6579 483.539 85.6765 480.614 85.3165 477.602C84.3245 469.309 75.6619 464.643 67.5672 455.885C63.5512 451.538 62.2632 429.221 64.4939 425.654C63.8392 426.478 61.9899 428.874 59.9232 432.147Z"
					fill="#5AAAFF"
				/>
				<path
					d="M64.4935 425.654C64.6561 425.45 64.7535 425.331 64.7535 425.331C64.6615 425.408 64.5761 425.522 64.4935 425.654Z"
					fill="#5AAAFF"
				/>
				<path
					d="M96.9903 492.616C96.9903 492.616 100.34 480.189 108.624 478.516C116.908 476.843 126.42 464.601 126.934 462.2C126.934 462.2 125.537 485.287 96.9903 492.616Z"
					fill="#5AAAFF"
				/>
				<path
					d="M108.15 482.624C103.074 485.161 100.964 488.576 100.214 491.674C125.63 483.501 126.934 462.201 126.934 462.201C126.833 462.676 126.369 463.542 125.642 464.629C123.15 468.996 117.116 478.141 108.15 482.624Z"
					fill="#5AAAFF"
				/>
				<path
					d="M114.916 488.04H71.5893L76.7347 520.4C77.1253 522.854 79.2413 524.662 81.7267 524.662H104.779C107.264 524.662 109.381 522.854 109.771 520.4L114.916 488.04Z"
					fill="#FFBF4D"
				/>
				<path
					d="M114.065 493.393L114.916 488.04H71.5904L72.4411 493.393H114.065Z"
					fill="#FF9F50"
				/>
				<path
					d="M104.42 488.04L99.2745 520.4C98.8839 522.854 96.7679 524.662 94.2825 524.662H104.78C107.264 524.662 109.381 522.854 109.771 520.4L114.916 488.04H104.42Z"
					fill="#FF9F50"
				/>
				<path
					d="M413.753 470.143H217.936V430.329H413.753V470.143Z"
					fill="#FFBF4D"
				/>
				<g opacity="0.289993">
					<path
						opacity="0.289993"
						d="M413.753 439.993H217.936V430.329H413.753V439.993Z"
						fill="#F98D2B"
					/>
				</g>
				<path
					d="M234.962 430.329H252.834L248.045 439.997H230.162L234.962 430.329Z"
					fill="#F77E2D"
				/>
				<path
					d="M275.228 430.329H293.1L288.311 439.997H270.437L275.228 430.329Z"
					fill="#F77E2D"
				/>
				<path
					d="M315.493 430.329H333.365L328.576 439.997H310.703L315.493 430.329Z"
					fill="#F77E2D"
				/>
				<path
					d="M355.759 430.329H373.622L368.832 439.997H350.968L355.759 430.329Z"
					fill="#F77E2D"
				/>
				<path
					d="M413.754 430.329V430.599L409.088 439.997H391.235L396.026 430.329H413.754Z"
					fill="#F77E2D"
				/>
				<path
					d="M256.116 470.147H242.312V524.584H256.116V470.147Z"
					fill="#5AAAFF"
				/>
				<path
					d="M256.116 470.147H252.98V524.584H256.116V470.147Z"
					fill="#5AAAFF"
				/>
				<path
					d="M390.451 470.147H376.647V524.584H390.451V470.147Z"
					fill="#5AAAFF"
				/>
				<path
					d="M390.451 470.147H386.369V524.584H390.451V470.147Z"
					fill="#5AAAFF"
				/>
				<path
					d="M256.116 470.104H242.312V476.96H256.116V470.104Z"
					fill="#5AAAFF"
				/>
				<path
					d="M390.451 470.104H376.647V476.96H390.451V470.104Z"
					fill="#5AAAFF"
				/>
				<path
					d="M256.116 430.291H242.312V424.109H256.116V430.291Z"
					fill="#5AAAFF"
				/>
				<path
					d="M256.116 430.291H252.98V424.109H256.116V430.291Z"
					fill="#5AAAFF"
				/>
				<path
					d="M390.451 430.291H376.647V424.109H390.451V430.291Z"
					fill="#5AAAFF"
				/>
				<path
					d="M390.451 430.291H386.369V424.109H390.451V430.291Z"
					fill="#5AAAFF"
				/>
				<path
					d="M256.116 430.296H242.312V429.517H256.116V430.296Z"
					fill="#5AAAFF"
				/>
				<path
					d="M390.451 430.296H376.647V429.517H390.451V430.296Z"
					fill="#5AAAFF"
				/>
				<path
					d="M252.835 430.329L233.087 470.143H217.936V464.651L234.963 430.329H252.835Z"
					fill="#007BFF"
				/>
				<path
					d="M275.228 430.329L255.48 470.143H273.352L293.1 430.329H275.228Z"
					fill="#007BFF"
				/>
				<path
					d="M333.366 430.329L313.618 470.143H295.746L315.494 430.329H333.366Z"
					fill="#007BFF"
				/>
				<path
					d="M373.622 430.329L353.884 470.143H336.012L355.76 430.329H373.622Z"
					fill="#007BFF"
				/>
				<path
					d="M396.026 430.329L376.277 470.143H394.141L413.754 430.599L396.026 430.329Z"
					fill="#007BFF"
				/>
				<path
					d="M252.835 430.329L248.044 439.997H230.163L234.963 430.329H252.835Z"
					fill="#0056B1"
				/>
				<path
					d="M293.1 430.329L288.31 439.997H270.438L275.228 430.329H293.1Z"
					fill="#0056B1"
				/>
				<path
					d="M333.366 430.329L328.575 439.997H310.703L315.494 430.329H333.366Z"
					fill="#0056B1"
				/>
				<path
					d="M373.622 430.329L368.832 439.997H350.969L355.76 430.329H373.622Z"
					fill="#0056B1"
				/>
				<path
					d="M413.754 430.599L409.088 439.998H391.235L396.026 430.33L413.754 430.599Z"
					fill="#0056B1"
				/>
				<g opacity="0.550003">
					<g opacity="0.550003">
						<g opacity="0.550003">
							<g opacity="0.550003">
								<g opacity="0.550003">
									<g opacity="0.550003">
										<path
											opacity="0.550003"
											d="M410.379 447.555H223.393V467.469H410.379V447.555Z"
											fill="url(#paint0_linear)"
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
				<path
					d="M261.161 415.469C261.161 408.671 255.651 403.162 248.853 403.162C242.056 403.162 236.547 408.671 236.547 415.469C236.547 422.267 242.056 427.777 248.853 427.777C255.651 427.777 261.161 422.267 261.161 415.469Z"
					fill="#FFBF4D"
				/>
				<path
					d="M254.432 415.469C254.432 412.389 251.934 409.891 248.853 409.891C245.773 409.891 243.276 412.389 243.276 415.469C243.276 418.55 245.773 421.047 248.853 421.047C251.934 421.047 254.432 418.55 254.432 415.469Z"
					fill="#007BFF"
				/>
				<path
					d="M395.775 415.469C395.775 408.671 390.265 403.162 383.468 403.162C376.671 403.162 371.16 408.671 371.16 415.469C371.16 422.267 376.671 427.777 383.468 427.777C390.265 427.777 395.775 422.267 395.775 415.469Z"
					fill="#FFBF4D"
				/>
				<path
					d="M389.046 415.469C389.046 412.389 386.548 409.891 383.467 409.891C380.387 409.891 377.891 412.389 377.891 415.469C377.891 418.55 380.387 421.047 383.467 421.047C386.548 421.047 389.046 418.55 389.046 415.469Z"
					fill="#007BFF"
				/>
				<path
					d="M487.94 520.366H425.576L435.115 497.242V497.227L438.952 487.898L440.009 485.339L441.732 481.177L442.083 480.33L445.92 470.999L446.625 469.302L453.424 452.794C454.651 449.819 458.865 449.819 460.092 452.794L466.891 469.302L467.596 470.999L471.432 480.33L471.785 481.177L473.508 485.339L474.564 487.898L478.401 497.227V497.242L487.94 520.366Z"
					fill="#FFBF4D"
				/>
				<g opacity="0.289993">
					<g opacity="0.289993">
						<path
							opacity="0.289993"
							d="M429.664 510.451L425.579 520.365H487.947L483.86 510.451H429.664Z"
							fill="#F98D2B"
						/>
					</g>
				</g>
				<path
					d="M492.021 514.437H420.593V524.535H492.021V514.437Z"
					fill="#5AAAFF"
				/>
				<path
					d="M476.588 524.535H492.02V514.437H476.588V524.535Z"
					fill="#5AAAFF"
				/>
				<g opacity="0.289993">
					<g opacity="0.289993">
						<path
							opacity="0.289993"
							d="M485.487 514.434H476.666L469.579 497.247V497.235L465.742 487.904L464.686 485.347L462.963 481.184L462.611 480.335L458.775 471.004L458.07 469.307L452.354 455.41L453.424 452.799C454.651 449.824 458.866 449.824 460.092 452.799L466.891 469.307L467.596 471.004L471.432 480.335L471.786 481.184L473.508 485.347L474.564 487.904L478.402 497.235V497.247L485.487 514.434Z"
							fill="#F98D2B"
						/>
					</g>
				</g>
				<path
					d="M471.432 480.329H442.083L445.92 470.999H467.596L471.432 480.329Z"
					fill="#007BFF"
				/>
				<path
					d="M478.401 497.228V497.243H435.114V497.228L438.952 487.899H474.564L478.401 497.228Z"
					fill="#007BFF"
				/>
				<path
					d="M471.432 480.329H462.611L458.775 470.999H467.596L471.432 480.329Z"
					fill="#0056B1"
				/>
				<path
					d="M478.401 497.228V497.243H469.58V497.228L465.742 487.899H474.564L478.401 497.228Z"
					fill="#0056B1"
				/>
				<path
					d="M90.4108 293.611C95.0708 293.611 99.1215 290.227 99.7508 285.611C99.8628 284.783 99.9215 283.937 99.9215 283.079C99.9215 272.799 91.5895 264.467 81.3108 264.467C80.8881 264.467 80.4708 264.485 80.0561 264.513C79.2068 254.377 70.7161 246.415 60.3601 246.415C49.4401 246.415 40.5895 255.267 40.5895 266.185C40.5895 266.669 40.6121 267.148 40.6468 267.624C37.5481 265.893 33.9375 264.968 30.0961 265.144C20.1641 265.599 12.0041 273.543 11.3015 283.459C11.2961 283.537 11.2908 283.616 11.2868 283.695C10.9668 289.088 15.3268 293.611 20.7295 293.611H90.4108Z"
					fill="#EBF5FF"
				/>
				<path
					d="M534.59 308.229C549.307 308.229 550.122 286.08 535.422 285.367C535.021 285.347 534.618 285.337 534.21 285.337C531.586 285.337 529.061 285.752 526.689 286.513C524.722 274.525 514.318 265.376 501.773 265.376C488.951 265.376 478.369 274.933 476.741 287.311C473.698 285.852 470.29 285.035 466.691 285.035C464.681 285.035 462.73 285.289 460.87 285.768C448.001 289.083 450.702 308.229 463.99 308.229H534.59Z"
					fill="#EBF5FF"
				/>
				<path
					d="M357.846 199.097C372.56 199.097 373.374 176.954 358.678 176.24C358.277 176.221 357.874 176.21 357.468 176.21C354.844 176.21 352.318 176.626 349.948 177.388C347.982 165.402 337.581 156.257 325.04 156.257C312.221 156.257 301.642 165.81 300.014 178.184C296.974 176.726 293.566 175.909 289.969 175.909C287.958 175.909 286.009 176.164 284.149 176.644C271.284 179.956 273.984 199.097 287.268 199.097H357.846Z"
					fill="#EBF5FF"
				/>
				<path
					d="M158.868 204.751C158.868 185.269 143.076 169.477 123.595 169.477C104.115 169.477 88.3212 185.269 88.3212 204.751C88.3212 224.231 104.115 240.024 123.595 240.024C143.076 240.024 158.868 224.231 158.868 204.751Z"
					fill="#FFBF4D"
				/>
				<path
					d="M123.885 169.484C133.478 175.788 139.817 186.64 139.817 198.978C139.817 218.458 124.023 234.25 104.543 234.25C104.446 234.25 104.35 234.243 104.253 234.243C109.807 237.894 116.451 240.023 123.594 240.023C143.075 240.023 158.867 224.231 158.867 204.751C158.867 185.367 143.231 169.642 123.885 169.484Z"
					fill="#FF9F50"
				/>
				<defs>
					<linearGradient
						id="paint0_linear"
						x1="316.886"
						y1="481.79"
						x2="316.886"
						y2="449.718"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="white" />
						<stop offset="0.649262" stop-color="white" />
						<stop offset="1" stop-color="white" />
					</linearGradient>
				</defs>
			</svg>
		</div>
		<div class="py-4 px-4 text-center text-blue-700">
			<h1 class="mb-4 text-7xl font-semibold">Page Not Found.</h1>
			<h2 class="text-4xl">
				Looks like you may have made a wrong turn somewhere.
			</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageNotFound'
}
</script>
