<template>
	<div class="content" v-html="replaceSections"></div>
</template>

<script>
export default {
	name: 'BodyText',
	props: ['content'],
	computed: {
		removeHTMLfromShortcode() {
			return this.content
				? this.content.replace(/(<\w>)(\[[^<]+\])(<\/\w>)/g, '$2')
				: ''
		},
		replaceAccordions() {
			if (this.removeHTMLfromShortcode) {
				return this.removeHTMLfromShortcode
					.replaceAll(
						'[accordion]',
						'<div class="accordion my-4 border border-gray-400 rounded-md overflow-hidden bg-white">'
					)
					.replaceAll(
						'[/accordion]',
						'<input class="hidden" type="radio" name="accordion" id="acc-close" /></div>'
					)
			}
			return ''
		},
		replaceSections() {
			if (this.replaceAccordions) {
				const regex =
					/(\[section\s)(title=")([^"]+)("\])([^[]+)(\[\/section\])/g
				return this.replaceAccordions.replaceAll(
					regex,
					`<div>
            <input type="radio" class="hidden checked:hidden" name="accordion" id="$3"/>
            <section class="box relative h-auto transition ease-in-out duration-150">
              <label class="box-title flex items-center -mt-px bg-gray-200 px-4 py-3 cursor-pointer border-t border-b border-gray-400" for="$3">
                <span class="arrow block text-yellow-500 mr-2">
                  <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 13 24">
                    <path d="M6.26087 0L12.5217 12L6.26087 24H0L6.26087 12L0 0H6.26087Z"/>
                  </svg>
                </span>
                $3
              </label>
              <label class="box-close h-12 absolute top-0 inset-x-0 hidden cursor-pointer" for="acc-close"></label>
              <div class="box-content p-4 -mb-px hidden border-b border-gray-400">$5</div>
            </section>
          </div>
          `
				)
			}
			return ''
		}
	}
}
</script>
