<template>
	<div
		class="group-focus-visible:kta-ring-inset relative flex h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-wide"
	>
		<img
			v-if="path"
			class="z-0 mx-auto h-full w-full object-cover"
			:src="path"
			alt="Card Image"
		/>
		<div
			class="z-10 flex h-full w-full flex-col items-center justify-center px-2 py-4"
		>
			<div v-if="name" class="text-center">
				<p class="text-2xl font-bold leading-tight text-cobalt">{{ name }}</p>
				<p class="font-medium leading-tight text-dark">{{ title }}</p>
			</div>
			<div
				v-if="description"
				class="grow px-3 text-left text-dark"
				v-html="description"
			></div>
			<a v-if="link" :href="link" target="_blank" class="mt-4">
				<svg
					class="h-10 w-10 text-[#2867B2] hover:text-[#004182]"
					viewBox="0 0 25 25"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M2.04827 0.982605C1.04719 0.982605 0.235664 1.79356 0.235664 2.79393V23.1713C0.235664 24.1717 1.04719 24.9826 2.04827 24.9826H22.4231C23.4241 24.9826 24.2357 24.1717 24.2357 23.1713V2.79393C24.2357 1.79356 23.4241 0.982605 22.4231 0.982605H2.04827ZM5.53824 8.46114C6.70616 8.46114 7.65294 7.51503 7.65294 6.34793C7.65294 5.18084 6.70616 4.23472 5.53824 4.23472C4.37032 4.23472 3.42354 5.18084 3.42354 6.34793C3.42354 7.51503 4.37032 8.46114 5.53824 8.46114ZM20.6432 14.4989V21.4423H17.1113V15.7064C17.1113 14.4989 17.0203 12.8203 15.2357 12.8203C13.083 12.8203 13.089 14.9583 13.0921 15.8653V21.4423H9.46554V9.97057H13.0907V11.4379C13.3418 11.0418 14.3529 9.68176 16.401 9.68176C17.8882 9.68176 20.6432 10.0144 20.6432 14.4989ZM7.35084 9.97057H3.72564V21.4423H7.35084V9.97057Z"
					/>
				</svg>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LeadershipCard',
	props: {
		path: {
			type: String,
			default: '',
			required: false
		},
		name: {
			type: String,
			required: false
		},
		title: {
			type: String,
			required: false
		},
		description: {
			type: String,
			required: false
		},
		buttonText: {
			type: String,
			default: '',
			required: false
		},
		link: {
			type: [String, Object],
			default: '',
			required: false
		}
	}
}
</script>
