import { Alert, State } from '@/types/main'
import { defineStore } from 'pinia'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'

export const useMainStore = defineStore('main', {
	state: (): State => ({
		alert: new Alert(),
		errors: {}
	}),
	getters: {
		getAlert(state) {
			return state.alert
		}
	},
	actions: {
		mapErrors(errors: { [key: string]: any }[]) {
			return errors.reduce((errors: any, error: any) => {
				if (!error.children.length) {
					const key = Object.keys(error.constraints)[0]
					errors[error.property] = error.constraints[key]
					return errors
				}
				errors[error.property] = this.mapErrors(error.children)
				return errors
			}, {})
		},
		handleErrors(errors: any) {
			if (typeof errors.message === 'string') {
				this.displayAlert({
					level: 'error',
					title: errors.message,
					position: 'top-right'
				})
				// create error alert
				return
			}
			if (errors.message.length) {
				this.errors = this.mapErrors(errors.message)
			}
		},
		removeError(props: string[]) {
			const last = props.pop()
			if (last) {
				delete props.reduce((o, k) => o[k], this.errors)[last]
			}

			props.reduce((props) => {
				const last = props.pop()
				const obj = props.reduce((o, k) => o[k], this.errors)
				if (last !== undefined && isEmpty(obj[last])) {
					delete obj[last]
				}
				return props
			}, props)
		},
		removeErrors() {
			this.errors = {}
		},
		displayAlert(options: Alert) {
			this.alert = merge(this.alert, options)
		},
		closeAlert() {
			this.alert = new Alert()
		},
		copyClipboard(string: string) {
			// clipboard API only works on secure origins: https or localhost
			// only alternative is execCommand which is deprecated
			if (navigator.clipboard) {
				navigator.clipboard.writeText(string)
			} else {
				const textArea = document.createElement('textarea')
				textArea.value = string
				document.body.appendChild(textArea)
				textArea.select()
				document.execCommand('copy')
				document.body.removeChild(textArea)
			}

			this.displayAlert({
				level: 'success',
				title: 'Copied to clipboard!',
				timer: 2000
			})
		}
	}
})
