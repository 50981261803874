<template>
	<div class="absolute inset-x-0 top-16 z-50 xl:top-10" v-if="embedderIsActive">
		<div class="fixed inset-0" @click="cancel"></div>
		<div
			class="relative mx-auto w-full min-w-max max-w-xl rounded border border-gray-300 bg-white p-3 shadow"
		>
			<div class="relative flex items-center">
				<form @submit.prevent="insert" class="flex-1">
					<input
						type="text"
						class="block w-full rounded-md border-gray-300 bg-white text-gray-700 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
						v-model="url"
						placeholder="https://"
						ref="linkEmbedder"
						@keyup.enter="insert"
						@keydown.esc="cancel"
					/>
				</form>
			</div>
			<div class="mt-3 flex items-center justify-between">
				<div class="flex items-start">
					<div class="flex h-5 max-w-xs items-center text-sm">
						<label class="mr-8 flex items-center">
							<span class="mr-2.5">Width</span>
							<input
								v-model="width"
								type="text"
								class="block h-8 w-14 rounded-md border-gray-300 bg-white text-gray-700 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
							/>
						</label>
						<label class="flex items-center">
							<span class="mr-2.5">Height</span>
							<input
								v-model="height"
								type="text"
								class="block h-8 w-14 rounded-md border-gray-300 bg-white text-gray-700 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
							/>
						</label>
					</div>
				</div>
				<div class="flex items-center">
					<span class="mr-2 inline-flex rounded-md shadow-sm">
						<button
							@click="cancel"
							class="focus:shadow-outline-kta-blue router-link-active rounded-md border border-gray-300 py-2 px-3 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
						>
							Cancel
						</button>
					</span>
					<span class="inline-flex rounded-md shadow-sm">
						<button
							@click="insert"
							type="button"
							class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
						>
							Insert
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'YoutubeEmbedder',
	props: {
		embedderIsActive: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		url: null,
		width: 240,
		height: 180
	}),
	methods: {
		insert() {
			this.$emit('insert', {
				url: this.url,
				width: this.width,
				height: this.height
			})

			this.reset()
		},
		cancel() {
			this.$emit('cancel')
		},
		reset() {
			this.url = null
			this.width = 240
			this.height = 180
		},
		showFilePicker() {
			this.$emit('showFilePicker')
		}
	}
}
</script>
