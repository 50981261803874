import { defineStore } from 'pinia'
import { format } from 'date-fns'
import { useMainStore } from './main'
import { useUsersStore } from './users'
import axios from 'axios'
import kebabCase from 'lodash/kebabCase'
import { Page } from '@/types/pages'

type OrderBy = {
	key: string
	value: string
	type: string
}

export const usePagesStore = defineStore('pages', {
	state: () => ({
		pages: [],
		allPublished: [],
		page: 1,
		count: 0,
		limit: 10,
		offset: 0,
		filter: '',
		filterOptions: [
			{ key: 'published', value: 'Published' },
			{ key: 'unpublished', value: 'Unpublished' }
		],
		order: 'ASC',
		orderBy: { key: 'headerTitle', value: 'Title', type: 'string' },
		orderOptions: [
			{ key: 'headerTitle', value: 'Title', type: 'string' },
			{ key: 'published', value: 'Published', type: 'date' },
			{ key: 'created', value: 'Created', type: 'date' }
		],
		active: new Page(),
		dynamicSlug: true
	}),
	getters: {
		usersStore() {
			return useUsersStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		getActivePage(state) {
			return state.active
		},
		get(state) {
			return state.pages.map((page: any) => {
				page.created = format(new Date(page.created), 'MM/dd/yyyy h:mm a')
				page.updated = format(new Date(page.updated), 'MM/dd/yyyy h:mm a')
				if (page.published)
					page.published = format(new Date(page.published), 'MM/dd/yyyy h:mm a')
				return page
			})
		},
		from(state) {
			return +state.offset + 1
		},
		to(state) {
			return state.limit > state.count
				? state.count
				: state.limit * state.page > state.count
				? state.count
				: state.limit * state.page
		},
		getOffset(state) {
			return +state.offset
		},
		published(state) {
			return state.pages.filter((page: any) => page.published)
		},
		mainStore() {
			return useMainStore()
		}
	},
	actions: {
		setFilter(value: string) {
			this.filter = value
		},
		setOrderBy(orderBy: OrderBy) {
			this.orderBy = orderBy
		},
		setOrder(order: string) {
			this.order = order
		},
		clearActive() {
			this.active = new Page()
			this.dynamicSlug = true
		},
		setLimit(value: number) {
			this.limit = value
			this.offset = 0
			this.page = 1
		},
		updateSlug() {
			if (this.dynamicSlug) {
				this.active.slug = kebabCase(this.active.headerTitle)
			}
		},
		nextPage() {
			this.page++
			this.offset += this.limit
		},
		prevPage() {
			this.page--
			this.offset -= this.limit
		},
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				let query = this.offset
					? `?limit=${this.limit}&offset=${this.offset}`
					: `?limit=${this.limit}`
				query = this.filter ? `${query}&filter=${this.filter}` : query
				query =
					this.orderBy.key !== 'created' || this.order !== 'ASC'
						? `${query}&order=${this.order}&orderBy=${this.orderBy.key}`
						: query
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/pages${query}`,
					this.userConfig
				)
				this.pages = data.items
				this.count = data.count
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchPublished() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/pages/published`
				)

				this.allPublished = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: string) {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/pages/${id}`,
					this.userConfig
				)
				this.dynamicSlug = false
				this.active = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create() {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/pages`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Page created.',
					timer: 3000
				})
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update() {
			await this.usersStore.checkLogin()
			try {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { id, created, updated, ...data } = this.active
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/pages/${this.active.id}`,
					data,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Page updated.',
					timer: 3000
				})
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove(id: string) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/pages/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Page deleted.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
