<template>
	<div class="flex items-center">
		<FormSelect
			v-model="active"
			:options="menus"
			optionsDisplay="name"
			class="flex-1"
			label="Active Menu"
			inlineLabel
		/>
		<button
			@click="create"
			type="button"
			class="mb-5 inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
		>
			New Menu
		</button>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMenusStore } from '@/stores/menus'
import isEqual from 'lodash/isEqual'
export default {
	name: 'MenuSelector',
	data: () => ({
		active: ''
	}),
	created() {
		this.menusStore.setActiveMenu('')
	},
	computed: {
		...mapStores(useMenusStore),
		menus() {
			return this.menusStore.getMenus
		},
		activeMenu() {
			return this.menusStore.getActive
		}
	},
	methods: {
		create() {
			const found = this.menus.filter((item) => item.name.includes('New Menu'))
			const name = found ? `New Menu ${found.length}` : `New Menu`
			const newMenu = {
				name,
				items: []
			}
			this.menusStore.addMenu(newMenu)
			this.menusStore.setActiveMenu(newMenu)
		}
	},
	watch: {
		active(value) {
			this.menusStore.setActiveMenu(structuredClone(value))
		},
		activeMenu(value) {
			if (!isEqual(this.active, value)) {
				this.active = structuredClone(value)
			}
		}
	}
}
</script>
