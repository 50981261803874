<template>
	<PageLayout :title="postTitle">
		<div v-if="isIndex">
			<DataControls :store="postsStore" />
			<div class="flex items-center justify-end">
				<PaginatorComponent
					:store="postsStore"
					class="flex-1"
					shortcode="posts"
				/>
				<router-link
					:to="{ name: 'PostsCreate' }"
					class="focus:shadow-outline-gray ml-4 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
			<DisplayItems :items="formatted" to="PostsEdit" />
			<div class="flex items-center justify-end">
				<PaginatorComponent :store="postsStore" class="flex-1" />
				<router-link
					:to="{ name: 'PostsCreate' }"
					class="focus:shadow-outline-gray ml-4 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
		</div>
		<router-view v-else />
	</PageLayout>
</template>

<script>
import { mapStores } from 'pinia'
import { usePostsStore } from '@/stores/posts'
import { format } from 'date-fns'
import map from 'lodash/map'
import DisplayItems from '@/views/layout/DisplayItems.vue'
import DataControls from '@/components/utilities/DataControls'
import PaginatorComponent from '@/components/utilities/PaginatorComponent'

export default {
	title: 'Posts - iKTA',
	name: 'PostsIndex',
	components: {
		DataControls,
		PaginatorComponent,
		DisplayItems
	},
	beforeRouteUpdate() {
		this.postsStore.fetchAll()
	},
	created() {
		this.postsStore.fetchAll()
	},
	data() {
		return {
			showLatestSort: false
		}
	},
	computed: {
		...mapStores(usePostsStore),
		isIndex() {
			return this.$route.name === 'PostsIndex'
		},
		postTitle() {
			if (this.isIndex) {
				return 'Posts'
			}
			return this.$route.params.id ? 'Edit Post' : 'Create Post'
		},
		posts() {
			return this.postsStore.posts
		},
		itemsPerPage() {
			return this.postsStore.limit
		},
		formatted() {
			return this.posts.map((post) => {
				post.created = format(new Date(post.created), 'MM/dd/yyyy h:mm')
				if (post.expires)
					post.expires = format(new Date(post.expires), 'MM/dd/yyyy h:mm a')
				if (post.updated)
					post.updated = format(new Date(post.updated), 'MM/dd/yyyy h:mm a')
				if (post.published)
					post.published = format(new Date(post.published), 'MM/dd/yyyy h:mm a')
				post.tags = map(post.tags, 'name').join(', ')

				delete post.summary
				delete post.body
				return post
			})
		}
	},
	watch: {
		itemsPerPage() {
			this.postsStore.fetchAll()
		}
	}
}
</script>
