<template>
	<div class="mt-8 border-t border-gray-200 pb-4 pt-5">
		<span
			:class="[
				editing ? 'justify-between' : 'justify-end',
				'flex items-center'
			]"
		>
			<div class="flex items-center space-x-3">
				<button
					v-if="editing"
					type="button"
					class="focus:shadow-outline-red inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:outline-none active:bg-red-700"
					@click="confirm = true"
				>
					Delete
				</button>
				<span class="space-x-3 text-sm italic" v-if="editing">
					<span class="font-light text-gray-700" v-show="lastEdit"
						>Last edited on {{ lastEdit }}</span
					>
					<span class="font-light text-gray-700" v-show="lastEdit === false"
						>This is the original version</span
					>
					<router-link
						class="text-cobalt underline"
						:to="{ name: 'PageHistory' }"
						v-show="lastEdit"
						>(Version History)</router-link
					>
				</span>
			</div>
			<span class="flex">
				<button
					type="button"
					class="rounded-md border border-gray-300 py-2 px-4 text-sm font-medium leading-5 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 hover:text-gray-500 focus:border-gray-400 focus:outline-none active:bg-gray-50 active:text-gray-800"
					@click="cancel"
				>
					Cancel
				</button>
				<button
					type="submit"
					v-text="editing ? 'Update' : 'Save'"
					class="ml-3 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
					@click="submit"
				></button>
			</span>
		</span>
		<ConfirmDialog :isOpen="confirm" @confirmed="remove" />
	</div>
</template>

<script>
import ConfirmDialog from '@/components/utilities/ConfirmDialog'
export default {
	name: 'ManageControls',
	emits: ['cancel', 'remove', 'submit'],
	props: {
		lastEdit: {
			type: [String, Boolean],
			default: null,
			required: false
		}
	},
	components: {
		ConfirmDialog
	},
	data: () => ({
		confirm: false
	}),
	methods: {
		cancel() {
			this.$emit('cancel')
		},
		remove(confirmed) {
			if (confirmed) this.$emit('remove')
			this.confirm = false
		},
		submit() {
			this.$emit('submit')
		}
	},
	computed: {
		editing() {
			return !!this.$route.params.id
		}
	}
}
</script>
