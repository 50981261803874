<template>
	<div>
		<span class="mb-4 flex flex-row space-x-3">
			<TextInput
				label="Label"
				v-model="computedProps.label"
				:error="errors?.label"
			/>
			<FormSelect
				label="Column Width"
				:options="[...Array(12)].map((item, index) => index + 1)"
				v-model="computedProps.width"
			/>
			<TextInput
				label="Rows"
				v-model="computedProps.rows"
				:error="errors?.rows"
			/>
		</span>
		<FormToggle label="Required" v-model="computedProps.required" />
	</div>
</template>

<script>
import { FormToggle } from '@/components/utilities/form'
export default {
	name: 'TextAreaSettings',
	props: {
		props: {
			type: Object,
			required: true
		},
		errors: {
			type: Object,
			required: false
		}
	},
	components: {
		FormToggle
	},
	computed: {
		computedProps() {
			return this.props
		}
	}
}
</script>
