<template>
	<div class="my-4">
		<div
			class="flex items-center justify-end rounded-t-md bg-gray-900 px-2 py-3 text-sm text-gray-300 lg:justify-start"
		>
			<div
				class="hidden flex-1 cursor-pointer select-none items-center lg:flex"
				@click="sortFiles('name')"
			>
				<span>Title/Name</span>
				<ChevronUpIcon
					class="h-4 w-5"
					v-if="sort.by === 'name' && sort.direction === 'ASC'"
				/>
				<ChevronDownIcon
					class="h-4 w-5"
					v-if="sort.by === 'name' && sort.direction === 'DESC'"
				/>
			</div>
			<div
				class="hidden w-44 cursor-pointer select-none items-center pl-2 lg:flex"
				@click="sortFiles('type')"
			>
				<span>Type</span>
				<ChevronUpIcon
					class="h-4 w-5"
					v-if="sort.by === 'type' && sort.direction === 'ASC'"
				/>
				<ChevronDownIcon
					class="h-4 w-5"
					v-if="sort.by === 'type' && sort.direction === 'DESC'"
				/>
			</div>
			<div
				class="hidden w-20 cursor-pointer select-none items-center pl-2 lg:flex"
				@click="sortFiles('size')"
			>
				<span>Size</span>
				<ChevronUpIcon
					class="h-4 w-5"
					v-if="sort.by === 'size' && sort.direction === 'ASC'"
				/>
				<ChevronDownIcon
					class="h-4 w-5"
					v-if="sort.by === 'size' && sort.direction === 'DESC'"
				/>
			</div>
			<div
				class="hidden w-36 cursor-pointer select-none items-center pl-2 lg:flex"
				@click="sortFiles('added')"
			>
				<span>Date Added</span>
				<ChevronUpIcon
					class="h-4 w-5"
					v-if="sort.by === 'added' && sort.direction === 'ASC'"
				/>
				<ChevronDownIcon
					class="h-4 w-5"
					v-if="sort.by === 'added' && sort.direction === 'DESC'"
				/>
			</div>
			<div class="flex w-20 items-center justify-end pl-2">
				<input
					type="checkbox"
					v-model="allSelected"
					class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
				/>
			</div>
		</div>
		<ul class="rounded-b-md bg-white text-sm shadow-md">
			<li v-if="!files.length" class="py-2 px-4 italic text-gray-400">
				folder empty
			</li>
			<li
				v-for="(file, index) in files"
				:key="index"
				class="flex justify-between p-2 text-gray-800 lg:items-center"
				:class="{
					'border-t border-gray-200': index > 0,
					'bg-gray-100': index % 2
				}"
			>
				<div class="flex-1 lg:flex lg:items-center">
					<div class="mb-2 flex flex-1 items-start lg:mb-0 lg:items-center">
						<div class="flex-1">
							<span class="text-xs uppercase text-gray-600 lg:hidden"
								>File</span
							>
							<span class="block">{{ file.name }}</span>
							<span class="block text-xs italic text-gray-400">{{
								file.filename
							}}</span>
						</div>
						<div class="w-44 text-right lg:pl-2 lg:text-left">
							<span class="block text-xs uppercase text-gray-600 lg:hidden"
								>Type</span
							>
							<span>{{ file.type }}</span>
						</div>
					</div>
					<div
						class="flex flex-1 items-start justify-between lg:w-56 lg:flex-none lg:items-center"
					>
						<div class="w-20 lg:pl-2">
							<span class="block text-xs uppercase text-gray-600 lg:hidden"
								>Size</span
							>
							<span>{{ file.size }}</span>
						</div>
						<div class="w-36 text-right lg:pl-2 lg:text-left">
							<span class="block text-xs uppercase text-gray-600 lg:hidden"
								>Date Added</span
							>
							<span>{{ file.added }}</span>
						</div>
					</div>
				</div>
				<div
					class="ml-4 flex flex-col items-center justify-between border-l border-solid border-gray-400 pl-2 lg:ml-0 lg:w-20 lg:flex-row lg:border-none"
				>
					<span
						class="cursor-pointer rounded-full hover:text-blue-500 lg:pr-0.5"
						@click="edit(file)"
					>
						<svg
							class="h-4 w-4"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
							/>
						</svg>
					</span>
					<a
						:href="file.path"
						target="_blank"
						class="rounded-full hover:text-green-500 lg:pr-0.5"
					>
						<svg
							class="h-4 w-4"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
							/>
						</svg>
					</a>
					<span
						class="cursor-pointer rounded-full hover:text-red-600 lg:pr-1.5"
						@click="deleteOne(file)"
					>
						<svg
							class="h-4 w-4"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="3"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</span>
					<input
						type="checkbox"
						v-model="selected"
						:value="file.id"
						class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
					/>
				</div>
			</li>
		</ul>
		<div v-show="selected.length" class="mt-5">
			<div class="flex justify-end">
				<select
					v-model="folder"
					class="block w-full max-w-xs rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
				>
					<option value="">Select Folder</option>
					<option v-for="folder in folders" :key="folder.id" :value="folder.id">
						{{ folder.title }}
					</option>
				</select>
			</div>
			<div class="mt-3 flex items-center justify-end">
				<span class="mr-2 inline-flex rounded-md shadow-sm">
					<button
						@click="moveSelected"
						type="button"
						class="focus:shadow-outline-kta-blue inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
					>
						Move
					</button>
				</span>
				<span class="inline-flex rounded-md shadow-sm">
					<button
						@click="deleteSelected"
						type="button"
						class="focus:shadow-outline-red inline-flex items-center justify-center rounded-md border border-red-200 bg-red-100 px-4 py-2 font-medium text-red-700 transition duration-150 ease-in-out hover:bg-red-50 focus:border-red-300 focus:outline-none active:bg-red-200 sm:text-sm sm:leading-5"
					>
						Delete
					</button>
				</span>
			</div>
		</div>
		<p class="p-2 text-xs italic text-gray-600">
			Moving or deleting files will break any links used in pages or posts.
		</p>
		<FileEditor :file="file" @close="close" v-if="editing" />
		<ConfirmDialog :isOpen="confirm" @confirmed="confirmed" />
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMediaStore } from '../../stores/media'
import ConfirmDialog from '@/components/utilities/ConfirmDialog'
import FileEditor from '@/components/media/FileEditor'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/24/solid'

export default {
	name: 'FileViewer',
	components: {
		ConfirmDialog,
		FileEditor,
		ChevronUpIcon,
		ChevronDownIcon
	},
	computed: {
		...mapStores(useMediaStore),
		files() {
			return this.mediaStore.getFiles
		},
		folders() {
			return this.mediaStore.getFolders
		},
		sort() {
			return this.mediaStore.sortFiles
		}
	},
	data: () => ({
		confirm: false,
		editing: false,
		file: null,
		selected: [],
		allSelected: false,
		folder: ''
	}),
	methods: {
		sortFiles(prop) {
			this.mediaStore.setSortBy(prop)
		},
		edit(file) {
			this.editing = true
			this.file = file
		},
		close() {
			this.editing = false
			this.file = null
		},
		async confirmed(confirmed) {
			if (confirmed) {
				if (this.confirmType === 'deleteOne') {
					await this.mediaStore.deleteFile(this.tempFile.id)
					delete this.tempFile
				}
				if (this.confirmType === 'deleteSelected') {
					await this.mediaStore.deleteFiles(this.selected)
				}
			}
			this.confirm = false
		},
		deleteOne(file) {
			this.confirm = true
			this.tempFile = file
			this.confirmType = 'deleteOne'
		},
		async deleteSelected() {
			this.confirm = true
			this.confirmType = 'deleteSelected'
		},
		moveSelected() {
			if (this.folder) {
				// replace with alert
				// this.$swal({
				// 	position: 'center',
				// 	icon: 'info',
				// 	title: 'Move File(s)',
				// 	text: 'Are you sure you want to move?',
				// 	showConfirmButton: true,
				// 	showCancelButton: true
				// })
			}
		}
	},
	watch: {
		allSelected(value) {
			value
				? (this.selected = this.files.map(({ id }) => id))
				: (this.selected = [])
		}
	}
}
</script>
