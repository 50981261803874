<template>
	<div :class="[error ? 'mb-3' : 'mb-5', 'flex-1']">
		<label class="mb-1 block text-sm font-medium leading-5">{{ label }}</label>
		<div class="relative flex items-center">
			<Listbox v-model="selected">
				<div class="relative w-fit">
					<ListboxButton
						class="relative w-full cursor-pointer rounded-none rounded-l-md border border-gray-300 bg-gray-50 py-2 pr-3 pl-8 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-white hover:text-gray-500 focus:z-10 focus:outline-blue-500 active:bg-gray-100 active:text-gray-700 sm:text-sm"
					>
						<span
							class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"
						>
							<ChevronRightIcon
								class="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</span>
						<span class="block truncate">{{ selected.addOn }}</span>
					</ListboxButton>
					<transition
						leave-active-class="transition duration-100 ease-in"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<ListboxOptions
							class="absolute z-10 mt-1 max-h-60 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
						>
							<ListboxOption
								v-slot="{ active, selected }"
								v-for="(option, index) in options"
								:key="index"
								:value="option"
								as="template"
							>
								<li
									:class="[
										active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
										'relative cursor-default select-none py-2 pl-10 pr-10'
									]"
								>
									<span class="block truncate">{{ option.display }}</span>
									<span
										v-if="selected"
										class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600"
									>
										<CheckIcon class="h-5 w-5" aria-hidden="true" />
									</span>
								</li>
							</ListboxOption>
						</ListboxOptions>
					</transition>
				</div>
			</Listbox>
			<input
				v-model="text"
				type="text"
				:class="[
					error
						? 'border-r-md border border-red-300 pr-10 text-red-900 placeholder:text-red-500 focus:border-red-500 focus:outline-none focus:ring-red-500'
						: 'border-gray-300 focus:border-blue-500 focus:ring-blue-500',
					'relative -ml-px inline-flex h-fit w-full items-center space-x-2 rounded-r-md border bg-white px-4 py-2 text-gray-700 focus:ring-1  sm:text-sm'
				]"
				:placeholder="placeholder"
			/>
			<div
				class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
				v-if="error"
			>
				<svg
					class="h-5 w-5 text-red-500"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fill-rule="evenodd"
						d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
		</div>
		<p class="mt-1 text-sm text-red-600" v-if="error">{{ error }}</p>
	</div>
</template>

<script>
import {
	Listbox,
	ListboxButton,
	ListboxOptions,
	ListboxOption
} from '@headlessui/vue'
import { CheckIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
export default {
	name: 'LinkInput',
	components: {
		Listbox,
		ListboxButton,
		ListboxOptions,
		ListboxOption,
		CheckIcon,
		ChevronRightIcon
	},
	emits: ['update:new', 'update:type', 'removeError'],
	props: {
		label: {
			type: String
		},
		new: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	created() {
		this.selected = this.options[0]
	},
	data: () => ({
		options: [
			{
				display: 'Internal',
				type: 'internal',
				addOn: process.env.VUE_APP_CLIENT_URL
			},
			{
				display: 'External (http://)',
				type: 'http',
				addOn: 'http://'
			},
			{
				display: 'External (https://)',
				type: 'https',
				addOn: 'https://'
			}
		],
		selected: null
	}),
	computed: {
		placeholder() {
			if (this.selected.type === 'internal') return '/example/files/item.pdf'
			return 'www.example.com'
		},
		text: {
			get() {
				return this.new
			},
			set(value) {
				this.$emit('update:new', value)
			}
		}
	},
	watch: {
		selected(newVal) {
			this.$emit('update:type', newVal.type)
		},
		type(newVal) {
			this.selected = this.options.find((item) => item.type === newVal)
		},
		new() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
