<template>
	<div class="rounded border border-gray-300 bg-gray-50 p-2 text-sm">
		<component
			:is="type"
			:props="component.props"
			:errors="error?.props"
			@removeError="removeError"
		></component>
	</div>
</template>

<script>
import CheckInputSettings from '@/components/formBuilder/toolboxItems/CheckInputSettings.vue'
import FileInput from '@/components/formBuilder/toolboxItems/FileInputSettings.vue'
import TextInputSettings from '@/components/formBuilder/toolboxItems/TextInputSettings.vue'
import RadioInputSettings from '@/components/formBuilder/toolboxItems/RadioInputSettings.vue'
import SelectInputSettings from '@/components/formBuilder/toolboxItems/SelectInputSettings.vue'
import TextAreaSettings from '@/components/formBuilder/toolboxItems/TextAreaSettings.vue'
export default {
	name: 'ComponentSettings',
	props: {
		component: {
			type: Object,
			required: true
		},
		error: {
			type: Object,
			required: false
		},
		componentIndex: {
			type: Number,
			required: false
		},
		rowIndex: {
			type: Number,
			required: false
		}
	},
	emits: ['removeError'],
	computed: {
		type() {
			return `${this.component.type}Settings`
		}
	},
	methods: {
		removeError(e) {
			this.$emit('removeError', [
				'content',
				this.rowIndex,
				'components',
				this.componentIndex,
				'props',
				...e
			])
		}
	},
	components: {
		CheckInputSettings,
		FileInput,
		TextInputSettings,
		RadioInputSettings,
		SelectInputSettings,
		TextAreaSettings
	}
}
</script>
