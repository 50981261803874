<template>
	<PageLayout :title="pageTitle">
		<div v-if="isIndex">
			<div class="mb-4 flex items-center justify-end">
				<button
					v-if="sorted"
					@click="updateIndexes"
					class="focus:shadow-outline-gray mr-2 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Save Order
				</button>
				<ShortcodeGenerator shortcode="leadership" />
				<router-link
					:to="{ name: 'LeadershipCreate' }"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
			<div class="overflow-hidden bg-white shadow sm:rounded-md">
				<div class="p-4 italic text-gray-500" v-if="leadership.length < 1">
					No leaderships found.
				</div>
				<draggable
					v-else
					tag="ul"
					handle=".handleRow"
					item-key="index"
					:list="leadership"
					@sort="sorted = true"
				>
					<template #item="{ element, index }">
						<li
							:class="[
								index > 0 ? 'border-t border-gray-200' : '',
								'flex items-center'
							]"
						>
							<span
								class="handleRow flex h-20 items-center bg-gray-300 p-3 lg:h-12"
							>
								<Bars3Icon class="h-5 w-5" />
							</span>
							<span class="grow">
								<div
									class="flex items-center justify-between transition duration-150 ease-in-out"
								>
									<div
										class="block flex-1 items-center justify-between px-4 py-3 text-sm leading-6 text-gray-700 sm:px-6 lg:flex"
									>
										<div>
											<span
												class="mr-2 text-xs font-light uppercase text-gray-400"
												>Name</span
											>
											<span class="font-semibold text-gray-900">{{
												element.name
											}}</span>
										</div>
										<div>
											<span
												class="mr-4 text-xs font-light uppercase text-gray-400"
												v-text="getStatus(element)"
											>
											</span>
											<span
												class="mr-2 text-xs font-light uppercase text-gray-400"
												>GROUP
											</span>
											<span class="font-semibold text-gray-900">{{
												upperCaseFirst(element.group)
											}}</span>
										</div>
									</div>
									<div
										class="'flex h-12 cursor-pointer items-center border-l py-3 px-4 text-gray-500 hover:text-gray-700"
										title="Copy Shortcode"
										@click="generateShortcode(element.id)"
									>
										<ClipboardDocumentIcon class="h-5 w-5" />
									</div>
									<router-link
										:to="{
											name: 'LeadershipEdit',
											params: { id: element.id }
										}"
										class="flex h-12 items-center border-l py-3 px-4 text-gray-400 hover:text-gray-700"
									>
										<svg
											class="h-5 w-5"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
											fill="currentColor"
											aria-hidden="true"
										>
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											/>
										</svg>
									</router-link>
								</div>
							</span>
						</li>
					</template>
				</draggable>
			</div>
			<div class="mt-4 flex justify-end">
				<button
					v-if="sorted"
					@click="updateIndexes"
					class="focus:shadow-outline-gray mr-2 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Save Order
				</button>
				<ShortcodeGenerator shortcode="leadership" />
				<router-link
					:to="{ name: 'LeadershipCreate' }"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
		</div>
		<router-view v-else />
	</PageLayout>
</template>

<script>
import { mapStores } from 'pinia'
import { useLeadershipStore } from '@/stores/leadership'
import { useMainStore } from '@/stores/main'
import { ClipboardDocumentIcon } from '@heroicons/vue/24/outline'
import { Bars3Icon } from '@heroicons/vue/24/solid'
import upperFirst from 'lodash/upperFirst'
import draggable from 'vuedraggable'
import ShortcodeGenerator from '@/components/utilities/ShortcodeGenerator'
export default {
	name: 'LeadershipIndex',
	components: {
		Bars3Icon,
		ClipboardDocumentIcon,
		draggable,
		ShortcodeGenerator
	},
	beforeRouteUpdate() {
		this.leadershipStore.fetchAll()
	},
	created() {
		this.leadershipStore.fetchAll()
	},
	data: () => ({
		sorted: false
	}),
	methods: {
		generateShortcode(id) {
			const leadership = this.leadership.find(
				(leadership) => leadership.id === id
			)
			const props = `group="${leadership.group}"`
			this.mainStore.copyClipboard(`[Leadership ${props}]`)
		},
		getStatus(element) {
			if (element.published) {
				return 'active'
			}

			return 'inactive'
		},
		updateIndexes() {
			this.leadershipStore.reorder()
			this.sorted = false

			this.mainStore.displayAlert({
				level: 'success',
				title: 'Order saved.',
				timer: 3000
			})
		},
		upperCaseFirst(word) {
			return upperFirst(word)
		}
	},
	computed: {
		...mapStores(useLeadershipStore, useMainStore),
		isIndex() {
			return this.$route.name === 'LeadershipIndex'
		},
		pageTitle() {
			if (this.isIndex) {
				return 'Leadership'
			}
			return this.$route.params.id ? 'Edit Leadership' : 'Create Leadership'
		},
		leadership() {
			return this.leadershipStore.getLeadership
		}
	}
}
</script>
