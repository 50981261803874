import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { useUsersStore } from './users'
import axios from 'axios'

export type State = {
	links: Link[]
	active: Link | null
}

export class Link {
	id?: number | null = null
	old?: string = ''
	new?: string = ''
	type?: string = 'internal'
	created?: string | null = null
	updated?: string | null = null
}

export const useLinksStore = defineStore('links', {
	state: (): State => ({
		links: [],
		active: new Link()
	}),
	getters: {
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		init() {
			this.active = new Link()
		},
		setActive(id: number) {
			this.active = structuredClone(this.links.find((link) => link.id === id))
		},
		async fetchLinks() {
			await this.usersStore.checkLogin()
			const { data } = await axios.get(
				`${process.env.VUE_APP_API_URL}/links`,
				this.userConfig
			)
			this.links = data
		},
		async create() {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/links`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Link created.',
					timer: 3000
				})
				this.fetchLinks()
				this.active = new Link()
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/links/${id}`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Link updated.',
					timer: 3000
				})
				this.fetchLinks()
				this.active = new Link()
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
				return false
			}
		},
		async remove(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/links/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Link deleted.',
					timer: 3000
				})
				this.fetchLinks()
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
