import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { State, Leadership, Index } from '@/types/leadership'
import { useUsersStore } from './users'
import axios from 'axios'

export const useLeadershipStore = defineStore('leadership', {
	state: (): State => ({
		leadership: [],
		active: {
			name: '',
			title: '',
			image: {
				id: null,
				path: null
			},
			imageId: null,
			description: '',
			published: null,
			index: null
		}
	}),
	getters: {
		activeLeadership(state) {
			return state.active
		},
		getLeadership(state) {
			return state.leadership
		},
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		addLeadership() {
			this.leadership.push(structuredClone(this.active))
		},
		initLeadership() {
			const newLeadership: Leadership = {
				name: '',
				title: '',
				image: {
					id: null,
					path: null
				},
				imageId: null,
				description: '',
				published: null,
				index: this.leadership.length
			}

			this.setActive(newLeadership)
		},
		setActive(leadership: Leadership) {
			this.active = leadership
		},
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/leadership`,
					this.userConfig
				)
				this.leadership = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: number) {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/leadership/${id}`,
					this.userConfig
				)
				this.active = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create() {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/leadership`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					position: 'top-right',
					level: 'success',
					title: 'Leadership created.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async reorder() {
			await this.usersStore.checkLogin()
			const order = this.leadership.map(({ id }) => id)
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/leadership/reorder`,
					{ order: order },
					this.userConfig
				)
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update(id: number, changes: Partial<Leadership>) {
			await this.usersStore.checkLogin()
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/leadership/${id}`,
					changes,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Leadership updated.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/leadership/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Leadership deleted.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
