<template>
	<div :class="{ 'flex space-x-2': imagePosition }">
		<div :class="imagePosition ? 'w-3/4' : 'w-full'">
			<label class="mb-1 block text-sm font-medium leading-5 text-gray-700">
				{{ label }}
			</label>
			<div class="grid grid-cols-1 items-center rounded-md shadow-sm">
				<div class="flex h-full">
					<span class="inline-flex h-full rounded-md">
						<button
							@click="toggleFilePicker"
							type="button"
							class="focus:shadow-outline-gray inline-flex h-full items-center rounded-l-md border border-gray-700 bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
						>
							Select
						</button>
					</span>
					<div
						:class="[
							{ 'border-red-300': error },
							'relative w-full overflow-x-auto whitespace-nowrap rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-4 py-2 text-sm leading-5 text-gray-600'
						]"
					>
						<span v-if="image" class="inline-block h-3">{{ image.path }}</span>
						<div
							class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
							v-if="error"
						>
							<svg
								class="h-5 w-5 text-red-500"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div v-if="sizeDisclaimer" class="mt-1 text-xs italic text-gray-500">
				Images need to be {{ sizeDisclaimer }}
			</div>
			<FilePicker
				class="z-10"
				v-if="filePickerActive"
				@cancel="toggleFilePicker"
				@insertImage="setImage"
				imagesOnly
				:showStyle="false"
			/>
			<p class="mt-1 text-sm text-red-600" v-if="error">{{ error }}</p>
		</div>
		<div v-if="imagePosition" :class="{ grow: imagePosition }">
			<FormSelect
				v-model="position"
				:options="positions"
				optionsDisplay="display"
				optionsValue="value"
				label="Image Position"
			/>
		</div>
	</div>
	<div class="mb-5" v-if="image">
		<div
			v-if="image.path"
			:class="[
				imagePosition ? 'mt-0' : 'mt-4',
				'relative mx-auto w-fit rounded'
			]"
		>
			<img
				:class="`${size} ${imagePosition} mx-auto h-full w-full border border-solid border-gray-300 object-scale-down`"
				:src="image.path"
				alt="header image"
			/>
			<button
				type="button"
				class="absolute top-0 right-0 mt-1 mr-2 text-gray-400 transition hover:text-gray-600 focus:ring-2"
				@click="removeImage"
			>
				<svg class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
					<path
						fill-rule="evenodd"
						d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
						clip-rule="evenodd"
					/>
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
import FilePicker from '@/components/utilities/FilePicker'
export default {
	name: 'SelectImage',
	emits: ['updateImageId', 'updateImage', 'updateImagePosition', 'removeError'],
	props: {
		image: {
			type: Object,
			default: () => ({ id: null, path: null }),
			required: false
		},
		imageId: {
			type: [String, Number],
			default: null,
			required: false
		},
		imageSize: {
			type: String,
			default: 'medium',
			required: false
		},
		imagePosition: {
			type: String,
			default: '',
			required: false
		},
		label: {
			type: String,
			default: 'Image',
			required: false
		},
		sizeDisclaimer: {
			type: String,
			default: '',
			required: false
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	components: {
		FilePicker
	},
	data: (vm) => ({
		imageData: vm.image,
		filePickerActive: false,
		positions: [
			{ display: 'Top', value: 'top' },
			{ display: 'Center', value: 'center' },
			{ display: 'Bottom', value: 'bottom' }
		]
	}),
	methods: {
		toggleFilePicker() {
			this.filePickerActive = !this.filePickerActive
		},
		setImage(image) {
			this.toggleFilePicker()
			this.$emit('updateImage', { id: image.id, path: image.src })
			this.$emit('updateImageId', image.id)
		},
		removeImage() {
			this.$emit('updateImage', { id: '', path: null })
			this.$emit('updateImageId', null)
		}
	},
	computed: {
		size() {
			if (this.imageSize === 'small') {
				return 'max-h-[150px] max-w-[250px]'
			} else if (this.imageSize === 'medium') {
				return 'max-h-[250px] max-w-[400px]'
			} else if (this.imageSize === 'large') {
				return 'max-h-[400px] max-w-[1600px]'
			}

			return 'max-h-[250px] max-w-[400px]'
		},
		position: {
			get() {
				return this.imagePosition
			},
			set(value) {
				this.$emit('updateImagePosition', value)
			}
		}
	},
	watch: {
		imageId() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
