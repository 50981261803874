<template>
	<div class="flex items-center justify-between">
		<div class="flex items-center">
			<label
				for="items"
				class="mr-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
			>
				items
			</label>
			<div class="mt-1 sm:col-span-2 sm:mt-0">
				<div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
					<select
						id="items"
						class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
						v-model="limit"
					>
						<option>10</option>
						<option>20</option>
						<option>50</option>
					</select>
				</div>
			</div>
		</div>
		<div class="hidden sm:block">
			<p class="text-sm text-gray-700">
				Showing <span class="font-medium">{{ from }}</span>
				to
				<span class="font-medium">{{ to }}</span>
				of
				<span class="font-medium">{{ total }}</span>
				results
			</p>
		</div>
		<nav class="flex items-center justify-between py-3" aria-label="Pagination">
			<ShortcodeGenerator v-if="shortcode" :shortcode="shortcode" />
			<button
				type="button"
				@click="prev"
				class="relative inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
			>
				Previous
			</button>
			<button
				type="button"
				@click="next"
				class="relative ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
			>
				Next
			</button>
		</nav>
	</div>
</template>

<script>
import ShortcodeGenerator from '@/components/utilities/ShortcodeGenerator'
export default {
	name: 'PaginatorComponent',
	props: {
		store: {
			required: true
		},
		hideButtons: {
			type: Boolean,
			required: false,
			default: false
		},
		shortcode: {
			type: String,
			default: '',
			required: false
		}
	},
	components: {
		ShortcodeGenerator
	},
	computed: {
		from() {
			return this.store.from
		},
		to() {
			return this.store.to
		},
		offset() {
			return this.store.getOffset
		},
		total() {
			return this.store.count
		},
		limit: {
			get() {
				return this.store.limit
			},
			set(value) {
				this.store.setLimit(+value)
			}
		}
	},
	methods: {
		next() {
			if (this.to < this.total) {
				this.store.nextPage()
				this.store.fetchAll()
			}
		},
		prev() {
			if (this.offset > 0) {
				this.store.prevPage()
				this.store.fetchAll()
			}
		}
	}
}
</script>
