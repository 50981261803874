export type State = {
	accessToken: string
	refreshToken: string
	users: User[]
	permissions: Permissions
}

export class User {
	id?: number | null = null
	email?: string = ''
	firstName?: string = ''
	lastName?: string = ''
	status?: string = 'pending'
	role: Role = 'user'
	permissions: Permissions = {}
	created?: string
	updated?: string
}

export type Role = 'user' | 'admin'

export type UserPayload = {
	email: string
	exp: number
	iat: number
	id: number
	permissions: Permissions
	role: Role
}

export type Tokens = {
	accessToken: string
	refreshToken: string
}

export type Permissions = {
	[component: string]: Level[]
}

export type PasswordReset = {
	verificationCode: string
	password: string
	confirmPassword: string
}

export type Level = 'create' | 'read' | 'update' | 'destroy'
