import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { State, Form } from '@/types/forms'
import { useUsersStore } from './users'
import axios from 'axios'

export const useFormsStore = defineStore('forms', {
	state: (): State => ({
		forms: [],
		active: {
			name: '',
			columns: 1,
			title: '',
			saved: '',
			email: [],
			content: [],
			submissions: [],
			published: '',
			expires: ''
		},
		span: {
			1: 'col-span-1',
			2: 'col-span-2',
			3: 'col-span-3',
			4: 'col-span-4',
			5: 'col-span-5',
			6: 'col-span-6',
			7: 'col-span-7',
			8: 'col-span-8',
			9: 'col-span-9',
			10: 'col-span-10',
			11: 'col-span-11',
			12: 'col-span-12'
		},
		cols: {
			1: 'grid-cols-1',
			2: 'grid-cols-2',
			3: 'grid-cols-3',
			4: 'grid-cols-4',
			5: 'grid-cols-5',
			6: 'grid-cols-6',
			7: 'grid-cols-7',
			8: 'grid-cols-8',
			9: 'grid-cols-9',
			10: 'grid-cols-10',
			11: 'grid-cols-11',
			12: 'grid-cols-12'
		},
		components: [
			{
				type: 'TextInput',
				name: 'Input',
				props: { __type: 'TextInput', label: '', width: 1, required: false }
			},
			{
				type: 'CheckInput',
				name: 'Checkbox',
				props: {
					__type: 'CheckInput',
					label: '',
					width: 1,
					description: '',
					boldLabel: true,
					boldDescription: false,
					orientation: 'stacked',
					required: false,
					options: [{ optionValue: '', optionName: '' }]
				}
			},
			{
				type: 'RadioInput',
				name: 'Radio',
				props: {
					__type: 'RadioInput',
					label: '',
					width: 1,
					description: '',
					boldLabel: true,
					boldDescription: false,
					orientation: 'stacked',
					required: false,
					options: [
						{ optionValue: '', optionName: '' },
						{ optionValue: '', optionName: '' }
					]
				}
			},
			{
				type: 'SelectInput',
				name: 'Select',
				props: {
					__type: 'SelectInput',
					label: '',
					width: 1,
					options: [],
					placeholder: '',
					required: false
				}
			},
			{
				type: 'TextArea',
				name: 'Textbox',
				props: {
					__type: 'TextArea',
					label: '',
					width: 12,
					rows: 3,
					required: false
				}
			}
		]
	}),
	getters: {
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		addForm() {
			this.forms.push(structuredClone(this.active))
		},
		initForm() {
			const newForm: Form = {
				name: '',
				title: '',
				saved: '',
				email: [],
				content: [],
				submissions: [],
				published: '',
				expires: ''
			}

			this.setActive(newForm)
		},
		setActive(form: Form) {
			this.active = form
		},
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/forms`,
					this.userConfig
				)
				this.forms = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: number) {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/forms/${id}`,
					this.userConfig
				)
				this.active = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create() {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/forms`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Form created.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update(id: number, changes: Partial<Form>) {
			await this.usersStore.checkLogin()
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/forms/${id}`,
					changes,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Form updated.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/forms/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Form deleted.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
