<template>
	<form @submit.prevent>
		<div>
			<div class="grid grid-cols-1 gap-4 md:grid-cols-2">
				<TextInput
					v-model="post.title"
					label="Title"
					:error="errors?.title"
					@keyup="updateSlug"
					@removeError="removeError(['title'])"
				/>
				<TextInput
					v-model="post.slug"
					label="Slug"
					:error="errors?.slug"
					@keypress="disableDynamicSlug"
					@removeError="removeError(['slug'])"
				/>
			</div>
			<div>
				<FormTextarea
					label="Meta Description (Social Media)"
					v-model="post.metaDescription"
					:error="errors?.metaDescription"
					@removeError="removeError(['metaDescription'])"
					:minLength="70"
					:maxLength="155"
					showCharacterCount
				/>
			</div>
			<div class="mb-5">
				<label class="mb-1 block text-sm font-medium leading-5 text-gray-700">
					Summary/Preview
				</label>
				<WysiwygEditor
					v-model="post.summary"
					:error="errors?.summary"
					@removeError="removeError(['summary'])"
				/>
				<FormSelect
					class="mt-4"
					label="Content Type"
					v-model="contentType"
					:options="['WYSIWYG', 'Content URL']"
				/>
				<div v-if="contentType === 'Content URL'" class="flex">
					<TextInput
						label="Content URL"
						v-model="post.contentUrl"
						placeholder="https://www.example.com"
						:error="errors?.contentUrl"
						@removeError="removeError(['contentUrl'])"
					/>
					<button
						type="button"
						class="mt-6 ml-3 mb-5 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:bg-gray-500 focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-300 disabled:hover:bg-gray-300"
						:disabled="refetchUrl"
						@click="refetchUrl = true"
					>
						Refetch URL
					</button>
				</div>
				<div v-if="contentType === 'WYSIWYG'">
					<label
						class="mt-5 mb-1 block text-sm font-medium leading-5 text-gray-700"
					>
						Body
					</label>
					<WysiwygEditor
						v-model="post.body"
						:error="errors?.body"
						@removeError="removeError(['body'])"
					/>
				</div>
				<div v-if="post.tags" class="mt-5">
					<TagInput
						:tags="post.tags"
						:error="errors?.tags"
						@add="addTag"
						@remove="removeTag"
						@removeError="removeError(['tags'])"
					/>
				</div>
			</div>
			<div class="md:flex md:space-x-3">
				<DateSelector
					class="flex-1"
					v-model="post.published"
					label="Published"
					includeSwitch
					includeTime
					:error="errors?.published"
					@removeError="removeError(['published'])"
				/>
				<DateSelector
					class="flex-1"
					v-model="post.expires"
					label="Expiration"
					includeSwitch
					includeTime
					:error="errors?.expires"
					@removeError="removeError(['expires'])"
				/>
			</div>
		</div>
		<div class="mt-8">
			<ManageControls @cancel="cancel" @remove="remove" @submit="submit" />
		</div>
	</form>
</template>

<script>
import { mapStores } from 'pinia'
import { usePostsStore } from '@/stores/posts'
import { useMainStore } from '@/stores/main'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import { toRaw } from 'vue'
import DateSelector from '@/components/utilities/form/DateSelector'
import ManageControls from '@/components/utilities/ManageControls'
import TagInput from '@/components/utilities/form/TagInput'
import WysiwygEditor from '@/components/utilities/form/WysiwygEditor'
import FormTextarea from '@/components/utilities/form/FormTextarea.vue'
export default {
	name: 'CreateEditPost',
	components: {
		DateSelector,
		FormTextarea,
		ManageControls,
		TagInput,
		WysiwygEditor
	},
	async created() {
		if (this.$route.params.id) {
			await this.loadData()
		}

		this.contentType = this.post.contentUrl ? 'Content URL' : 'WYSIWYG'
	},
	beforeUnmount() {
		this.postsStore.clearActive()
	},
	data: () => ({
		contentType: null,
		refetchUrl: false
	}),
	methods: {
		disableDynamicSlug() {
			this.postsStore.dynamicSlug = false
		},
		updateSlug() {
			this.postsStore.updateSlug()
			this.$forceUpdate()
		},
		addTag(tag) {
			this.post.tags.push(tag)
		},
		removeTag({ name }) {
			this.post.tags = this.post.tags.filter((tag) => tag.name !== name)
		},
		cancel() {
			this.$router.push({ name: 'PostsIndex' })
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async remove() {
			await this.postsStore.remove(this.post.id)
			this.cancel()
		},
		async submit() {
			if (this.editing) {
				const changes = differenceWith(
					toPairs(toRaw(this.post)),
					toPairs(this.original),
					isEqual
				)
				if (this.refetchUrl) {
					changes.push(['contentUrl', this.post.contentUrl])
				}

				await this.postsStore.update(this.post.id, fromPairs(changes))
			} else {
				await this.postsStore.create()
			}
			if (isEmpty(this.errors)) this.cancel()
		},
		async loadData() {
			if (this.editing) {
				await this.postsStore.fetchOne(this.$route.params.id)
				this.original = structuredClone(this.post)
			}
		}
	},
	computed: {
		...mapStores(usePostsStore, useMainStore),
		editing() {
			return !!this.$route.params.id
		},
		errors() {
			return this.mainStore.errors
		},
		post() {
			return this.postsStore.getActivePost
		},
		tags() {
			return this.post.tags
		}
	}
}
</script>
