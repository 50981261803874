
import { mapStores } from 'pinia'
import { useUsersStore } from '@/stores/users'
import { defineComponent } from 'vue'
import { User } from '@/types/users'
export default defineComponent({
	title: 'Users - iKTA',
	name: 'AdminUsers',
	async mounted() {
		await this.usersStore.fetchAll()
	},
	computed: {
		...mapStores(useUsersStore),
		isIndex() {
			return this.$route.name === 'Users'
		},
		pageTitle() {
			if (this.isIndex) {
				return 'Users'
			}
			return this.$route.params.id ? 'Edit User' : 'Create User'
		},
		users(): User[] {
			return this.usersStore.users
		},
		IsAdmin(): boolean {
			return this.usersStore.role === 'admin'
		},
		activeUser(): number {
			return this.usersStore.accessTokenPayload.id
		},
		errors() {
			return null
		}
	},
	methods: {
		setAltImg(event: { target: { src: string } }) {
			event.target.src = `/kta.svg`
		},
		formatPhone(number: string) {
			const parts = number.match(/^(\d{3})(\d{3})(\d{4})$/)
			if (parts) {
				return `(${parts[1]}) ${parts[2]}-${parts[3]}`
			}
		},
		getColor(status: string): string {
			if (status === 'inactive') return 'border-red-500 bg-red-200 text-red-700'
			if (status === 'pending')
				return 'border-yellow-500 bg-yellow-200 text-yellow-700'
			return 'border-green-500 bg-green-200 text-green-700'
		}
	}
})
