import axios from 'axios'
import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { useUsersStore } from './users'
import { OrderBy, Post, State } from '@/types/posts'
import kebabCase from 'lodash/kebabCase'

export const usePostsStore = defineStore('posts', {
	state: (): State => ({
		posts: [],
		page: 1,
		count: 6,
		limit: 10,
		offset: 0,
		filter: '',
		filterOptions: [
			{ key: 'published', value: 'Published' },
			{ key: 'unpublished', value: 'Unpublished' }
		],
		order: 'ASC',
		orderBy: { key: 'title', value: 'Title', type: 'string' },
		orderOptions: [
			{ key: 'title', value: 'Title', type: 'string' },
			{ key: 'published', value: 'Published', type: 'date' },
			{ key: 'created', value: 'Created', type: 'date' }
		],
		active: new Post(),
		dynamicSlug: true
	}),
	getters: {
		from(state) {
			return +state.offset + 1
		},
		to(state) {
			return state.limit > state.count
				? state.count
				: state.limit * state.page > state.count
				? state.count
				: state.limit * state.page
		},
		getActivePost(state) {
			return state.active
		},
		getOffset(state) {
			return state.offset
		},
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		setFilter(value: string) {
			this.filter = value
		},
		setOrderBy(orderBy: OrderBy) {
			this.orderBy = orderBy
		},
		setOrder(order: string) {
			this.order = order
		},
		setLimit(value: number) {
			this.limit = value
			this.offset = 0
			this.page = 1
		},
		nextPage() {
			this.page++
			this.offset += this.limit
		},
		prevPage() {
			this.page--
			this.offset -= this.limit
		},
		clearActive() {
			this.active = new Post()
			this.dynamicSlug = true
		},
		updateSlug() {
			if (this.dynamicSlug) {
				this.active.slug = kebabCase(this.active.title)
			}
		},
		async fetchAll(): Promise<void> {
			await this.usersStore.checkLogin()
			try {
				let query = this.offset
					? `?limit=${this.limit}&offset=${this.offset}`
					: `?limit=${this.limit}`
				query = this.filter ? `${query}&filter=${this.filter}` : query
				query =
					this.orderBy.key !== 'created' || this.order !== 'ASC'
						? `${query}&order=${this.order}&orderBy=${this.orderBy.key}`
						: query
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/posts${query}`,
					this.userConfig
				)
				this.posts = data.items
				this.count = data.count
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: string) {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/posts/${id}`,
					this.userConfig
				)
				this.dynamicSlug = false
				this.active = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create() {
			await this.usersStore.checkLogin()
			const tags = structuredClone(this.active.tags)
			this.active.tags = structuredClone(
				this.active.tags.map(({ name }: any) => name)
			)
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/posts`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Post created.',
					timer: 3000
				})
			} catch (error: any) {
				this.active.tags = tags
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update(id: number, changes: Partial<Post>) {
			await this.usersStore.checkLogin()
			changes.tags = structuredClone(changes.tags?.map(({ name }: any) => name))
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/posts/${id}`,
					changes,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Post updated.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove(id: string) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/posts/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Post deleted.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
