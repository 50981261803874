<template>
	<div class="flex items-center justify-center">
		<button
			class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
			@click="open"
		>
			Add Item
		</button>
	</div>
	<TransitionRoot appear :show="isOpen" as="template">
		<Dialog as="div" @close="close" class="relative z-10">
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 bg-black bg-opacity-25" />
			</TransitionChild>
			<div class="fixed inset-0 overflow-y-auto">
				<div
					class="flex min-h-full items-start justify-center p-4 text-center md:ml-64"
				>
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<DialogPanel
							class="relative mt-32 w-full max-w-4xl transform rounded-lg bg-white p-3 text-left align-middle shadow-xl transition-all"
						>
							<XMarkIcon
								class="absolute right-2 top-2 h-4 w-4 cursor-pointer text-gray-700 hover:text-gray-500"
								@click="close"
							/>
							<DialogTitle as="h3" class="mt-2 text-lg leading-6 text-black">
								Item Toolbox
							</DialogTitle>
							<div class="mt-4">
								<ComponentSelector
									ref="select"
									@itemSelect="changeComponent($event)"
								/>
							</div>
							<div v-if="selectedComponent" class="mt-7">
								<span>{{ selectedComponent.name }}</span>
								<div class="mt-1 bg-gray-100">
									<ComponentSettings
										:component="selectedComponent"
										@updateProps="updateProps($event)"
									/>
								</div>
								<span class="mt-2 flex justify-end">
									<button
										class="mr-2 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:border-gray-300 hover:shadow focus-visible:border-black active:bg-gray-200"
										@click="close"
									>
										Cancel
									</button>
									<button
										class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
										@click="addComponent"
									>
										Add Item
									</button>
								</span>
							</div>
							<div class="mt-4"></div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle
} from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import ComponentSelector from '@/components/formBuilder/ComponentSelector.vue'
import ComponentSettings from '@/components/formBuilder/ComponentSettings.vue'
export default {
	name: 'ComponentToolbox',
	emits: ['addComponent'],
	props: {
		iconButton: {
			type: Boolean,
			required: false
		}
	},
	components: {
		TransitionRoot,
		TransitionChild,
		Dialog,
		DialogPanel,
		DialogTitle,
		ComponentSelector,
		ComponentSettings,
		XMarkIcon
	},
	data: () => ({
		isOpen: false,
		selectedComponent: null
	}),
	methods: {
		addComponent() {
			this.$emit('addComponent', this.selectedComponent)
			this.reset()
		},
		changeComponent(component) {
			this.selectedComponent = structuredClone(component)
		},
		updateProps(props) {
			this.selectedComponent.props = props
		},
		close() {
			this.isOpen = false
			this.reset()
		},
		open() {
			this.isOpen = true
		},
		reset() {
			this.selectedComponent = null
			this.$refs.select.reset()
		}
	}
}
</script>
