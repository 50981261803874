<template>
	<TransitionRoot appear :show="isOpen" as="template">
		<Dialog as="div" @close="cancel">
			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div class="min-h-screen px-4 text-center">
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100"
						leave-to="opacity-0"
					>
						<DialogOverlay class="fixed inset-0" />
					</TransitionChild>

					<span class="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>

					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<div
							class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl border border-gray-100 bg-white p-6 text-left align-middle shadow-xl transition-all"
						>
							<DialogTitle
								as="h3"
								class="text-lg font-medium leading-6 text-gray-900"
							>
								Permanently Delete?
							</DialogTitle>
							<div class="mt-2">
								<p class="text-sm text-gray-500">
									This cannot be undone. Are you sure you want to permanently
									deleted?
								</p>
							</div>

							<div class="mt-4">
								<button
									type="button"
									class="mr-2 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
									@click="cancel"
								>
									Cancel
								</button>
								<button
									type="button"
									class="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
									@click="confirm"
								>
									Confirm
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogOverlay,
	DialogTitle
} from '@headlessui/vue'

export default {
	name: 'ConfirmationDialog',
	components: {
		TransitionRoot,
		TransitionChild,
		Dialog,
		DialogOverlay,
		DialogTitle
	},
	props: {
		isOpen: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		cancel() {
			this.$emit('confirmed', false)
		},
		confirm() {
			this.$emit('confirmed', true)
		}
	}
}
</script>
