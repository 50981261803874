<template>
	<div class="relative my-4">
		<div
			class="flex items-center justify-between rounded-t-md bg-gray-900 px-4 py-2 text-sm text-gray-300"
		>
			<div>Upload Folders</div>
			<span class="inline-flex rounded-md shadow-sm">
				<button
					type="button"
					class="focus:shadow-outline-gray inline-flex items-center rounded border border-transparent bg-gray-600 px-2.5 py-1.5 text-xs font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
					@click="showForm = true"
				>
					New
				</button>
			</span>
		</div>
		<ul class="rounded-b-md bg-white text-sm shadow-md">
			<router-link custom v-slot="{ navigate }" :to="{ name: 'Media' }">
				<li
					@click="navigate"
					class="flex cursor-pointer items-center px-2 py-2 leading-7 text-gray-600 hover:text-gray-900"
					active-class="font-bold"
				>
					<span class="mr-2">
						<svg
							class="h-4 w-4"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
							/>
						</svg>
					</span>
					<span>All Files</span>
				</li>
			</router-link>
			<router-link
				v-slot="{ navigate }"
				custom
				:to="{ name: 'MediaFolder', params: { id: folder.id } }"
				v-for="(folder, index) in folders"
				:key="index"
			>
				<li
					@click="navigate"
					class="flex cursor-pointer items-center justify-between border-t border-gray-200 p-2 text-gray-600 hover:text-gray-900"
					active-class="font-bold"
				>
					<div class="flex items-center">
						<span class="mr-2" v-if="activeFolder(folder.id)">
							<svg
								class="h-4 w-4"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
								/>
							</svg>
						</span>
						<span class="mr-2" v-else>
							<svg
								class="h-4 w-4"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
								/>
							</svg>
						</span>
						<span>{{ folder.title }}</span>
					</div>
					<span class="relative z-0 inline-flex shadow-sm">
						<button
							type="button"
							@click="edit(folder)"
							class="focus:shadow-outline-kta-blue relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-blue-700 focus:z-10 focus:border-blue-300 focus:outline-none active:bg-gray-100 active:text-gray-500"
							aria-label="Edit"
						>
							<svg
								class="h-4 w-4"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
								/>
							</svg>
						</button>
						<span class="relative">
							<button
								type="button"
								@click="remove(folder)"
								class="focus:shadow-outline-kta-blue relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-red-600 focus:border-blue-300 focus:outline-none active:bg-gray-100 active:text-gray-500"
								aria-label="Delete"
							>
								<svg
									class="h-4 w-4"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
									/>
								</svg>
							</button>
						</span>
					</span>
				</li>
			</router-link>
		</ul>
		<div
			class="absolute inset-x-0 top-0 flex items-center justify-center"
			v-if="showForm"
		>
			<form
				@submit.prevent
				class="w-full rounded-md border bg-gray-50 p-4 shadow-md"
			>
				<h2
					class="mb-2 font-bold"
					v-text="editing ? 'Edit Folder' : 'Create New Folder'"
				></h2>
				<TextInput v-model="title" label="Title" />
				<div class="mt-4 flex justify-end">
					<span class="inline-flex rounded-md shadow-sm">
						<button
							type="button"
							class="focus:shadow-outline-kta-blue rounded-md border border-gray-300 py-2 px-4 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
							@click="closeForm"
						>
							Cancel
						</button>
					</span>
					<span class="ml-3 inline-flex rounded-md shadow-sm">
						<button
							type="submit"
							class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
							@click="update"
							v-if="editing"
						>
							Update
						</button>
						<button
							type="submit"
							class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
							@click="save"
							v-else
						>
							Save
						</button>
					</span>
				</div>
			</form>
		</div>
		<ConfirmDialog :isOpen="confirm" @confirmed="confirmed" />
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMediaStore } from '../../stores/media'
import kebabCase from 'lodash/kebabCase'
import ConfirmDialog from '@/components/utilities/ConfirmDialog'
export default {
	name: 'FolderViewer',
	components: { ConfirmDialog },
	data() {
		return {
			confirm: false,
			editing: false,
			showForm: false,
			title: '',
			id: null
		}
	},
	methods: {
		activeFolder(folderId) {
			return 'id' in this.$route.params
				? this.$route.params.id === folderId
				: false
		},
		async confirmed(confirmed) {
			if (confirmed) {
				await this.mediaStore.deleteFolder(this.tempFolder.id)
				delete this.tempFolder
			}
			this.confirm = false
		},
		async remove(folder) {
			this.confirm = true
			this.tempFolder = folder
		},
		edit(folder) {
			this.title = folder.title
			this.id = folder.id
			this.editing = true
			this.showForm = true
		},
		async update() {
			await this.mediaStore.updateFolder({
				id: this.id,
				title: this.title,
				name: this.name
			})
			this.closeForm()
		},
		async save() {
			this.mediaStore.createFolder({
				title: this.title,
				name: this.name
			})
			this.closeForm()
		},
		closeForm() {
			this.showForm = false
			this.editing = false
			this.id = null
		}
	},
	props: {
		folders: {
			type: Array,
			required: true
		}
	},
	computed: {
		...mapStores(useMediaStore),
		name() {
			return kebabCase(this.title)
		}
	}
}
</script>
