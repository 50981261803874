<template>
	<div class="absolute inset-x-0 top-16 z-50 xl:top-10" v-if="linkMenuIsActive">
		<div class="fixed inset-0" @click="cancel"></div>
		<div
			class="relative mx-auto w-full min-w-max max-w-xl rounded border border-gray-300 bg-white p-3 shadow"
		>
			<div class="relative flex items-center">
				<form @submit.prevent="insert" class="flex-1">
					<input
						type="text"
						class="block w-full rounded-md border-gray-300 bg-white text-gray-700 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
						v-model="url"
						placeholder="https://"
						ref="linkInput"
						@keyup.enter="insert"
						@keydown.esc="cancel"
					/>
				</form>
				<span class="ml-3 rounded-md shadow-sm">
					<button
						@click="showFilePicker"
						type="button"
						class="whitespace-no-wrap focus:shadow-outline-kta-blue rounded-md border border-gray-300 py-2 px-3 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
					>
						Select File
					</button>
				</span>
			</div>
			<div class="mt-3 flex items-center justify-between">
				<div class="relative flex items-start">
					<div class="flex h-5 items-center">
						<input
							v-model="openInNewTab"
							id="blank"
							type="checkbox"
							class="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
						/>
					</div>
					<div class="ml-3 text-sm leading-5">
						<label for="blank" class="font-medium text-gray-700"
							>Open link in new tab</label
						>
					</div>
				</div>
				<div class="flex items-center">
					<span class="mr-2 inline-flex rounded-md shadow-sm">
						<button
							@click="cancel"
							class="focus:shadow-outline-kta-blue router-link-active rounded-md border border-gray-300 py-2 px-3 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
						>
							Cancel
						</button>
					</span>
					<span class="inline-flex rounded-md shadow-sm">
						<button
							@click="insert"
							type="button"
							class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
						>
							Insert
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LinkPicker',
	props: {
		linkMenuIsActive: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		url: null,
		openInNewTab: true,
		filePickerActive: false
	}),
	methods: {
		insert() {
			this.$emit('insert', { url: this.url, openInNewTab: this.openInNewTab })
		},
		cancel() {
			this.$emit('cancel')
		},
		showFilePicker() {
			this.$emit('showFilePicker')
		}
	}
}
</script>
