import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "space-y-2" }
const _hoisted_2 = { class: "flex space-x-5" }
const _hoisted_3 = { class: "md:flex md:space-x-3" }
const _hoisted_4 = { class: "flex justify-end" }
const _hoisted_5 = { class: "flex w-full items-center justify-between" }
const _hoisted_6 = { class: "handleRow flex w-full items-center" }
const _hoisted_7 = { class: "flex w-full items-center justify-end space-x-2" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "handleComponent" }
const _hoisted_12 = { class: "grow" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 0,
  class: "flex justify-end"
}
const _hoisted_16 = {
  key: 0,
  class: "mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_DateSelector = _resolveComponent("DateSelector")!
  const _component_FormToggle = _resolveComponent("FormToggle")!
  const _component_Bars3Icon = _resolveComponent("Bars3Icon")!
  const _component_ComponentToolbox = _resolveComponent("ComponentToolbox")!
  const _component_ChevronUpIcon = _resolveComponent("ChevronUpIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_Cog6ToothIcon = _resolveComponent("Cog6ToothIcon")!
  const _component_ComponentSettings = _resolveComponent("ComponentSettings")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_ManageControls = _resolveComponent("ManageControls")!
  const _component_FormPreview = _resolveComponent("FormPreview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.formMeta)
            ? (_openBlock(), _createBlock(_component_TextInput, {
                key: 0,
                class: "w-1/2",
                modelValue: _ctx.formMeta.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formMeta.name) = $event)),
                label: "Name",
                error: _ctx.errors?.name,
                onRemoveError: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeError(['name'])))
              }, null, 8, ["modelValue", "error"]))
            : _createCommentVNode("", true),
          (_ctx.formMeta)
            ? (_openBlock(), _createBlock(_component_TextInput, {
                key: 1,
                class: "w-1/2",
                modelValue: _ctx.formMeta.title,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formMeta.title) = $event)),
                label: "Title",
                error: _ctx.errors?.title,
                onRemoveError: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removeError(['title'])))
              }, null, 8, ["modelValue", "error"]))
            : _createCommentVNode("", true),
          (_ctx.formMeta)
            ? (_openBlock(), _createBlock(_component_FormSelect, {
                key: 2,
                class: "w-24",
                modelValue: _ctx.formMeta.columns,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formMeta.columns) = $event)),
                modelModifiers: { number: true },
                label: "Columns",
                options: _ctx.gridCols,
                error: _ctx.errors?.columns,
                onRemoveError: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeError(['columns'])))
              }, null, 8, ["modelValue", "options", "error"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_DateSelector, {
            class: "flex-1",
            modelValue: _ctx.formMeta.published,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formMeta.published) = $event)),
            label: "Published",
            includeSwitch: "",
            includeTime: "",
            error: _ctx.errors?.published,
            onRemoveError: _cache[7] || (_cache[7] = ($event: any) => (_ctx.removeError(['published'])))
          }, null, 8, ["modelValue", "error"]),
          _createVNode(_component_DateSelector, {
            class: "flex-1",
            modelValue: _ctx.formMeta.expires,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formMeta.expires) = $event)),
            label: "Expiration",
            includeSwitch: "",
            includeTime: "",
            error: _ctx.errors?.expires,
            onRemoveError: _cache[9] || (_cache[9] = ($event: any) => (_ctx.removeError(['expires'])))
          }, null, 8, ["modelValue", "error"])
        ]),
        _createElementVNode("span", null, [
          _createVNode(_component_TextInput, {
            label: "Email List",
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.email) = $event)),
            error: _ctx.errors?.email,
            onRemoveError: _cache[11] || (_cache[11] = ($event: any) => (_ctx.removeError(['email'])))
          }, null, 8, ["modelValue", "error"]),
          _createVNode(_component_FormToggle, {
            class: "mt-2 mb-5",
            modelValue: _ctx.formMeta.saved,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formMeta.saved) = $event)),
            label: "Save to database",
            labelLocation: "right"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("button", {
            class: "focus:shadow-outline-gray items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700",
            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.addRow()))
          }, " Add Row ")
        ])
      ]),
      _createVNode(_component_draggable, {
        list: _ctx.content,
        tag: "div",
        handle: ".handleRow",
        "item-key": "id",
        class: "space-y-2",
        onSort: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setActiveRow($event.newIndex, $event.oldIndex)))
      }, {
        item: _withCtx(({ element, index: rowIndex }) => [
          _createElementVNode("div", {
            class: _normalizeClass([
						_ctx.errors?.content?.[rowIndex]
							? 'border-red-200 bg-red-50'
							: 'border-gray-300 bg-gray-200',
						'w-full rounded-lg border  py-3 px-4'
					])
          }, [
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Bars3Icon, { class: "mr-4 h-5 w-5 text-gray-500" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.rowItems(rowIndex)), 1)
              ]),
              _createElementVNode("span", _hoisted_7, [
                _createVNode(_component_ComponentToolbox, {
                  onAddComponent: ($event: any) => (_ctx.addComponent($event, rowIndex))
                }, null, 8, ["onAddComponent"]),
                _createElementVNode("button", {
                  onClick: ($event: any) => (_ctx.setActiveRow(rowIndex)),
                  class: "rounded-md bg-gray-400 p-2 shadow-sm hover:bg-gray-500"
                }, [
                  _createVNode(_component_ChevronUpIcon, {
                    class: _normalizeClass([
										_ctx.activeRow === rowIndex ? 'rotate-180 transform' : '',
										'h-5 w-5 text-white'
									])
                  }, null, 8, ["class"])
                ], 8, _hoisted_8),
                _createElementVNode("button", {
                  title: "Delete Row",
                  class: "rounded-md bg-red-200 p-2 shadow-sm hover:bg-red-300",
                  onClick: ($event: any) => (_ctx.removeRow(rowIndex))
                }, [
                  _createVNode(_component_TrashIcon, { class: "h-5 w-5 text-red-800" })
                ], 8, _hoisted_9)
              ])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass([
							_ctx.activeRow === rowIndex ? 'block' : 'hidden',
							'mt-4 rounded border border-gray-300 bg-white p-3 shadow'
						])
            }, [
              _createVNode(_component_draggable, {
                list: element.components,
                tag: "div",
                handle: ".handleComponent",
                "item-key": "props",
                onSort: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setActiveComponent($event.newIndex, $event.oldIndex)))
              }, {
                item: _withCtx(({ element, index: componentIndex }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass([
										_ctx.errors?.content?.[rowIndex]?.components[componentIndex]
											? 'border-red-300 bg-red-100'
											: 'border-gray-300 bg-gray-100',
										'mb-2 space-y-3 rounded border  px-4 py-2 pb-2'
									])
                  }, [
                    _createElementVNode("span", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, [
                        _createVNode(_component_Bars3Icon, { class: "mr-4 h-5 w-5 text-gray-500" })
                      ]),
                      _createElementVNode("span", _hoisted_12, _toDisplayString(element.props.label
													? `${element.props.label}: ${element.name}`
													: `${element.name}`), 1),
                      _createElementVNode("button", {
                        onClick: ($event: any) => (_ctx.setActiveComponent(componentIndex)),
                        class: "mr-2"
                      }, [
                        _createVNode(_component_Cog6ToothIcon, { class: "h-6 w-6 text-gray-500 hover:text-gray-700" })
                      ], 8, _hoisted_13),
                      _createElementVNode("button", {
                        onClick: ($event: any) => (_ctx.removeComponent(componentIndex))
                      }, [
                        _createVNode(_component_TrashIcon, { class: "h-6 w-6 text-red-900 hover:text-red-800" })
                      ], 8, _hoisted_14)
                    ]),
                    _createVNode(_component_ComponentSettings, {
                      component: element,
                      componentIndex: componentIndex,
                      rowIndex: rowIndex,
                      error: 
											_ctx.errors?.content?.[rowIndex]?.components[componentIndex]
										,
                      class: _normalizeClass(
											_ctx.activeComponent === componentIndex ? 'block' : 'hidden'
										),
                      onRemoveError: _ctx.removeError,
                      onUpdateProps: ($event: any) => (_ctx.updateProps($event, componentIndex))
                    }, null, 8, ["component", "componentIndex", "rowIndex", "error", "class", "onRemoveError", "onUpdateProps"])
                  ], 2)
                ]),
                _: 2
              }, 1032, ["list"])
            ], 2)
          ], 2)
        ]),
        _: 1
      }, 8, ["list"]),
      (_ctx.content)
        ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
            (_ctx.content.length > 0)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700",
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.addRow()))
                }, " Add Row "))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.content)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createVNode(_component_ManageControls, {
            onCancel: _ctx.cancel,
            onRemove: _ctx.remove,
            onSubmit: _ctx.submit
          }, null, 8, ["onCancel", "onRemove", "onSubmit"]),
          (_ctx.formMeta.title)
            ? (_openBlock(), _createBlock(_component_FormPreview, {
                key: 0,
                title: _ctx.formMeta.title,
                form: _ctx.content
              }, null, 8, ["title", "form"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}