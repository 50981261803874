<template>
	<div>
		<div class="my-4 flex justify-end">
			<button
				@click="add"
				type="button"
				class="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
			>
				Add Item
			</button>
			<button
				v-if="edited"
				@click="save"
				type="button"
				class="ml-2 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
			>
				Save
			</button>
			<button
				@click="remove"
				type="button"
				class="ml-2 text-sm italic text-red-400 underline hover:text-red-600 focus:outline-none"
			>
				Delete
			</button>
		</div>
		<div class="p-2 text-sm italic text-gray-500" v-if="items.length < 1">
			This menu currently has no items
		</div>
		<MenuItemSorter :items="items" />
		<div class="mt-4 flex justify-end">
			<button
				@click="add"
				type="button"
				class="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
			>
				Add Item
			</button>
			<button
				v-if="edited"
				@click="save"
				type="button"
				class="ml-2 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
			>
				Save
			</button>
			<button
				@click="remove"
				type="button"
				class="ml-2 text-sm italic text-red-400 underline hover:text-red-600 focus:outline-none"
			>
				Delete
			</button>
		</div>
		<ConfirmDialog :isOpen="confirm" @confirmed="confirmed" />
	</div>
</template>
<script>
import { mapStores } from 'pinia'
import { useMenusStore } from '@/stores/menus'
import MenuItemSorter from '@/components/menu/MenuItemSorter.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog'
export default {
	name: 'MenuEditor',
	data: () => ({
		confirm: false
	}),
	computed: {
		...mapStores(useMenusStore),
		items: {
			get() {
				return this.menusStore.getActive?.items
					? this.menusStore.getActive.items
					: []
			},
			set(value) {
				this.menusStore.updateActiveMenu(value)
			}
		},
		edited() {
			return this.menusStore.isEdited
		},
		active() {
			return this.menusStore.getActive
		}
	},
	components: {
		ConfirmDialog,
		MenuItemSorter
	},
	methods: {
		remove() {
			this.confirm = true
		},
		async confirmed(confirmed) {
			if (confirmed) {
				await this.menusStore.remove()
			}
			this.confirm = false
		},
		add() {
			this.$emit('openEditor')
		},
		save() {
			this.edited && this.active.id
				? this.menusStore.update()
				: this.menusStore.create()
		}
	}
}
</script>
