export default [
	{ optionName: 'KS', optionValue: 'Kansas' },
	{ optionName: 'AL', optionValue: 'Alabama' },
	{ optionName: 'AK', optionValue: 'Alaska' },
	{ optionName: 'AZ', optionValue: 'Arizona' },
	{ optionName: 'AR', optionValue: 'Arkansas' },
	{ optionName: 'CA', optionValue: 'California' },
	{ optionName: 'CO', optionValue: 'Colorado' },
	{ optionName: 'CT', optionValue: 'Connecticut' },
	{ optionName: 'DE', optionValue: 'Delaware' },
	{ optionName: 'DC', optionValue: 'District Of Columbia' },
	{ optionName: 'FL', optionValue: 'Florida' },
	{ optionName: 'GA', optionValue: 'Georgia' },
	{ optionName: 'HI', optionValue: 'Hawaii' },
	{ optionName: 'ID', optionValue: 'Idaho' },
	{ optionName: 'IL', optionValue: 'Illinois' },
	{ optionName: 'IN', optionValue: 'Indiana' },
	{ optionName: 'IA', optionValue: 'Iowa' },
	{ optionName: 'KY', optionValue: 'Kentucky' },
	{ optionName: 'LA', optionValue: 'Louisiana' },
	{ optionName: 'ME', optionValue: 'Maine' },
	{ optionName: 'MD', optionValue: 'Maryland' },
	{ optionName: 'MA', optionValue: 'Massachusetts' },
	{ optionName: 'MI', optionValue: 'Michigan' },
	{ optionName: 'MN', optionValue: 'Minnesota' },
	{ optionName: 'MS', optionValue: 'Mississippi' },
	{ optionName: 'MO', optionValue: 'Missouri' },
	{ optionName: 'MT', optionValue: 'Montana' },
	{ optionName: 'NE', optionValue: 'Nebraska' },
	{ optionName: 'NV', optionValue: 'Nevada' },
	{ optionName: 'NH', optionValue: 'New Hampshire' },
	{ optionName: 'NJ', optionValue: 'New Jersey' },
	{ optionName: 'NM', optionValue: 'New Mexico' },
	{ optionName: 'NY', optionValue: 'New York' },
	{ optionName: 'NC', optionValue: 'North Carolina' },
	{ optionName: 'ND', optionValue: 'North Dakota' },
	{ optionName: 'OH', optionValue: 'Ohio' },
	{ optionName: 'OK', optionValue: 'Oklahoma' },
	{ optionName: 'OR', optionValue: 'Oregon' },
	{ optionName: 'PA', optionValue: 'Pennsylvania' },
	{ optionName: 'RI', optionValue: 'Rhode Island' },
	{ optionName: 'SC', optionValue: 'South Carolina' },
	{ optionName: 'SD', optionValue: 'South Dakota' },
	{ optionName: 'TN', optionValue: 'Tennessee' },
	{ optionName: 'TX', optionValue: 'Texas' },
	{ optionName: 'UT', optionValue: 'Utah' },
	{ optionName: 'VT', optionValue: 'Vermont' },
	{ optionName: 'VA', optionValue: 'Virginia' },
	{ optionName: 'WA', optionValue: 'Washington' },
	{ optionName: 'WV', optionValue: 'West Virginia' },
	{ optionName: 'WI', optionValue: 'Wisconsin' },
	{ optionName: 'WY', optionValue: 'Wyoming' }
]
