import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { useUsersStore } from './users'
import { State, Slide } from '@/types/slides'
import orderBy from 'lodash/orderBy'
import axios from 'axios'

export const useSlidesStore = defineStore('slides', {
	state: (): State => ({
		slides: [],
		active: {
			name: '',
			images: [],
			responsiveImageIds: {},
			textColor: 'white',
			imageOverlay: null,
			title: '',
			content: '',
			linkUrl: '',
			buttonTitle: '',
			published: null,
			expires: null,
			index: null
		}
	}),
	getters: {
		activeSlide(state) {
			return state.active
		},
		getSlides(state) {
			return state.slides
		},
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		initSlide() {
			const newSlide: Slide = {
				name: '',
				images: [],
				responsiveImageIds: {},
				title: '',
				content: '',
				linkUrl: '',
				buttonTitle: '',
				textColor: 'white',
				imageOverlay: null,
				published: null,
				expires: null,
				index: this.slides.length
			}

			this.setActive(newSlide)
		},
		setActive(slide: Slide) {
			this.active = slide
		},
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/slides`,
					this.userConfig
				)
				this.slides = orderBy(data, ['index', 'asc'])
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: number) {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/slides/${id}`,
					this.userConfig
				)
				this.active = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create() {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/slides`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Slide created.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async reorder() {
			await this.usersStore.checkLogin()
			const order = this.slides.map(({ id }) => id)
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/slides/reorder`,
					{ order: order },
					this.userConfig
				)
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update(id: number, changes: Partial<Slide>) {
			await this.usersStore.checkLogin()
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/slides/${id}`,
					changes,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Slide updated.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/slides/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Slide deleted.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
