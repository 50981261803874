<template>
	<form @submit.prevent>
		<div class="mb-4 w-full">
			<TextInput
				v-model="serviceArea.name"
				label="Service Area"
				:error="errors?.name"
				@removeError="removeError(['name'])"
			/>
			<SelectImage
				:image="serviceArea.image"
				:imageId="serviceArea.imageId"
				:imagePosition="serviceArea.imagePosition"
				:error="errors?.imageId"
				@updateImage="updateImage"
				@updateImageId="updateImageId"
				@updateImagePosition="updateImagePosition"
				@removeError="removeError(['imageId'])"
			/>
			<span class="grid grid-cols-3 gap-2">
				<TextInput
					v-model="serviceArea.mile"
					label="Milepost"
					:error="errors?.mile"
					@removeError="removeError(['mile'])"
				/>
				<TextInput
					v-model="serviceArea.position.lat"
					label="Latitude"
					:error="errors?.position?.lat"
					@removeError="removeError(['position', 'lat'])"
				/>
				<TextInput
					v-model="serviceArea.position.lng"
					label="Longitude"
					:error="errors?.position?.lng"
					@removeError="removeError(['position', 'lng'])"
				/>
			</span>
			<span>
				<span class="text-sm">Content</span>
				<WsyiwygEditor
					v-model="serviceArea.content"
					:error="errors?.content"
					@removeError="removeError(['content'])"
				/>
			</span>
		</div>
		<span class="text-sm">Amenities</span>
		<span class="mt-1 mb-4 grid grid-cols-4 gap-2">
			<label
				class="flex w-fit items-center"
				v-for="amenity in amenitiesList"
				:key="amenity.id"
			>
				<input
					type="checkbox"
					class="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-gray-500"
					v-model="amenities"
					:value="amenity"
				/>
				<span class="ml-2 text-sm">{{ amenity.name }}</span>
			</label>
		</span>
		<div>
			<draggable
				class="mt-6"
				tag="ul"
				handle=".handleRow"
				item-key="id"
				:list="serviceArea.amenities"
			>
				<template #item="{ element }">
					<li class="mb-1">
						<span class="flex items-center">
							<span
								class="handleRow rounded-l-md border border-r-0 border-gray-300 bg-gray-200 p-2"
							>
								<Bars3Icon class="h-5 w-5" />
							</span>
							<span
								class="block w-1/4 rounded-r-md border-gray-300 bg-white p-2 pl-2 shadow-sm sm:text-sm"
								>{{ element.name }}
							</span>
						</span>
					</li>
				</template>
			</draggable>
		</div>
		<DateSelector
			class="mt-6"
			v-model="serviceArea.published"
			label="Publish"
			includeSwitch
			excludeDate
			:error="errors?.published"
			@removeError="removeError(['published'])"
		/>
		<div v-if="serviceArea" class="mt-6">
			<ManageControls
				@cancel="cancel"
				@remove="remove"
				@submit="editing ? update() : create()"
			/>
			<ServiceAreaPreview :serviceArea="serviceArea" />
		</div>
	</form>
</template>

<script>
import { mapStores } from 'pinia'
import { useServiceAreasStore } from '@/stores/serviceAreas'
import { useMainStore } from '@/stores/main'
import { Bars3Icon } from '@heroicons/vue/24/solid'
import DateSelector from '@/components/utilities/form/DateSelector'
import draggable from 'vuedraggable'
import ManageControls from '@/components/utilities/ManageControls'
import SelectImage from '@/components/utilities/form/SelectImage'
import ServiceAreaPreview from '@/components/serviceArea/ServiceAreaPreview'
import WsyiwygEditor from '@/components/utilities/form/WysiwygEditor'
export default {
	name: 'CreateEditServiceAreas',
	components: {
		DateSelector,
		draggable,
		ManageControls,
		Bars3Icon,
		SelectImage,
		ServiceAreaPreview,
		WsyiwygEditor
	},
	async created() {
		await this.serviceAreasStore.fetchAmenities()
		if (this.$route.params.id) {
			await this.serviceAreasStore.fetchOne(this.$route.params.id)
		}
	},
	beforeUnmount() {
		this.serviceAreasStore.resetActive()
	},
	data: () => ({
		position: [
			{ display: 'Top', value: 'object-top' },
			{ display: 'Center', value: 'object-center' },
			{ display: 'Bottom', value: 'object-bottom' }
		]
	}),
	methods: {
		cancel() {
			this.$router.push({ name: 'ServiceAreasIndex' })
		},
		updateImage(image) {
			this.serviceArea.image = image
		},
		updateImageId(id) {
			this.serviceArea.imageId = id
		},
		updateImagePosition(position) {
			this.serviceArea.imagePosition = position
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async remove() {
			const success = await this.serviceAreasStore.remove()
			if (success) {
				this.cancel()
			}
		},
		async update() {
			const success = await this.serviceAreasStore.update()
			if (success) {
				this.cancel()
			}
		},
		async create() {
			const success = await this.serviceAreasStore.create()
			if (success) {
				this.cancel()
			}
		}
	},
	computed: {
		...mapStores(useServiceAreasStore, useMainStore),
		editing() {
			return !!this.$route.params.id
		},
		amenitiesList() {
			return this.serviceAreasStore.amenities
		},
		serviceArea() {
			return this.serviceAreasStore.active
		},
		errors() {
			return this.mainStore.errors
		},
		amenities: {
			get() {
				return this.serviceArea.amenities
			},
			set(value) {
				this.serviceArea.amenities = value
			}
		}
	}
}
</script>
