<template>
	<div
		class="w-full"
		:class="[error ? 'mb-3' : 'mb-5', inlineLabel ? 'flex items-center' : '']"
	>
		<div class="fixed inset-0 z-10" @click="clear" v-if="showTags"></div>
		<label
			class="mb-1 block text-sm font-medium leading-5 text-gray-700"
			:class="{ 'mr-2 whitespace-nowrap': inlineLabel }"
			>Tags</label
		>
		<div
			class="relative flex w-full items-center rounded-md border border-gray-300 bg-white px-2 shadow-sm"
			:class="[
				error
					? 'rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
					: 'focus:border-gray-500 focus:ring-gray-500'
			]"
		>
			<div class="flex shrink-0 flex-wrap space-x-1">
				<span
					class="inline-flex items-center rounded-md bg-gray-800 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-200"
					v-for="(tag, index) in tags"
					:key="index"
				>
					{{ tag.name }}
					<button
						type="button"
						@click="remove(tag)"
						class="ml-0.5 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-600 hover:text-gray-200 focus:bg-gray-500 focus:text-gray-200 focus:outline-none"
					>
						<span class="sr-only">Remove {{ tag.name }}</span>
						<svg
							class="h-2 w-2"
							stroke="currentColor"
							fill="none"
							viewBox="0 0 8 8"
						>
							<path
								stroke-linecap="round"
								stroke-width="1.5"
								d="M1 1l6 6m0-6L1 7"
							/>
						</svg>
					</button>
				</span>
			</div>
			<div class="relative w-full">
				<input
					type="text"
					v-model="tag"
					class="block w-full border-transparent bg-transparent text-gray-700 outline-none focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
					:class="{ 'animated shake': shake }"
					aria-label="Tags"
					placeholder="Add Tag"
					@keydown="handleKeydown"
					@keydown.tab="clear"
					@keydown.enter.prevent="add"
				/>
				<div
					v-show="showTags"
					class="min-w-64 absolute left-2 top-9 z-10 w-full max-w-sm rounded-md bg-gray-800 py-2 text-sm text-white"
				>
					<ul>
						<li
							class="cursor-pointer px-3 leading-7"
							:class="{ 'bg-gray-600': activeOption === index }"
							v-for="(tag, index) in filtered"
							@mouseover="activeOption = index"
							@click="add"
							:key="tag.id"
						>
							{{ tag.name }}
						</li>
					</ul>
				</div>
			</div>
			<div
				class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
				v-if="error"
			>
				<svg
					class="h-5 w-5 text-red-500"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fill-rule="evenodd"
						d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
		</div>
		<p class="mt-1 text-sm text-red-600" v-if="error">{{ error }}</p>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useTagsStore } from '@/stores/tags'
export default {
	name: 'TagInput',
	props: {
		tags: {
			type: Array,
			required: true
		},
		error: {
			type: String,
			default: '',
			required: false
		},
		inlineLabel: {
			type: Boolean,
			default: false
		}
	},
	emits: ['add', 'remove', 'removeError'],
	created() {
		if (!this.tagsStore.tags.length) this.tagsStore.fetchAll()
	},
	data: () => ({
		tag: '',
		shake: false,
		activeOption: null
	}),
	watch: {
		tag(value) {
			if (value && this.filtered.length) {
				this.activeOption = 0
			} else {
				this.activeOption = null
			}
		}
	},
	computed: {
		...mapStores(useTagsStore),
		showTags() {
			return (
				(this.filtered.length && !!this.tag) ||
				(this.filtered.length && this.activeOption !== null)
			)
		},
		filtered() {
			return this.availableTags.filter((tag) =>
				tag.name.toLowerCase().startsWith(this.tag.toLowerCase())
			)
		},
		availableTags() {
			return this.tagsStore.tags.filter((tag) => {
				const found = this.tags?.find(
					(activeTag) => activeTag.name === tag.name
				)
				if (!found) return tag
			})
		}
	},
	methods: {
		clear() {
			this.tag = ''
			this.activeOption = null
		},
		add() {
			if (this.activeOption !== null) {
				this.$emit('add', this.filtered[this.activeOption])
				if (this.activeOption === this.availableTags.length) {
					this.activeOption--
				}
				this.tag = ''
				this.$emit('removeError')
			} else {
				this.reject()
			}
		},
		remove(tag) {
			this.$emit('remove', tag)
		},
		handleKeydown(e) {
			if (e.key === 'ArrowDown') {
				this.activeOption === null ||
				this.activeOption === this.filtered.length - 1
					? (this.activeOption = 0)
					: this.activeOption++
			}
			if (e.key === 'ArrowUp') {
				this.activeOption !== 0
					? this.activeOption--
					: (this.activeOption = this.filtered.length - 1)
			}
			if (e.key === 'Escape') {
				this.activeOption = null
			}
		},
		reject() {
			this.shake = true
			setTimeout(() => {
				this.shake = false
			}, 500)
		}
	}
}
</script>

<style scoped>
.animated {
	animation-duration: 0.4s;
	animation-fill-mode: both;
	animation-iteration-count: 1;
	animation-timing-function: linear;
}

@keyframes shake {
	0%,
	100% {
		transform: translateX(0px);
	}
	15%,
	45%,
	85% {
		transform: translateX(-5px);
	}
	30%,
	60% {
		transform: translateX(5px);
	}
}
.shake {
	animation-name: shake;
}
</style>
