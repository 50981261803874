
import { mapStores } from 'pinia'
import { useFormsStore } from '@/stores/forms'
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'WFTextArea',
	props: {
		label: {
			type: String,
			required: true
		},
		width: {
			type: [String, Number],
			default: 12,
			required: false
		},
		rows: {
			type: [String, Number],
			default: 3,
			required: false
		},
		required: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	computed: {
		...mapStores(useFormsStore),
		span() {
			return this.formsStore.span[this.width]
		}
	}
})
