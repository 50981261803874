import Image from '@tiptap/extension-image'
// import { mergeAttributes } from '@tiptap/core'

export default Image.extend({
	name: 'custom-image',
	addAttributes() {
		return {
			...Image.config.addAttributes(),
			size: {
				default: 'custom-item-small'
			},
			float: {
				default: 'float-none'
			},
			class: {
				default: `float-none custom-item-small`
			}
		}
	},
	addCommands() {
		return {
			setImage:
				(options) =>
				({ tr, commands }) => {
					if (tr.selection?.node?.type?.name == 'custom-image') {
						return commands.updateAttributes('custom-image', options)
					} else {
						return commands.insertContent({
							type: this.name,
							attrs: options
						})
					}
				},
			setFloat:
				(float) =>
				({ tr, commands }) => {
					tr.selection.node.attrs.float = `float-${float}`
					return commands.updateAttributes(tr.selection?.node?.type?.name, {
						class: `${tr.selection.node.attrs.float} ${tr.selection.node.attrs.size}`
					})
				},
			setSize:
				(size) =>
				({ tr, commands }) => {
					tr.selection.node.attrs.size = `custom-item-${size}`
					return commands.updateAttributes(tr.selection?.node?.type?.name, {
						class: `${tr.selection.node.attrs.float} ${tr.selection.node.attrs.size}`
					})
				}
		}
	}
	// renderHTML({ node, HTMLAttributes }) {
	// 	HTMLAttributes.class = ' custom-image-' + node.attrs.size
	// 	HTMLAttributes.class += ' custom-image-float-' + node.attrs.float
	// 	return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
	// }
})
