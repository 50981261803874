<template>
	<div class="mr-3 flex items-center justify-center">
		<button
			type="button"
			@click="openModal"
			class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
		>
			Create Shortcode
		</button>
	</div>
	<TransitionRoot appear :show="isOpen" as="template">
		<Dialog as="div" @close="closeModal" class="relative z-10">
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 bg-black bg-opacity-25" />
			</TransitionChild>

			<div class="fixed inset-0 overflow-y-auto">
				<div
					class="flex min-h-full items-center justify-center p-4 text-center md:ml-64"
				>
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<DialogPanel
							class="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
						>
							<DialogTitle
								as="h3"
								class="text-lg font-medium leading-6 text-gray-900"
							>
								Shortcode Generation
							</DialogTitle>
							<div class="my-4">
								<div v-if="shortcode === 'posts'">
									<TagInput
										:tags="tags"
										@add="addTag"
										@remove="removeTag"
										:error="''"
									/>
									<span class="mt-4 flex space-x-2">
										<FormSelect
											class="w-1/2 text-gray-700"
											v-model="columns"
											label="Columns"
											:options="options.slice(0, 4)"
										/>
										<FormSelect
											class="w-1/2 text-gray-700"
											v-model="itemsPerPage"
											label="Posts Per Page"
											:options="options"
										/>
									</span>
									<FormToggle
										v-model="pagination"
										label="Pagination"
										labelLocation="right"
									/>
								</div>
								<div v-if="shortcode === 'leadership'">
									<span class="flex space-x-2">
										<FormSelect
											class="mb-0 w-1/2 text-gray-700"
											v-model="group"
											label="Group"
											:options="groups"
										/>
										<FormSelect
											class="mb-0 w-1/2 text-gray-700"
											v-model="columns"
											label="Columns"
											:options="options.slice(0, 4)"
										/>
									</span>
								</div>
							</div>
							<div :class="shortcode === 'posts' && 'mt-8'">
								<label class="block text-sm font-medium text-gray-700"
									>Shortcode</label
								>
								<div class="mt-1 flex rounded-md shadow-sm">
									<input
										type="text"
										v-model="shortcodeCopy"
										class="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
										disabled
									/>
									<button
										title="Copy Shortcode"
										class="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 hover:text-gray-700 sm:text-sm"
										@click="copyShortcode"
									>
										<ClipboardDocumentIcon class="h-5 w-5" />
									</button>
								</div>
							</div>
							<div class="mt-6 flex justify-end">
								<button
									type="button"
									class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
									@click="closeModal"
								>
									OK
								</button>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/main'
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle
} from '@headlessui/vue'
import { ClipboardDocumentIcon } from '@heroicons/vue/24/outline'
import map from 'lodash/map'
import FormToggle from '@/components/utilities/form/FormToggle'
import TagInput from '@/components/utilities/form/TagInput'
export default {
	name: 'ShortcodeGenerator',
	components: {
		TransitionRoot,
		TransitionChild,
		Dialog,
		DialogPanel,
		DialogTitle,
		ClipboardDocumentIcon,
		FormToggle,
		TagInput
	},
	props: {
		shortcode: {
			type: String,
			default: '',
			required: false
		}
	},
	data: () => ({
		isOpen: false,
		tags: [],
		group: '',
		groups: ['director', 'board'],
		pagination: false,
		columns: '2',
		itemsPerPage: '4',
		options: [1, 2, 3, 4, 5, 6, 7, 8]
	}),
	methods: {
		openModal() {
			this.isOpen = true
		},
		closeModal() {
			this.isOpen = false
		},
		addTag(tag) {
			this.tags.push(tag)
		},
		removeTag({ name }) {
			this.tags = this.tags.filter((tag) => tag.name !== name)
		},
		copyShortcode() {
			this.mainStore.copyClipboard(this.shortcodeCopy)
		}
	},
	computed: {
		...mapStores(useMainStore),
		shortcodeCopy() {
			if (this.shortcode === 'posts')
				return `[Posts ${this.tagsShortcode} ${this.layoutShortcode} ${this.paginationShortcode}]`
			if (this.shortcode === 'leadership')
				return `[Leadership group="${this.group}" :columns="${this.columns}"]`

			return ''
		},
		tagsShortcode() {
			return `categories="${map(this.tags, 'name').join()}"`
		},
		layoutShortcode() {
			return `:columns="${this.columns}" :postsPerPage="${this.itemsPerPage}"`
		},
		paginationShortcode() {
			return this.pagination ? 'pagination' : ''
		}
	}
}
</script>
