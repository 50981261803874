<template>
	<transition
		enter-active-class="transition ease-out duration-200"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="transition ease-in duration-200"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div
			v-if="alert.level"
			:class="[
				position[1] ? `text-${position[1]}` : '',
				`fixed ${position[0]}-5 left-5 right-5 z-50`
			]"
		>
			<div
				:class="[
					position[1] ? 'inline-block' : 'w-full',
					`rounded-md bg-${color}-200 p-4 shadow`
				]"
			>
				<div class="flex items-center">
					<div class="shrink-0">
						<ExclamationTriangleIcon
							class="h-5 w-5 text-yellow-400"
							aria-hidden="true"
							v-if="level === 'warning'"
						/>
						<XCircleIcon
							class="h-5 w-5 text-red-400"
							aria-hidden="true"
							v-if="level === 'error'"
						/>
						<CheckCircleIcon
							class="h-5 w-5 text-green-400"
							aria-hidden="true"
							v-if="level === 'success'"
						/>
						<InformationCircleIcon
							class="h-5 w-5 text-blue-400"
							aria-hidden="true"
							v-if="level === 'info'"
						/>
					</div>
					<div class="ml-3">
						<h3 :class="`text-sm font-medium text-${color}-800`" v-if="title">
							{{ title }}
						</h3>
						<div
							:class="`mt-2 text-sm text-${color}-700`"
							v-if="text"
							v-html="text"
						></div>
					</div>
					<div class="ml-auto pl-3">
						<div class="-mx-1.5 -my-1.5">
							<button
								@click="close"
								type="button"
								:class="`
                inline-flex
                bg-${color}-200
                rounded-md
                p-1.5
                text-${color}-500
                hover:bg-${color}-100
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-${color}-50
                focus:ring-${color}-600
              `"
							>
								<span class="sr-only">Dismiss</span>
								<XMarkIcon class="h-5 w-5" aria-hidden="true" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/main'
import {
	CheckCircleIcon,
	ExclamationTriangleIcon,
	InformationCircleIcon,
	XCircleIcon,
	XMarkIcon
} from '@heroicons/vue/24/solid'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const classes = ['bottom-5', 'top-5']
export default {
	name: 'AlertComponent',
	components: {
		CheckCircleIcon,
		ExclamationTriangleIcon,
		InformationCircleIcon,
		XCircleIcon,
		XMarkIcon
	},
	computed: {
		...mapStores(useMainStore),
		alert() {
			return this.mainStore.getAlert
		},
		color() {
			switch (this.alert.level) {
				case 'success':
					return 'green'
				case 'error':
					return 'red'
				case 'warning':
					return 'yellow'
				default:
					return 'blue'
			}
		},
		error() {
			return []
			// return this.$store.getters.getErrors
		},
		level() {
			return this.alert ? this.alert.level : 'info'
		},
		text() {
			return this.alert.text
		},
		title() {
			return this.alert.title
		},
		position() {
			return this.alert.position.split('-')
		}
	},
	methods: {
		close() {
			this.mainStore.closeAlert()
		}
	},
	watch: {
		alert: {
			handler(value) {
				if (value.timer) {
					setTimeout(() => {
						this.close()
					}, value.timer)
				}
			},
			deep: true
		}
	}
}
</script>
