import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-2 leading-none"
}
const _hoisted_2 = ["aria-label", "rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(`w-full text-dark ${_ctx.span}`)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.required ? `${_ctx.label} *` : _ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      class: "block w-full rounded-md border border-medium focus:border-sky",
      "aria-label": _ctx.label,
      rows: _ctx.rows
    }, null, 8, _hoisted_2)
  ], 2))
}