<template>
	<PageLayout :title="pageTitle">
		<div v-if="isIndex">
			<DataControls :store="pagesStore" />
			<div class="flex items-center justify-end">
				<PaginatorComponent :store="pagesStore" class="flex-1" />
				<router-link
					:to="{ name: 'PagesCreate' }"
					class="focus:shadow-outline-gray ml-4 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
			<DisplayItems :items="formatted" to="PagesEdit" />
			<div class="flex items-center justify-end">
				<PaginatorComponent :store="pagesStore" class="flex-1" />
				<router-link
					:to="{ name: 'PagesCreate' }"
					class="focus:shadow-outline-gray ml-4 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
		</div>
		<router-view v-else />
	</PageLayout>
</template>

<script>
import { mapStores } from 'pinia'
import { usePagesStore } from '@/stores/pages'
import { format } from 'date-fns'
import DisplayItems from '@/views/layout/DisplayItems.vue'
import DataControls from '@/components/utilities/DataControls'
import PaginatorComponent from '@/components/utilities/PaginatorComponent'

export default {
	title: 'Pages',
	name: 'PageIndex',
	components: {
		DataControls,
		DisplayItems,
		PaginatorComponent
	},
	beforeRouteUpdate() {
		this.pagesStore.fetchAll()
	},
	created() {
		this.pagesStore.fetchAll()
	},
	computed: {
		...mapStores(usePagesStore),
		isIndex() {
			return this.$route.name === 'PagesIndex'
		},
		pageTitle() {
			if (this.isIndex) {
				return 'Pages'
			}
			return this.$route.params.id ? 'Edit Page' : 'Create Page'
		},
		pages() {
			return this.pagesStore.pages
		},
		itemsPerPage() {
			return this.pagesStore.limit
		},
		formatted() {
			return this.pages.map((page) => {
				page.created = format(new Date(page.created), 'MM/dd/yyyy h:mm a')
				page.updated = format(new Date(page.updated), 'MM/dd/yyyy h:mm a')
				if (page.published)
					page.published = format(new Date(page.published), 'MM/dd/yyyy h:mm a')

				delete page.body
				delete page.history
				delete page.headerImage
				delete page.headerImageId
				return page
			})
		}
	},
	watch: {
		itemsPerPage() {
			this.pagesStore.fetchAll()
		}
	}
}
</script>
