<template>
	<Listbox v-model="selectedItem">
		<div class="relative mt-1 w-60">
			<ListboxButton
				class="text-md relative w-full cursor-default rounded border border-medium bg-white py-2 pl-3 pr-10 text-left sm:text-base"
			>
				<span class="block truncate">{{ selectedItem.name }}</span>
				<span
					class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
				>
					<ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				</span>
			</ListboxButton>
			<transition
				leave-active-class="transition duration-100 ease-in"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<ListboxOptions
					class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md border border-medium bg-white py-1 text-base ring-1 ring-black ring-opacity-5 scrollbar-hide focus:outline-none sm:text-sm"
				>
					<ListboxOption
						v-slot="{ active, selected }"
						v-for="component in components"
						:key="component.type"
						:value="component"
						as="template"
					>
						<li
							:class="[
								active ? 'bg-kta-blue text-white' : 'text-extra-dark',
								'content-li-none group relative cursor-default select-none py-2 px-4 pl-10'
							]"
						>
							<span
								:class="[
									selected ? 'font-semibold' : 'font-normal',
									'block truncate text-sm sm:text-base'
								]"
								>{{ component.name }}</span
							>
							<span
								v-if="selected"
								class="text-blue absolute inset-y-0 left-0 flex items-center pl-3"
							>
								<CheckIcon
									:class="[active ? 'text-white' : 'text-blue', 'h-5 w-5']"
									aria-hidden="true"
								/>
							</span>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</transition>
		</div>
	</Listbox>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormsStore } from '@/stores/forms.ts'
import {
	Listbox,
	ListboxButton,
	ListboxOptions,
	ListboxOption
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/24/solid'

export default {
	name: 'BuilderSelectComponent',
	emits: ['itemSelect'],
	components: {
		Listbox,
		ListboxButton,
		ListboxOptions,
		ListboxOption,
		CheckIcon,
		ChevronUpDownIcon
	},
	data: () => ({
		selectedItem: { name: 'Select Item' }
	}),
	computed: {
		...mapStores(useFormsStore),
		components() {
			return this.formsStore.components
		}
	},
	methods: {
		reset() {
			this.selectedItem = { name: 'Select Item' }
		}
	},
	watch: {
		selectedItem(item) {
			if (item.type) this.$emit('itemSelect', item)
		}
	}
}
</script>
