import { defineStore } from 'pinia'
import { Role, State, Tokens, User, UserPayload } from '@/types/users'
import { isAfter } from 'date-fns'
import axios from 'axios'
import { useMainStore } from './main'
import { PasswordReset } from '../types/users'

export const useUsersStore = defineStore('users', {
	state: (): State => ({
		accessToken: '',
		refreshToken: '',
		users: [],
		permissions: {}
	}),
	getters: {
		mainStore() {
			return useMainStore()
		},
		config(state) {
			return {
				headers: {
					Authorization: `Bearer ${state.accessToken}`
				}
			}
		},
		accessTokenPayload(state): UserPayload {
			return state.accessToken
				? JSON.parse(atob(state.accessToken.split('.')[1]))
				: ''
		},
		refreshTokenPayload(state): UserPayload {
			return state.refreshToken
				? JSON.parse(atob(state.refreshToken.split('.')[1]))
				: ''
		},
		role(): Role | null {
			return this.accessTokenPayload ? this.accessTokenPayload.role : null
		},
		getPermissions(state) {
			return state.permissions
		}
	},
	actions: {
		isLoggedIn(): boolean {
			return isAfter(new Date(this.accessTokenPayload.exp * 1000), new Date())
		},
		isRefreshable(): boolean {
			return isAfter(new Date(this.refreshTokenPayload.exp * 1000), new Date())
		},
		async checkLogin(): Promise<void> {
			if (!this.isLoggedIn()) {
				this.isRefreshable() ? await this.refreshTokens() : this.logout()
			}
		},
		setTokens(tokens: Tokens): void {
			this.accessToken = tokens.accessToken
			this.refreshToken = tokens.refreshToken

			localStorage.setItem('accessToken', tokens.accessToken)
			localStorage.setItem('refreshToken', tokens.refreshToken)
		},
		async login(form: { email: string; password: string }): Promise<void> {
			try {
				const { data } = await axios.post(
					`${process.env.VUE_APP_API_URL}/auth/login`,
					form
				)
				this.setTokens(data)
				this.checkRedirect()
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		checkRedirect(): { name: string } {
			const redirect = sessionStorage.getItem('from')
			sessionStorage.removeItem('from')

			return redirect ? JSON.parse(redirect) : { name: 'AdminDashboard' }
		},
		async refreshTokens(): Promise<void> {
			const { data } = await axios.post(
				`${process.env.VUE_APP_API_URL}/auth/refresh`,
				{ refreshToken: this.refreshToken }
			)
			this.setTokens(data)
		},
		retrieveStoredTokens(): void {
			this.accessToken = localStorage.getItem('accessToken') || ''
			this.refreshToken = localStorage.getItem('refreshToken') || ''
		},
		logout(): void {
			this.accessToken = ''
			this.refreshToken = ''
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')
		},
		async forgotPassword(email: string): Promise<boolean> {
			try {
				await axios.get(
					`${process.env.VUE_APP_API_URL}/auth/forgot-password?email=${email}`
				)
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
				return false
			}
		},
		async resetPassword(passwordReset: PasswordReset): Promise<any> {
			try {
				const { data } = await axios.post(
					`${process.env.VUE_APP_API_URL}/auth/reset-password`,
					passwordReset
				)
				this.setTokens(data)
				return this.checkRedirect()
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchAll(): Promise<void> {
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/users`,
					this.config
				)
				this.users = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: number): Promise<any> {
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/users/${id}`,
					this.config
				)
				data.permissions = data.permissions ? data.permissions : {}
				return data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchPermissions(): Promise<void> {
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/users/permissions`,
					this.config
				)
				this.permissions = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create(user: User): Promise<boolean> {
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/users`,
					user,
					this.config
				)
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
				return false
			}
		},
		async update(id: number, user: User): Promise<boolean> {
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/users/${id}`,
					user,
					this.config
				)
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
				return false
			}
		}
	}
})
