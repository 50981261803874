<template>
	<h2 class="mt-8 mb-4 font-bold">Form Preview</h2>
	<div class="mt-1 rounded border border-zinc-300 bg-white p-6 shadow">
		<div class="mx-auto max-w-screen-md space-y-5" v-if="html">
			<h3>{{ title }}</h3>
			<component
				:is="{
					name: 'FormRender',
					template: `${html}`
				}"
			></component>
		</div>
		<span v-else class="text-gray-400"><em>No Form Preview</em></span>
	</div>
</template>

<script>
export default {
	name: 'FormPreview',
	props: {
		form: {
			type: Object,
			required: true
		},
		title: {
			type: String,
			required: true
		}
	},
	data: () => ({
		enabled: false
	}),
	methods: {
		convertForm() {
			const parseValues = (values) => {
				if (Array.isArray(values)) {
					return `[${values.reduce((string, curr) => {
						return `${string}, '${JSON.stringify(curr)}'`
					}, '')}]`
						.replace(/^\[,/, '[')
						.replace(/'/g, '')
						.replace(/"/g, "'")
				}
				return values
			}

			const parseProps = (props) => {
				if (!props) return
				const keys = Object.keys(props)
				const values = Object.values(props)
				return keys.reduce((string, key, index) => {
					return `${string} ${
						typeof values[index] === 'string' ? key : `:${key}`
					}="${parseValues(values[index])}"`
				}, '')
			}

			const parseForm = (form) => {
				return form.reduce((string, item) => {
					if (!item.components) {
						return `${string}<WF${item.type} ${parseProps(item.props)}/>`
					}
					return `${string}<WF${item.type} ${parseProps(
						item.props
					)}>${parseForm(item.components)}</WF${item.type}>`
				}, '')
			}

			return parseForm(this.form)
		}
	},
	computed: {
		html() {
			return this.convertForm()
		}
	}
}
</script>

<style scoped>
h3 {
	font-size: 1.75rem;
	font-weight: 800;
	color: #000;
	line-height: 2.125rem;
}
</style>
