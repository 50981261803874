import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { State, Amenity } from '@/types/amenities'
import { useUsersStore } from './users'
import axios from 'axios'

export const useAmenitiesStore = defineStore('amenities', {
	state: (): State => ({
		amenities: [],
		active: {
			name: '',
			image: {
				id: null,
				path: null
			},
			imageId: null
		}
	}),
	getters: {
		activeAmenity(state) {
			return state.active
		},
		getAmenities(state) {
			return state.amenities
		},
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		addAmenity() {
			this.amenities.push(structuredClone(this.active))
		},
		initAmenity() {
			const newAmenity: Amenity = {
				name: '',
				image: {
					id: null,
					path: null
				},
				imageId: null
			}

			this.setActive(newAmenity)
		},
		setActive(amenity: Amenity) {
			this.active = amenity
		},
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/amenities`,
					this.userConfig
				)
				this.amenities = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: number) {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/amenities/${id}`,
					this.userConfig
				)
				this.active = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create() {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/amenities`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Amenity created.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update(id: number, changes: Partial<Amenity>) {
			await this.usersStore.checkLogin()
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/amenities/${id}`,
					changes,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Amenity updated.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/amenities/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Amenity deleted.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
