// import { worker } from '../mocks/browser.js'
// import { createApp } from 'vue'
import { createApp } from 'vue/dist/vue.esm-bundler'
import { createPinia } from 'pinia'
import '@/assets/main.css'

import App from './App.vue'
import router from './router'

import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'
import PageLayout from '@/views/layout/PageLayout.vue'

import TextInput from '@/components/utilities/form/TextInput.vue'
import FormSelect from '@/components/utilities/form/FormSelect.vue'
import Textarea from '@/components/utilities/form/FormTextarea.vue'
import FormCheckbox from '@/components/utilities/form/FormCheckbox.vue'

import LoadAnimation from '@/components/utilities/LoadAnimation.vue'
import BodyText from '@/components/utilities/BodyText.vue'

import {
	WFCheckInput,
	WFFileInput,
	WFFormRow,
	WFRadioInput,
	WFSelectInput,
	WFTextArea,
	WFTextInput
} from '@/components/formBuilder/wireframes'

// if (process.env.VUE_APP_USE_MOCK) {
// 	worker.start({
// 		onUnhandledRequest: 'bypass'
// 	})
// }

const app = createApp(App)
	.use(router)
	.use(createPinia())
	.use(VCalendar, {})
	.component('PageLayout', PageLayout)
	.component('TextInput', TextInput)
	.component('Textarea', Textarea)
	.component('FormSelect', FormSelect)
	.component('FormCheckbox', FormCheckbox)
	.component('LoadAnimation', LoadAnimation)
	.component('BodyText', BodyText)
	.component('WFFormRow', WFFormRow)
	.component('WFTextInput', WFTextInput)
	.component('WFFileInput', WFFileInput)
	.component('WFCheckInput', WFCheckInput)
	.component('WFRadioInput', WFRadioInput)
	.component('WFSelectInput', WFSelectInput)
	.component('WFTextArea', WFTextArea)

app.mount('#app')
