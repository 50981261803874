import Youtube from '@tiptap/extension-youtube'

export default Youtube.extend({
	name: 'custom-youtube',

	addAttributes() {
		return {
			...this.parent?.(),
			size: {
				default: ''
			},
			float: {
				default: 'float-none mr-6'
			},
			class: {
				default: 'float-none mr-6'
			}
		}
	},
	addCommands() {
		return {
			...this.parent?.(),
			setFloat:
				(float) =>
				({ tr, commands }) => {
					const padding = float === 'right' ? 'ml-6' : 'mr-6'
					tr.selection.node.attrs.float = `float-${float} ${padding}`
					return commands.updateAttributes(tr.selection?.node?.type?.name, {
						class: `${tr.selection.node.attrs.float} ${tr.selection.node.attrs.size}`
					})
				}
		}
	}
})
