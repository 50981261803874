<template>
	<div class="mb-4 flex justify-end">
		<router-link
			:to="{ name: 'FormsSubmissionsIndex' }"
			class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
		>
			Back
		</router-link>
	</div>
	<div v-if="submission">
		<span class="mb-2 inline-block text-lg font-bold">{{ form.title }}</span>
		<div class="flex flex-col space-y-4 rounded-md border bg-white p-4">
			<span v-for="(field, index) in formData" :key="index">
				{{ field.label }}: {{ field.value }}
			</span>
		</div>
		<span class="text-sm text-dark"
			>Submitted {{ formatDate(submission?.created) }}</span
		>
	</div>
	<div class="mt-4 flex justify-end">
		<router-link
			:to="{ name: 'FormsSubmissionsIndex' }"
			class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
		>
			Back
		</router-link>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormsStore } from '@/stores/forms'
import { format } from 'date-fns'
export default {
	name: 'FormSubmissions',
	async created() {
		if (this.$route.params.id)
			await this.formsStore.fetchOne(+this.$route.params.id)
	},
	methods: {
		formatDate(date) {
			return format(new Date(date), 'PPpp')
		}
	},
	computed: {
		...mapStores(useFormsStore, useFormsStore),
		form() {
			return this.formsStore.active
		},
		submission() {
			return this.form.submissions.find(
				(submission) => +submission.id === +this.$route.params.submitId
			)
		},
		formData() {
			return JSON.parse(this.submission?.data)
		}
	}
}
</script>
