<template>
	<div
		:class="[error ? 'mb-3' : 'mb-5', inlineLabel ? 'flex items-center' : '']"
	>
		<label
			class="mb-1 block text-sm font-medium leading-5"
			:class="{ 'mr-2 whitespace-nowrap': inlineLabel }"
		>
			{{ label }}
		</label>
		<div class="relative w-full">
			<textarea
				:readonly="readonly"
				:value="modelValue"
				:tabindex="tabindex"
				:rows="height"
				@input="$emit('update:modelValue', $event.target.value)"
				class="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
				:class="[
					error
						? 'rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
						: 'focus:border-blue-500 focus:ring-blue-500',
					readonly
						? 'cursor-not-allowed bg-gray-200 text-gray-600'
						: 'bg-white text-gray-700'
				]"
				:aria-label="label"
			/>
			<div
				class="pointer-events-none absolute top-0 right-0 flex items-center pt-3 pr-3"
				v-if="error"
			>
				<svg
					class="h-5 w-5 text-red-500"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fill-rule="evenodd"
						d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
		</div>
		<div
			class="flex items-center justify-between pt-1"
			v-if="helpText || showCharacterCount"
		>
			<span class="text-xs italic text-gray-500">{{ helpText }}</span>
			<div class="text-xs italic text-gray-500">
				<span v-show="minLength || maxLength" class="mr-4">Characters:</span>
				<span v-show="minLength" class="mr-4">Min: {{ minLength }}</span>
				<span v-show="maxLength" class="mr-4">Max: {{ maxLength }}</span>
				<span>
					Count:
					<span
						:class="[
							{ 'text-red-500': minLength && modelValue?.length < minLength },
							{ 'text-red-500': maxLength && modelValue?.length > maxLength },
							{ 'text-red-500': !modelValue?.length }
						]"
						>{{ modelValue?.length || 0 }}</span
					>
				</span>
			</div>
		</div>
		<p class="mt-1 text-sm text-red-600" v-if="error">{{ error }}</p>
	</div>
</template>

<script>
export default {
	name: 'FormTextarea',
	emits: ['update:modelValue', 'removeError'],
	props: {
		modelValue: {
			type: [String, Number],
			default: ''
		},
		readonly: {
			type: Boolean
		},
		label: {
			type: String
		},
		helpText: {
			type: String
		},
		error: {
			type: String
		},
		inlineLabel: {
			type: Boolean,
			default: false
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		height: {
			type: String,
			required: false,
			default: '3'
		},
		showCharacterCount: {
			type: Boolean,
			required: false,
			default: false
		},
		minLength: {
			type: Number,
			required: false
		},
		maxLength: {
			type: Number,
			required: false
		}
	},
	watch: {
		modelValue() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
