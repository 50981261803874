<template>
	<div class="flex items-center">
		<span
			class="mr-3 text-sm font-medium text-gray-900"
			v-if="labelLocation !== 'right'"
			>{{ label }}</span
		>
		<button
			type="button"
			@click="toggle"
			class="
        
      "
			role="switch"
			:aria-checked="modelValue"
			:aria-labelledby="label"
			:class="[
				modelValue ? 'bg-gray-700' : 'bg-gray-200',
				'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cobalt focus:ring-offset-2'
			]"
		>
			<span
				aria-hidden="true"
				:class="[
					modelValue ? 'translate-x-5' : 'translate-x-0',
					'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
				]"
			></span>
		</button>
		<span
			class="ml-3 text-sm font-medium text-gray-900"
			v-if="labelLocation === 'right'"
			>{{ label }}</span
		>
	</div>
</template>

<script>
export default {
	name: 'FormToggle',
	props: {
		modelValue: {
			type: Boolean,
			require: true,
			default: false
		},
		label: {
			type: String,
			required: true
		},
		labelLocation: {
			type: String,
			required: false,
			default: 'left'
		}
	},
	methods: {
		toggle() {
			this.$emit('update:modelValue', !this.modelValue)
		}
	}
}
</script>
