<template>
	<div
		:class="[
			{ 'hover:animate-flip-front hover:animate-flip-back': !click },
			'grid grid-cols-1'
		]"
		aria-roledescription="Flip card"
	>
		<div
			:class="[
				[
					!cardFlipped
						? 'rotate-y-0 z-10 opacity-100'
						: 'rotate-y-180 z-0 opacity-0'
				],
				'focus-visible:kta-ring ease-elastic col-start-1 row-start-1 motion-safe:transition-all motion-safe:duration-500'
			]"
			tabindex="0"
			@click="flipCard(true)"
		>
			<slot name="front"></slot>
		</div>
		<div
			:class="[
				[
					cardFlipped
						? 'rotate-y-0 z-10 opacity-100'
						: '-rotate-y-180 z-0 opacity-0'
				],
				'focus-visible:kta-ring ease-elastic col-start-1 row-start-1 motion-safe:transition-all motion-safe:duration-500'
			]"
			tabindex="0"
			@focusin="flipCard(true, 'focus')"
			@focusout="flipCard(false, 'focus')"
			@click="flipCard(false)"
		>
			<slot name="back"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CardFlip',
	props: {
		click: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data: () => ({
		cardFlipped: null
	}),
	methods: {
		flipCard(bool, focus) {
			if (this.click || focus) this.cardFlipped = bool
		}
	}
}
</script>
