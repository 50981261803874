<template>
	<TextInput
		v-model="amenity.name"
		label="Amenity Name"
		:error="errors?.name"
		@removeError="removeError(['name'])"
	/>
	<SelectImage
		:image="amenity.image"
		:imageId="amenity.imageId"
		@updateImage="updateImage"
		@updateImageId="updateImageId"
	/>
	<div class="mt-6">
		<ManageControls @cancel="cancel" @remove="remove" @submit="submit" />
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAmenitiesStore } from '@/stores/amenities'
import { useMainStore } from '@/stores/main'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import { toRaw } from 'vue'
import ManageControls from '@/components/utilities/ManageControls'
import SelectImage from '@/components/utilities/form/SelectImage'
export default {
	name: 'CreateEditAmenities',
	components: {
		ManageControls,
		SelectImage
	},
	created() {
		if (this.$route.params.id) {
			this.loadData()
		} else {
			this.amenitiesStore.initAmenity()
		}
	},
	beforeUnmount() {
		this.amenitiesStore.setActive({})
	},
	methods: {
		cancel() {
			this.$router.push({ name: 'AmenitiesIndex' })
		},
		updateImage(image) {
			this.amenity.image = image
		},
		updateImageId(id) {
			this.amenity.imageId = id
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async remove() {
			await this.amenitiesStore.remove(this.amenity.id)
			this.cancel()
		},
		async submit() {
			if (this.editing) {
				delete this.amenity.image
				delete this.original.image
				const changes = differenceWith(
					toPairs(toRaw(this.amenity)),
					toPairs(this.original),
					isEqual
				)
				await this.amenitiesStore.update(this.amenity.id, fromPairs(changes))
			} else {
				await this.amenitiesStore.create()
			}
			if (isEmpty(this.errors)) this.cancel()
		},
		async loadData() {
			if (this.editing)
				await this.amenitiesStore.fetchOne(this.$route.params.id)
			this.original = structuredClone(this.amenity)
		}
	},
	computed: {
		...mapStores(useAmenitiesStore, useMainStore),
		editing() {
			return !!this.$route.params.id
		},
		amenity() {
			return this.amenitiesStore.activeAmenity
		},
		errors() {
			return this.mainStore.errors
		}
	}
}
</script>
