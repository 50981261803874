<template>
	<div class="pt-2 pb-6 md:py-6">
		<div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
			<h1 class="mb-6 text-2xl font-semibold text-gray-900">Media</h1>
			<span class="inline-flex rounded-md shadow-sm">
				<button
					type="button"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
					@click="showUploader"
				>
					Upload File
				</button>
			</span>
		</div>
		<div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 xl:flex">
			<div class="xl:mr-6 xl:w-1/4">
				<FolderViewer :folders="folders" />
			</div>
			<FileViewer class="xl:w-3/4" />
		</div>
	</div>
</template>

<script>
import FolderViewer from '@/components/media/FolderViewer'
import FileViewer from '@/components/media/FileViewer'
import { mapStores } from 'pinia'
import { useMediaStore } from '@/stores/media'
export default {
	title: 'Media - iKTA',
	name: 'MediaManager',
	beforeRouteEnter(to, from, next) {
		const mediaStore = useMediaStore()
		next(() => {
			'id' in to.params
				? mediaStore.setActiveFolder(+to.params.id)
				: mediaStore.setActiveFolder(null)
			mediaStore.activeMediaFolder
				? mediaStore.fetchFiles()
				: mediaStore.fetchAllFiles()
		})
	},
	beforeRouteUpdate(to, from, next) {
		'id' in to.params
			? this.mediaStore.setActiveFolder(to.params.id)
			: this.mediaStore.setActiveFolder(null)
		next()
	},
	created() {
		this.mediaStore.fetchFolders()
	},
	components: {
		FolderViewer,
		FileViewer
	},
	computed: {
		...mapStores(useMediaStore),
		activeFolder() {
			return this.mediaStore.getActiveFolder
		},
		folders() {
			return this.mediaStore.getFolders
		},
		files() {
			return this.mediaStore.getFiles
		}
	},
	watch: {
		activeFolder(id) {
			id ? this.mediaStore.fetchFiles() : this.mediaStore.fetchAllFiles()
		}
	},
	methods: {
		showUploader() {
			this.mediaStore.toggleFileUploader()
		}
	}
}
</script>
