<template>
	<div v-if="leadership" class="w-full">
		<TextInput
			v-model="leadership.name"
			label="Name"
			:error="errors?.name"
			@removeError="removeError(['name'])"
		/>
		<FormSelect
			v-model="leadership.group"
			:options="groups"
			optionsDisplay="display"
			optionsValue="value"
			label="Group"
			:error="errors?.group"
			@removeError="removeError(['group'])"
		/>
		<TextInput
			v-model="leadership.title"
			label="Title"
			:error="errors?.title"
			@removeError="removeError(['title'])"
		/>
		<SelectImage
			:image="leadership.image"
			:imageId="leadership.imageId"
			:error="errors?.imageId"
			@updateImage="updateImage"
			@updateImageId="updateImageId"
			@removeError="removeError(['imageId'])"
		/>
		<TextInput
			v-model="leadership.link"
			label="LinkedIn Profile"
			:error="errors?.link"
			@removeError="removeError(['link'])"
		/>
		<div class="mb-5">
			<span class="text-sm">Description</span>
			<WsyiwygEditor
				v-model="leadership.description"
				:error="errors?.description"
				@removeError="removeError(['description'])"
			/>
		</div>
		<DateSelector
			class="mt-6"
			v-model="leadership.published"
			label="Publish"
			includeSwitch
			excludeDate
			:error="errors?.published"
			@removeError="removeError(['published'])"
		/>
		<div class="mt-6">
			<ManageControls @cancel="cancel" @remove="remove" @submit="submit" />
			<LeadershipPreview :leadership="leadership" />
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useLeadershipStore } from '@/stores/leadership'
import { useMainStore } from '@/stores/main'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import { toRaw } from 'vue'
import DateSelector from '@/components/utilities/form/DateSelector'
import LeadershipPreview from '@/components/leadership/LeadershipPreview'
import ManageControls from '@/components/utilities/ManageControls'
import SelectImage from '@/components/utilities/form/SelectImage'
import WsyiwygEditor from '@/components/utilities/form/WysiwygEditor'
export default {
	name: 'CreateEditLeadership',
	components: {
		DateSelector,
		LeadershipPreview,
		ManageControls,
		SelectImage,
		WsyiwygEditor
	},
	data: () => ({
		groups: [
			{ display: 'Director', value: 'director' },
			{ display: 'Board', value: 'board' }
		]
	}),
	created() {
		if (this.$route.params.id) {
			this.loadData()
		} else {
			this.leadershipStore.initLeadership()
		}
	},
	beforeUnmount() {
		this.leadershipStore.setActive({})
	},
	methods: {
		cancel() {
			this.$router.push({ name: 'LeadershipIndex' })
		},
		updateImage(image) {
			this.leadership.image = image
		},
		updateImageId(id) {
			this.leadership.imageId = id
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async remove() {
			await this.leadershipStore.remove(this.leadership.id)
			this.cancel()
		},
		async submit() {
			if (this.editing) {
				delete this.leadership.image
				delete this.original.image
				const changes = differenceWith(
					toPairs(toRaw(this.leadership)),
					toPairs(this.original),
					isEqual
				)
				await this.leadershipStore.update(
					this.leadership.id,
					fromPairs(changes)
				)
			} else {
				await this.leadershipStore.create()
			}

			if (isEmpty(this.errors)) this.cancel()
		},
		async loadData() {
			if (this.editing) {
				await this.leadershipStore.fetchOne(this.$route.params.id)
				this.original = structuredClone(this.leadership)
			}
		}
	},
	computed: {
		...mapStores(useLeadershipStore, useMainStore),
		editing() {
			return !!this.$route.params.id
		},
		leadership() {
			return this.leadershipStore.activeLeadership
		},
		errors() {
			return this.mainStore.errors
		},
		published: {
			get() {
				return !!this.leadership.published
			},
			set(value) {
				this.leadership.published = value ? new Date() : null
			}
		}
	}
}
</script>
