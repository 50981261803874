<template>
	<div v-if="slide" class="w-full">
		<TextInput
			v-model="slide.name"
			label="Slide Name"
			:error="errors?.name"
			@removeError="removeError(['name'])"
		/>
		<span v-if="slide.images" class="grid grid-cols-3 gap-2">
			<span>
				<SelectImage
					label="Desktop Image"
					sizeDisclaimer="max 576px x 320px"
					:image="findImage(imageIds?.desktop)"
					:imageId="imageIds?.desktop"
					:error="errors?.imageId"
					@updateImage="updateImage($event, 'desktop')"
					@updateImageId="updateImageId($event, 'desktop')"
					@removeError="removeError(['imageId'])"
				/>
			</span>
			<span>
				<SelectImage
					label="Tablet Image"
					sizeDisclaimer="max 1024px x 308px"
					:image="findImage(imageIds?.tablet)"
					:imageId="imageIds?.tablet"
					:error="errors?.imageId"
					@updateImage="updateImage($event, 'tablet')"
					@updateImageId="updateImageId($event, 'tablet')"
					@removeError="removeError(['imageId'])"
				/>
			</span>
			<span>
				<SelectImage
					label="Mobile Image"
					sizeDisclaimer="max 640px x 640px"
					:image="findImage(imageIds?.mobile)"
					:imageId="imageIds?.mobile"
					:error="errors?.imageId"
					@updateImage="updateImage($event, 'mobile')"
					@updateImageId="updateImageId($event, 'mobile')"
					@removeError="removeError(['imageId'])"
				/>
			</span>
		</span>
		<span class="mt-9 flex w-full space-x-2">
			<span class="flex-1">
				<TextInput
					v-model="slide.title"
					label="Title"
					:error="errors?.title"
					@removeError="removeError(['title'])"
				/>
			</span>
			<span class="flex-1">
				<TextInput
					v-if="slide"
					v-model="slide.buttonTitle"
					label="Button Text"
					:error="errors?.buttonTitle"
					@removeError="removeError(['buttonTitle'])"
				/>
			</span>
			<span class="flex-1">
				<TextInput
					v-model="slide.linkUrl"
					label="Button URL"
					:error="errors?.linkUrl"
					@removeError="removeError(['linkUrl'])"
				/>
			</span>
		</span>
		<span class="flex w-full space-x-2">
			<span class="flex-1 text-sm">
				<DateSelector
					v-model="slide.published"
					label="Start Date"
					includeTime
					:error="errors?.published"
					@removeError="removeError(['published'])"
				/>
			</span>
			<span class="flex-1 text-sm">
				<DateSelector
					v-model="slide.expires"
					label="End Date"
					includeTime
					:error="errors?.expires"
					@removeError="removeError(['expires'])"
				/>
			</span>
			<span class="flex-1"></span>
		</span>
		<Textarea
			v-model="slide.content"
			label="Content"
			height="5"
			errorKey="temp"
			:error="errors?.content"
			@removeError="removeError(['content'])"
		/>
		<h2 class="mb-3 font-bold">Mobile Settings</h2>
		<div class="flex space-x-10">
			<span>
				<FormRadio
					label="Image Overlay"
					v-model="slide.imageOverlay"
					:options="imageOverlays"
					:error="errors?.imageOverlay"
					@removeError="removeError(['imageOverlay'])"
				/>
			</span>
			<span>
				<FormRadio
					label="Text Color"
					v-model="slide.textColor"
					:options="textColors"
					:error="errors?.textColor"
					@removeError="removeError(['textColor'])"
				/>
			</span>
		</div>
		<ManageControls @cancel="cancel" @remove="remove" @submit="submit" />
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useSlidesStore } from '@/stores/slides'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import { FormRadio } from '@/components/utilities/form'
import { toRaw } from 'vue'
import DateSelector from '@/components/utilities/form/DateSelector'
import ManageControls from '@/components/utilities/ManageControls'
import SelectImage from '@/components/utilities/form/SelectImage'
import { useMainStore } from '../../stores/main'
export default {
	name: 'CreateEditSlides',
	components: {
		DateSelector,
		FormRadio,
		ManageControls,
		SelectImage
	},
	async created() {
		if (this.$route.params.id) {
			await this.loadData()
			this.images = this.slidesStore.active.images
		} else {
			this.slidesStore.initSlide()
		}
	},
	beforeUnmount() {
		this.slidesStore.setActive({})
	},
	data: () => ({
		textColors: [
			{ optionName: 'White', optionValue: 'white' },
			{ optionName: 'Blue', optionValue: 'blue' }
		],
		imageOverlays: [
			{ optionName: 'None', optionValue: null },
			{ optionName: 'Light', optionValue: 'light' },
			{ optionName: 'Dark', optionValue: 'dark' }
		],
		images: []
	}),
	methods: {
		addSlide() {
			this.slidesStore.addSlide()
			this.cancel()
		},
		cancel() {
			this.$router.push({ name: 'SlidesIndex' })
		},
		findImage(id) {
			return this.images.find((image) => image.id === id)
		},
		updateImage(image) {
			this.images.push(image)
		},
		updateImageId(id, size) {
			if (!id) {
				delete this.imageIds[size]
				return
			}
			this.imageIds[size] = id
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async remove() {
			await this.slidesStore.remove(this.slide.id)
			this.cancel()
		},
		async submit() {
			if (this.editing) {
				const changes = differenceWith(
					toPairs(toRaw(this.slide)),
					toPairs(this.original),
					isEqual
				)
				await this.slidesStore.update(this.slide.id, fromPairs(changes))
			} else {
				await this.slidesStore.create()
			}

			if (isEmpty(this.errors)) this.cancel()
		},
		async loadData() {
			if (this.editing) {
				await this.slidesStore.fetchOne(this.$route.params.id)
				this.original = structuredClone(this.slide)
			}
		}
	},
	computed: {
		...mapStores(useSlidesStore, useMainStore),
		editing() {
			return !!this.$route.params.id
		},
		slide() {
			return this.slidesStore.activeSlide
		},
		imageIds() {
			return this.slide?.responsiveImageIds
		},
		errors() {
			return this.mainStore.errors
		}
	}
}
</script>
