<template>
	<div class="pt-2 pb-6 md:py-6">
		<div class="mx-auto mb-4 max-w-7xl px-4 sm:px-6 md:px-8">
			<h1 class="text-2xl font-semibold text-gray-900">Site Configurations</h1>
		</div>
		<div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
			<div class="mx-auto w-full rounded-2xl bg-gray-100">
				<form @submit.prevent="update">
					<!-- alert settings -->
					<Disclosure v-slot="{ open }">
						<DisclosureButton
							class="flex w-full items-center rounded-lg bg-slate-100 px-4 py-2 text-left font-semibold text-gray-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75"
						>
							<ChevronRightIcon
								:class="open ? 'rotate-90 transform' : ''"
								class="mr-2 h-5 w-5 text-slate-900"
							/>
							<span>Alert Settings</span>
						</DisclosureButton>
						<DisclosurePanel class="ml-7 px-4 pt-4 pb-2 text-sm text-gray-700">
							<div class="grid grid-cols-2 gap-3 text-sm">
								<TextInput
									v-model="alert.title"
									label="Title"
									:error="errors?.alert?.title"
									@removeError="removeError(['alert', 'title'])"
								/>
								<FormSelect
									v-model="alert.position"
									label="Position"
									:options="selectOptions.alert"
									:error="errors?.alert?.position"
									@removeError="removeError(['alert', 'position'])"
								/>
								<span class="col-span-2">
									<label class="mb-1 block text-sm font-medium leading-5">
										Body
									</label>
									<WysiwygEditor
										v-model="alert.body"
										:height="3"
										:error="errors?.alert?.body"
										@removeError="removeError(['alert', 'body'])"
									/>
								</span>
								<DateSelector
									v-model="alert.start"
									label="Start Date"
									includeTime
									:error="errors?.alert?.start"
									@removeError="removeError(['alert', 'start'])"
								/>
								<DateSelector
									v-model="alert.end"
									label="End Date"
									includeTime
									:error="errors?.alert?.end"
									@removeError="removeError(['alert', 'end'])"
								/>
							</div>
						</DisclosurePanel>
					</Disclosure>

					<!-- map settings -->
					<Disclosure v-slot="{ open }">
						<DisclosureButton
							class="flex w-full items-center rounded-lg bg-slate-100 px-4 py-2 text-left font-semibold text-gray-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75"
						>
							<ChevronRightIcon
								:class="open ? 'rotate-90 transform' : ''"
								class="mr-2 h-5 w-5 text-slate-900"
							/>
							<span>Map Settings</span>
						</DisclosureButton>
						<DisclosurePanel class="ml-7 px-4 pt-4 pb-2 text-sm text-gray-700">
							<TextInput
								v-model="map.headerTitle"
								label="Header Title"
								:error="errors?.map?.headerTitle"
								@removeError="removeError(['map', 'headerTitle'])"
							/>
							<SelectImage
								sizeDisclaimer="1600px x 400px"
								imageSize="large"
								label="Header Image"
								:image="map.headerImage"
								:imageId="map.headerImageId"
								:error="errors?.map?.headerImage?.path"
								@removeError="removeError(['map', 'headerImage', 'path'])"
								@updateImage="updateImage($event, 'map')"
								@updateImageId="updateImageId($event, 'map')"
							/>
							<div class="mt-5 space-y-2">
								<h2 class="text-lg text-gray-900">Plan Trip</h2>
								<TextInput v-model="map.planTrip.title" label="Content Title" />
								<label class="mb-1 block text-sm font-medium leading-5">
									Content Body
								</label>
								<WysiwygEditor v-model="map.planTrip.body" />
							</div>
							<div class="mt-5 space-y-2">
								<h2 class="text-lg text-gray-900">Service Areas</h2>
								<TextInput
									v-model="map.serviceAreas.title"
									label="Content Title"
								/>
								<label class="mb-1 block text-sm font-medium leading-5">
									Content Body
								</label>
								<WysiwygEditor v-model="map.serviceAreas.body" />
							</div>
							<div class="mt-5 space-y-2">
								<h2 class="text-lg text-gray-900">Cameras</h2>
								<TextInput v-model="map.cameras.title" label="Content Title" />
								<label class="mb-1 block text-sm font-medium leading-5">
									Content Body
								</label>
								<WysiwygEditor v-model="map.cameras.body" />
							</div>
							<div class="mt-5 space-y-2">
								<h2 class="text-lg text-gray-900">Parking</h2>
								<TextInput v-model="map.parking.title" label="Content Title" />
								<label class="mb-1 block text-sm font-medium leading-5">
									Content Body
								</label>
								<WysiwygEditor v-model="map.parking.body" />
							</div>
							<div class="mt-5 space-y-2">
								<h2 class="text-lg text-gray-900">Message Signs</h2>
								<TextInput
									v-model="map.messageSigns.title"
									label="Content Title"
								/>
								<label class="mb-1 block text-sm font-medium leading-5">
									Content Body
								</label>
								<WysiwygEditor v-model="map.messageSigns.body" />
							</div>
							<div class="mt-5 space-y-2">
								<h2 class="text-lg text-gray-900">Weather</h2>
								<TextInput v-model="map.weather.title" label="Content Title" />
								<label class="mb-1 block text-sm font-medium leading-5">
									Content Body
								</label>
								<WysiwygEditor v-model="map.weather.body" />
							</div>
						</DisclosurePanel>
					</Disclosure>

					<!-- contact settings -->
					<Disclosure v-slot="{ open }">
						<DisclosureButton
							class="flex w-full items-center rounded-lg bg-slate-100 px-4 py-2 text-left font-semibold text-gray-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75"
						>
							<ChevronRightIcon
								:class="open ? 'rotate-90 transform' : ''"
								class="mr-2 h-5 w-5 text-slate-900"
							/>
							<span>Contact Form Settings</span>
						</DisclosureButton>
						<DisclosurePanel class="ml-7 px-4 pt-4 pb-2 text-sm text-gray-700">
							<div
								class="flex items-center space-x-4"
								v-for="(item, index) in contact.contactItems"
								:key="index"
							>
								<TextInput
									label="Subject"
									v-model="item.subject"
									:error="errors?.contact?.contactItems?.[index]?.subject"
									@removeError="
										removeError(['contact', 'contactItems', index, 'subject'])
									"
								/>
								<TextInput
									label="Contact Email"
									v-model="item.contact"
									:error="errors?.contact?.contactItems?.[index]?.contact"
									@removeError="
										removeError(['contact', 'contactItems', index, 'contact'])
									"
								/>
								<button type="button" @click="removeContact(index)">
									<XCircleIcon
										class="mt-1 h-6 w-6 text-red-600 hover:text-red-700"
									/>
								</button>
							</div>
							<button
								type="button"
								class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
								@click="addContact"
							>
								Add
							</button>
						</DisclosurePanel>
					</Disclosure>

					<!-- navigation settings -->
					<Disclosure v-slot="{ open }">
						<DisclosureButton
							class="flex w-full items-center rounded-lg bg-slate-100 px-4 py-2 text-left font-semibold text-gray-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75"
						>
							<ChevronRightIcon
								:class="open ? 'rotate-90 transform' : ''"
								class="mr-2 h-5 w-5 text-slate-900"
							/>
							<span>Navigation Settings</span>
						</DisclosureButton>
						<DisclosurePanel class="ml-7 px-4 pt-4 pb-2 text-sm text-gray-500">
							<FormSelect
								v-model="navigation.primary"
								:options="menus"
								optionsDisplay="name"
								label="Primary Menu"
							/>
							<FormSelect
								v-model="navigation.secondary"
								:options="menus"
								optionsDisplay="name"
								label="Secondary Menu"
							/>
							<FormSelect
								v-model="navigation.auxiliary"
								:options="menus"
								optionsDisplay="name"
								label="Auxiliary Menu"
							/>
						</DisclosurePanel>
					</Disclosure>

					<!-- slider settings -->
					<Disclosure v-slot="{ open }">
						<DisclosureButton
							class="flex w-full items-center rounded-lg bg-slate-100 px-4 py-2 text-left font-semibold text-gray-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75"
						>
							<ChevronRightIcon
								:class="open ? 'rotate-90 transform' : ''"
								class="mr-2 h-5 w-5 text-slate-900"
							/>
							<span>Slider Settings</span>
						</DisclosureButton>
						<DisclosurePanel class="ml-7 px-4 pt-4 pb-2 text-sm text-gray-500">
							<TextInput
								v-model="config.sliderDelay"
								label="Slide Delay"
								:error="errors?.config?.sliderDelay"
								@removeError="removeError(['config', 'sliderDelay'])"
							/>
						</DisclosurePanel>
					</Disclosure>

					<div class="mt-8 flex justify-end">
						<button
							type="submit"
							class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
							@click="submit"
						>
							Save
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useSiteConfigStore } from '@/stores/siteConfig'
import { useMenusStore } from '@/stores/menus'
import { useMainStore } from '@/stores/main'
import { defineComponent } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { XCircleIcon } from '@heroicons/vue/24/outline'
import DateSelector from '@/components/utilities/form/DateSelector'
import SelectImage from '@/components/utilities/form/SelectImage'
import WysiwygEditor from '@/components/utilities/form/WysiwygEditor'
export default defineComponent({
	title: 'Config - iKTA',
	name: 'SiteConfig',
	async created() {
		await this.siteConfigStore.fetchConfiguration()
		await this.menusStore.fetchAll()
	},
	components: {
		DateSelector,
		Disclosure,
		DisclosureButton,
		DisclosurePanel,
		ChevronRightIcon,
		SelectImage,
		WysiwygEditor,
		XCircleIcon
	},
	methods: {
		addContact() {
			this.contact.contactItems.push({ subject: '', contact: '' })
		},
		removeContact(index) {
			this.contact.contactItems.splice(index, 1)
		},
		updateImage(image, setting) {
			this[setting].headerImage = image
		},
		updateImageId(id, setting) {
			this[setting].headerImageId = id
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		submit() {
			this.siteConfigStore.submit()
		}
	},
	computed: {
		...mapStores(useSiteConfigStore, useMenusStore, useMainStore),
		config() {
			return this.siteConfigStore.getConfiguration
		},
		selectOptions() {
			return this.siteConfigStore.getSelectOptions
		},
		alert() {
			return this.config.alert
		},
		map() {
			return this.config.map
		},
		careers() {
			return this.config.careers
		},
		communication() {
			return this.config.communication
		},
		contact() {
			return this.config.contact
		},
		menus() {
			return this.menusStore.getMenus.map(({ id, name }) => ({ id, name }))
		},
		navigation() {
			return this.config.navigation
		},
		errors() {
			return this.mainStore.errors
		}
	}
})
</script>
