import { Tag } from './tags'

export type State = {
	posts: Post[]
	page: number
	count: number
	limit: number
	offset: number
	filter: string
	filterOptions: FilterOptions[]
	order: string
	orderBy: OrderBy
	orderOptions: OrderOptions[]
	active: Post | Record<string, any>
	dynamicSlug: boolean
}

export class Post {
	id?: null | number = null
	slug?: string = ''
	title?: string = ''
	contentUrl?: string | null = null
	summary?: string | null = null
	body?: string = ''
	published?: string | null = null
	expires?: string | null = null
	archived?: string | null = null
	created?: string | null = null
	updated?: string | null = null
	tags?: Tag[] | string[] = []
}

type FilterOptions = {
	key: 'published' | 'unpublished'
	value: 'Published' | 'Unpublished'
}

type OrderOptions = {
	key: string
	value: string
	type: string
}

export type OrderBy = {
	key: string
	value: string
	type: string
}
