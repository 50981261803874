<template>
	<div class="mx-auto max-w-7xl px-4 pt-2 pb-6 sm:px-6 md:px-8 md:py-6">
		<h1 class="mb-6 text-2xl font-semibold text-gray-900">Menus</h1>
		<MenuSelector class="mb-4" />
		<TextInput v-model="active.name" label="Menu Name" v-if="active" />
		<DateSelector
			v-if="active"
			class="mt-6"
			v-model="active.published"
			label="Publish"
			includeSwitch
			excludeDate
		/>
		<div class="flex items-start">
			<MenuItemEditor
				class="w-1/3 rounded-md border bg-gray-600 text-white"
				v-if="showItemEditor"
				:mode="editorMode"
				@close="closeEditor"
			/>
			<MenuEditor class="flex-1" @openEditor="openEditor" v-if="active" />
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMenusStore } from '@/stores/menus'
import { usePagesStore } from '@/stores/pages'
import DateSelector from '@/components/utilities/form/DateSelector.vue'
import MenuSelector from '@/components/menu/MenuSelector'
import MenuEditor from '@/components/menu/MenuEditor'
import MenuItemEditor from '@/components/menu/MenuItemEditor'
export default {
	created() {
		this.pagesStore.fetchAll()
		this.pagesStore.fetchPublished()
		this.menusStore.fetchAll()
	},
	title: 'Menus - iKTA',
	name: 'MenuManager',
	data: () => ({
		showItemEditor: false,
		editorMode: 'create'
	}),
	components: {
		DateSelector,
		MenuSelector,
		MenuItemEditor,
		MenuEditor
	},
	methods: {
		openEditor(value) {
			this.showItemEditor = true
			if (value) this.editorMode = value
		},
		closeEditor() {
			this.showItemEditor = false
		}
	},
	computed: {
		...mapStores(useMenusStore, usePagesStore),
		active() {
			return this.menusStore.getActive
		},
		activeItem() {
			return this.menusStore.getActiveItem
		}
	},
	watch: {
		activeItem(value) {
			if (value.menuType) {
				this.openEditor('edit')
			} else {
				this.editorMode = 'create'
			}
		}
	}
}
</script>
