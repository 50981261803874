
import { mapStores } from 'pinia'
import { useFormsStore } from '@/stores/forms'
import { useMainStore } from '@/stores/main'
import { ChevronUpIcon, Bars3Icon, TrashIcon } from '@heroicons/vue/24/solid'
import { Cog6ToothIcon } from '@heroicons/vue/24/outline'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import { FormSelect, FormToggle } from '@/components/utilities/form'
import { toRaw, defineComponent } from 'vue'
import draggable from 'vuedraggable'
import ComponentToolbox from '@/components/formBuilder/ComponentToolbox.vue'
import ComponentSettings from '@/components/formBuilder/ComponentSettings.vue'
import DateSelector from '@/components/utilities/form/DateSelector.vue'
import FormPreview from '@/components/formBuilder/FormPreview.vue'
import ManageControls from '@/components/utilities/ManageControls.vue'
import { Form } from '@/types/forms'
export default defineComponent({
	name: 'FormsCreate',
	components: {
		draggable,
		ChevronUpIcon,
		Cog6ToothIcon,
		ComponentToolbox,
		ComponentSettings,
		DateSelector,
		FormPreview,
		FormSelect,
		FormToggle,
		ManageControls,
		Bars3Icon,
		TrashIcon
	},
	created() {
		if (this.$route.params.id) {
			this.loadData()
		} else {
			this.formsStore.initForm()
		}
	},
	data: (): {
		activeRow: number | null
		activeComponent: any | null
		original: Form | { [key: string]: any }
	} => ({
		activeRow: null,
		activeComponent: null,
		original: {}
	}),
	methods: {
		addRow() {
			this.content.push({ type: 'FormRow', props: {}, components: [] })
			this.setActiveRow(this.content.length - 1)
		},
		addComponent(item: any, rowIndex: number) {
			this.content[rowIndex].components.push(item)
		},
		removeRow(index: number) {
			this.content.splice(index, 1)
		},
		removeComponent(index: number) {
			if (this.activeRow !== null)
				this.content[this.activeRow].components.splice(index, 1)
		},
		setActiveRow(index: number, oldIndex?: number) {
			if (Number.isInteger(oldIndex) && this.activeRow !== oldIndex) return
			this.activeRow = this.activeRow !== index ? index : null
			this.activeComponent = null
		},
		setActiveComponent(index: number, oldIndex?: number) {
			if (Number.isInteger(oldIndex) && this.activeComponent !== oldIndex)
				return
			this.activeComponent = this.activeComponent !== index ? index : null
		},
		rowItems(index: number) {
			return this.content[index].components
				.reduce((string: string, component: any) => {
					return `${string} ${
						component.props.label ? `${component.props.label}:` : ''
					} ${component.name} |`
				}, '')
				.slice(0, -1)
		},
		updateProps(props: any, index: number) {
			if (this.activeRow)
				this.content[this.activeRow].components[index].props = props
		},
		addForm() {
			this.formsStore.addForm()
			if (isEmpty(this.errors)) this.cancel()
		},
		cancel() {
			this.$router.push({ name: 'FormsIndex' })
		},
		removeError(props: string[]) {
			this.mainStore.removeError(props)
		},
		async remove() {
			if (this.formMeta.id) {
				await this.formsStore.remove(this.formMeta.id)
				this.cancel()
			}
		},
		async submit() {
			if (this.editing) {
				const changes = differenceWith(
					toPairs(toRaw(this.formMeta)),
					toPairs(this.original),
					isEqual
				)
				if (this.formMeta.id)
					await this.formsStore.update(this.formMeta.id, fromPairs(changes))
			} else {
				await this.formsStore.create()
			}
		},
		async loadData() {
			if (this.editing) {
				await this.formsStore.fetchOne(+this.$route.params.id)
				this.original = structuredClone(this.formMeta)
			}
		}
	},
	computed: {
		...mapStores(useFormsStore, useMainStore),
		editing() {
			return !!this.$route.params.id
		},
		formMeta(): Form {
			return this.formsStore.active
		},
		content() {
			return this.formMeta.content
		},
		email: {
			get() {
				return this.formMeta.email.join(',')
			},
			set(value: string) {
				this.formMeta.email = value.replace(/\s+/g, '').split(',')
			}
		},
		gridCols() {
			return Object.keys(this.formsStore.cols)
		},
		errors() {
			return this.mainStore.errors
		}
	}
})
