<template>
	<div
		class="flex min-h-screen flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
	>
		<div class="w-full max-w-md">
			<div>
				<img
					class="mx-auto h-12 w-auto"
					src="../assets/kta-logo.svg"
					alt="KTA Logo"
				/>
				<h2
					class="text-blue mt-6 text-center text-3xl font-extrabold leading-9"
				>
					Kansas Turnpike Authority
				</h2>
			</div>
			<form class="mt-8 w-full" @submit.prevent="login">
				<input type="hidden" name="remember" value="true" />
				<div
					class="rounded-md shadow-sm"
					:class="{ 'shadow-outline-red ring-1 ring-red-500': errors }"
				>
					<div class="">
						<input
							v-model="user.email"
							aria-label="email"
							autocomplete="email"
							name="email"
							type="text"
							class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 focus:z-10 sm:text-sm sm:leading-5"
							:class="[
								errors.email
									? 'focus:shadow-outline-red text-red-900 placeholder-red-300'
									: 'focus:shadow-outline-kta-blue text-gray-900 placeholder-gray-500 focus:border-blue-300 focus:outline-none'
							]"
							placeholder="email"
						/>
					</div>
					<div class="relative -mt-px">
						<div
							title="toggle view password"
							class="absolute inset-y-0 right-0 z-30 flex cursor-pointer items-center pr-3"
							@click="showPassword = !showPassword"
						>
							<EyeSlashIcon
								class="h-5 w-5 text-gray-600"
								aria-hidden="true"
								v-if="!showPassword"
							/>
							<EyeIcon
								class="h-5 w-5 text-gray-600"
								aria-hidden="true"
								v-else
							/>
						</div>
						<input
							v-model="user.password"
							aria-label="Password"
							autocomplete="current-password"
							name="password"
							:type="[showPassword ? 'text' : 'password']"
							class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 focus:z-10 sm:text-sm sm:leading-5"
							:class="[
								errors.password
									? 'focus:shadow-outline-red text-red-900 placeholder-red-300'
									: 'focus:shadow-outline-kta-blue text-gray-900 placeholder-gray-500 focus:border-cobalt focus:outline-none'
							]"
							placeholder="Password"
						/>
					</div>
				</div>
				<p class="mt-1 text-sm text-red-600" v-if="errors">
					<span v-if="errors.message">{{ errors.message }}</span>
					<span v-if="errors.email || errors.password"
						>email/password required</span
					>
				</p>

				<div class="mt-6 flex items-center justify-between">
					<div class="flex items-center">
						<input
							id="remember_me"
							type="checkbox"
							class="form-checkbox h-4 w-4 border-gray-300 text-cobalt shadow-sm transition duration-150 ease-in-out"
						/>
						<label
							for="remember_me"
							class="ml-2 block text-sm leading-5 text-gray-900"
						>
							Remember me
						</label>
					</div>
					<div class="text-center text-sm">
						<router-link
							:to="{ name: 'PasswordReset' }"
							class="font-bold text-kta-blue transition duration-150 ease-in-out hover:text-cobalt focus:underline focus:outline-none"
						>
							Forgot your password?
						</router-link>
					</div>
				</div>

				<div class="mt-6">
					<button
						type="submit"
						@submit.prevent="login"
						class="focus:shadow-outline-kta-blue active:bg-blue group relative flex w-full justify-center rounded-md border border-transparent bg-kta-blue py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-cobalt focus:border-cobalt focus:outline-none"
					>
						<span class="absolute inset-y-0 left-0 flex items-center pl-3">
							<svg
								class="h-5 w-5 text-cobalt transition duration-150 ease-in-out group-hover:text-sky"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fill-rule="evenodd"
									d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</span>
						Sign in
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useUsersStore } from '@/stores/users'
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
export default {
	name: 'AdminLogin',
	title: 'Login',
	components: { EyeIcon, EyeSlashIcon },
	data: () => ({
		user: {
			email: '',
			password: ''
		},
		showPassword: false
	}),
	methods: {
		async login() {
			const redirect = await this.usersStore.login(this.user)
			redirect
				? this.$router.push(redirect)
				: this.$router.push({ name: 'AdminDashboard' })
		}
	},
	computed: {
		...mapStores(useUsersStore),
		errors() {
			return false
			// return this.$store.getters.getErrors
		}
	},
	watch: {
		user: {
			deep: true,
			handler(value) {
				if (this.errors) {
					Object.keys(value).forEach((prop) =>
						this.$store.commit('removeError', prop)
					)
				}
			}
		}
	}
}
</script>
