import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { State, Accordion } from '@/types/accordions'
import { useUsersStore } from './users'
import axios from 'axios'

export const useAccordionsStore = defineStore('accordions', {
	state: (): State => ({
		accordions: [],
		active: {
			name: '',
			multiExpand: false,
			content: [],
			published: ''
		}
	}),
	getters: {
		activeAccordion(state) {
			return state.active
		},
		getAccordions(state) {
			return state.accordions
		},
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		initAccordion() {
			const newAccordion: Accordion = {
				name: '',
				multiExpand: false,
				content: [],
				published: null
			}

			this.setActive(newAccordion)
		},
		setActive(accordion: Accordion) {
			this.active = accordion
		},
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/accordions`,
					this.userConfig
				)
				this.accordions = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: number) {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/accordions/${id}`,
					this.userConfig
				)
				this.active = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create() {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/accordions`,
					this.active,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Accordion created.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update(id: number, changes: Partial<Accordion>) {
			await this.usersStore.checkLogin()
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/accordions/${id}`,
					changes,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Accordion updated.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/accordions/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Accordion deleted.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
