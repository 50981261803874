export type Position =
	| 'top'
	| 'right'
	| 'bottom'
	| 'left'
	| 'top-right'
	| 'bottom-right'
	| 'top-left'
	| 'bottom-left'

export class Alert {
	position?: string | Position = 'top'
	level?: string | 'success' | 'error' | 'warning' = ''
	title?: string = ''
	text?: string | unknown = ''
	timer?: number | null = null
}

export type State = {
	alert: Alert
	errors: { [key: string]: any }
}
