<template>
	<div class="pt-2 pb-6 md:py-6">
		<div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
			<h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
		</div>
		<div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">Content</div>
	</div>
</template>

<script>
export default {
	title: 'Dashboard - iKTA',
	name: 'AdminDashboard',
	components: {}
}
</script>
