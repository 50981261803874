<template>
	<h2 class="mt-8 mb-4 font-bold">Preview</h2>
	<div
		class="mt-1 space-y-5 rounded border border-zinc-300 bg-white p-6 shadow"
	>
		<div v-if="leadership" class="mx-auto mt-10 w-[260px]">
			<FlipCard>
				<template #front>
					<LeadershipCard
						:path="leadership.image?.path"
						:name="leadership.name"
						:title="leadership.title"
					/>
				</template>
				<template #back>
					<LeadershipCard
						:description="leadership.description"
						:buttonText="leadership.buttonText"
						:link="leadership.link"
					/>
				</template>
			</FlipCard>
		</div>
		<span v-else class="text-gray-400"><em>No Card Preview</em></span>
	</div>
</template>

<script>
import FlipCard from '@/components/leadership/FlipCard'
import LeadershipCard from '@/components/leadership/LeadershipCard'
export default {
	name: 'LeadershipPreview',
	props: {
		leadership: {
			type: Object,
			required: true
		}
	},
	components: { FlipCard, LeadershipCard }
}
</script>
