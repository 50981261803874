<template>
	<h2 class="mt-8 mb-4 font-bold">Preview</h2>
	<div
		class="mt-1 space-y-5 rounded border border-zinc-300 bg-white p-6 shadow"
	>
		<AccordionComponent
			v-if="accordionMeta.content.length"
			:accordionMeta="accordionMeta"
		/>
		<span v-else class="text-gray-400"><em>No Accordion Preview</em></span>
	</div>
</template>

<script>
import AccordionComponent from './AccordionComponent'
export default {
	name: 'AccordionPreview',
	props: {
		accordionMeta: {
			type: Object,
			required: true
		}
	},
	components: { AccordionComponent }
}
</script>
