<template>
	<div class="mt-6 mb-4 block items-end justify-between sm:flex">
		<div class="flex items-end md:items-center">
			<div class="mr-2 mb-2 block flex-1 items-center md:mb-0 lg:flex">
				<label
					for="orderBy"
					class="mr-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
				>
					Order by
				</label>
				<div class="mt-1 sm:col-span-2 sm:mt-0">
					<div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
						<select
							id="orderBy"
							v-model="orderBy"
							class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
						>
							<option
								:value="option"
								v-for="option in orderOptions"
								:key="option.key"
							>
								{{ option.value }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="mb-2 block items-center md:mb-0 lg:flex">
				<span class="inline-flex rounded-md shadow-sm">
					<button
						type="button"
						@click="orderItems"
						class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700 md:leading-5"
					>
						<span class="mr-1">
							<svg
								class="h-5 w-5"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								v-if="order === 'ASC'"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
								/>
							</svg>
							<svg
								class="h-5 w-5"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								v-else
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
								/>
							</svg>
						</span>
						Sort
					</button>
				</span>
			</div>
		</div>
		<div class="block items-center lg:flex">
			<label
				for="filter"
				class="mr-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
			>
				Filter
			</label>
			<div class="mt-1 sm:col-span-2 sm:mt-0">
				<div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
					<select
						id="filter"
						v-model="filter"
						class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
					>
						<option value="all">All</option>
						<option
							:value="option.key"
							v-for="option in filterOptions"
							:key="option.key"
						>
							{{ option.value }}
						</option>
					</select>
				</div>
			</div>
			<div
				class="ml-2 mt-1 sm:col-span-2 sm:mt-0"
				v-if="filterByOptions && filter !== 'all'"
			>
				<div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
					<select
						id="filter"
						v-model="filterBy"
						class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
					>
						<option
							:value="option"
							v-for="(option, index) in filterByOptions"
							:key="index"
						>
							{{ option }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DataControls',
	props: {
		store: {
			required: true
		}
	},
	computed: {
		filterOptions() {
			return this.store.filterOptions
		},
		filterByOptions() {
			return this.store.filterByOptions
				? ['All', ...this.store.filterByOptions]
				: null
		},
		orderOptions() {
			return this.store.orderOptions
		},
		order() {
			return this.store.order
		},
		filter: {
			get() {
				return this.store.filter ? this.store.filter : 'all'
			},
			set(value) {
				this.store.setFilter(value)
				this.handle()
			}
		},
		filterBy: {
			get() {
				return this.store.filterBy ? this.store.filterBy : 'All'
			},
			set(value) {
				this.store.setFilterBy(value)
				this.handle()
			}
		},
		orderBy: {
			get() {
				return this.store.orderBy
			},
			set(value) {
				this.store.setOrderBy(value)
				this.handle()
			}
		}
	},
	methods: {
		handle() {
			if (this.filter && this.filterBy) {
				this.store.fetchAll()
			}
			if (this.filterBy !== 'All') {
				this.store.fetchAll()
			}
		},
		orderItems() {
			const order = this.order === 'ASC' ? 'DESC' : 'ASC'
			this.store.setOrder(order)
			this.handle()
		}
	}
}
</script>
