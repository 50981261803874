<template>
	<div class="mx-auto w-full max-w-screen-md sm:px-4">
		<div
			class="mx-auto w-full overflow-hidden rounded-lg border border-light bg-white"
		>
			<Disclosure v-for="(item, index) in accordion" :key="index">
				<DisclosureButton
					:class="[
						{ 'border-b': panels[index]?.open },
						'focus-visible:kta-ring-inset -mt-px flex w-full items-center justify-between border-t border-light bg-extra-light px-4 py-2 text-left text-cobalt first:rounded-t'
					]"
					@click="displayPanel(index)"
					@keyup.enter="displayPanel(index)"
				>
					<span>{{ item.title }}</span>
					<ChevronDownIcon
						:class="[
							{ 'rotate-180 transform': panels[index]?.open },
							'text-blue h-5 w-5 flex-none'
						]"
					/>
				</DisclosureButton>
				<div v-show="panels[index]?.open">
					<DisclosurePanel
						class="space-y-3 p-4 text-extra-dark"
						v-html="item.body"
						static
					>
					</DisclosurePanel>
				</div>
			</Disclosure>
		</div>
	</div>
</template>

<script>
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
export default {
	name: 'AccordionComponent',
	props: {
		accordionMeta: {
			type: Object,
			required: true
		}
	},
	components: {
		ChevronDownIcon,
		Disclosure,
		DisclosureButton,
		DisclosurePanel
	},
	data: () => ({
		panels: []
	}),
	mounted() {
		for (let i = 0; i < this.accordion.length; i++) {
			this.panels.push({ open: false })
		}
	},
	methods: {
		closeAll() {
			this.panels.forEach((panel) => (panel.open = false))
		},
		displayPanel(index) {
			if (!this.accordionMeta.multiExpand) {
				this.panels.forEach((panel, i) => {
					if (index !== i) panel.open = false
				})
			}
			this.panels[index].open = !this.panels[index].open
		}
	},
	computed: {
		accordion() {
			return this.accordionMeta.content
		},
		accordionLength() {
			return this.accordion.length
		}
	},
	watch: {
		accordionLength() {
			this.closeAll()
			this.panels = []
			this.accordion.forEach(() => this.panels.push({ open: false }))
		}
	}
}
</script>
