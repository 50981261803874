<template>
	<TextInput
		v-model="accordionMeta.name"
		label="Name"
		:error="errors?.name"
		@removeError="removeError(['name'])"
	/>
	<div class="flex justify-end">
		<button
			@click="addSection"
			type="button"
			class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
		>
			Add Section
		</button>
	</div>
	<div v-if="accordion?.length" class="mt-6">
		<draggable
			handle=".handle"
			tag="div"
			:list="accordion"
			item-key="index"
			@sort="setActiveIndex($event.newIndex, $event.oldIndex)"
		>
			<template #item="{ element, index }">
				<div
					class="mb-2 rounded-xl border border-gray-300 bg-gray-200 px-4 pt-5"
				>
					<div class="flex items-start">
						<span class="handle mt-1 p-2 pl-0">
							<Bars3Icon class="h-5 w-5 text-gray-500" />
						</span>
						<TextInput
							class="flex-1"
							v-model="element.title"
							:error="errors.content?.[index]?.title"
							@removeError="removeError(['content', index, 'title'])"
						/>
						<button
							@click="setActiveIndex(index)"
							type="button"
							class="mt-1 ml-1 inline-flex items-center rounded-md bg-gray-400 p-2 text-xs font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none"
						>
							<ChevronUpIcon
								:class="[
									activeIndex === index ? 'rotate-180 transform' : '',
									'h-5 w-5'
								]"
							/>
						</button>
						<button
							@click="removeSection(index)"
							type="button"
							class="mt-1 ml-1 inline-flex items-center rounded-md bg-red-200 px-2 py-2 text-xs font-medium text-red-800 shadow-sm hover:bg-red-300 focus:outline-none"
						>
							<TrashIcon class="h-5 w-5" />
						</button>
					</div>
					<div class="pb-5" v-show="activeIndex === index">
						<WysiwygEditor
							v-model="element.body"
							:error="errors.content?.[index]?.body"
							@removeError="removeError(['content', index, 'body'])"
						/>
					</div>
				</div>
			</template>
		</draggable>
	</div>
	<div class="mt-5">
		<label class="flex w-fit items-center">
			<input
				class="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-gray-500"
				type="checkbox"
				v-model="accordionMeta.multiExpand"
			/>
			Multiple Expand
		</label>
	</div>
	<DateSelector
		class="mt-6"
		v-model="accordionMeta.published"
		label="Publish"
		includeSwitch
		excludeDate
		:error="errors?.published"
		@removeError="removeError(['published'])"
	/>
	<div class="mt-6" v-if="accordion">
		<ManageControls @cancel="cancel" @remove="remove" @submit="submit" />
		<AccordianPreview :accordionMeta="accordionMeta" />
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAccordionsStore } from '@/stores/accordions'
import { useMainStore } from '@/stores/main'
import { ChevronUpIcon, TrashIcon, Bars3Icon } from '@heroicons/vue/24/solid'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import { toRaw } from 'vue'
import AccordianPreview from '@/components/accordions/AccordionPreview'
import DateSelector from '@/components/utilities/form/DateSelector'
import draggable from 'vuedraggable'
import ManageControls from '@/components/utilities/ManageControls'
import WysiwygEditor from '@/components/utilities/form/WysiwygEditor'

export default {
	name: 'CreateEditAccordion',
	components: {
		ChevronUpIcon,
		AccordianPreview,
		DateSelector,
		draggable,
		ManageControls,
		Bars3Icon,
		TrashIcon,
		WysiwygEditor
	},
	created() {
		if (this.$route.params.id) {
			this.loadData()
		} else {
			this.accordionsStore.initAccordion()
		}
	},
	beforeUnmount() {
		this.accordionsStore.setActive({})
	},
	data: () => ({
		activeIndex: null,
		confirm: false
	}),
	methods: {
		addSection() {
			this.accordion.push({
				index: this.accordion.length,
				title: '',
				body: ''
			})
			this.setActiveIndex(this.accordion.length - 1)
		},
		removeSection(index) {
			this.accordion.splice(index, 1)
		},
		setActiveIndex(index, oldIndex) {
			if (Number.isInteger(oldIndex) && this.activeIndex !== oldIndex) return
			this.activeIndex = this.activeIndex !== index ? index : null
		},
		cancel() {
			this.$router.push({ name: 'AccordionsIndex' })
		},
		indexPanels() {
			this.accordion.forEach((panel, index) => {
				panel.index = index
			})
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async remove() {
			await this.accordionsStore.remove(this.accordionMeta.id)
			this.cancel()
		},
		async submit() {
			this.indexPanels()
			if (this.editing) {
				const changes = differenceWith(
					toPairs(toRaw(this.accordionMeta)),
					toPairs(this.original),
					isEqual
				)
				await this.accordionsStore.update(
					this.accordionMeta.id,
					fromPairs(changes)
				)
			} else {
				await this.accordionsStore.create()
			}
			if (isEmpty(this.errors)) this.cancel()
		},
		async loadData() {
			if (this.editing) {
				await this.accordionsStore.fetchOne(this.$route.params.id)
				this.original = structuredClone(this.accordionMeta)
			}
		}
	},
	computed: {
		...mapStores(useAccordionsStore, useMainStore),
		editing() {
			return !!this.$route.params.id
		},
		accordionMeta() {
			return this.accordionsStore.activeAccordion
		},
		accordion() {
			return this.accordionMeta.content
		},
		errors() {
			return this.mainStore.errors
		}
	}
}
</script>
