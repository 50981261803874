<template>
	<div class="mb-4">
		<div class="mb-2 flex items-center">
			<label class="block text-sm">{{ label }}</label>
			<Switch
				v-if="includeSwitch"
				v-model="enabled"
				:class="[
					enabled ? 'bg-gray-700' : 'bg-gray-200',
					error ? 'ring-2 ring-red-500 ring-offset-2' : 'focus:ring-blue-500',
					'relative ml-2 inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
				]"
			>
				<span class="sr-only">Active</span>
				<span
					aria-hidden="true"
					:class="[
						enabled ? 'translate-x-5' : 'translate-x-0',
						'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
					]"
				/>
			</Switch>
		</div>
		<v-date-picker
			v-model="date"
			:mode="dateMode"
			v-if="(includeSwitch && enabled && !excludeDate) || !includeSwitch"
		>
			<template v-slot="{ inputValue, inputEvents }">
				<div class="relative">
					<input
						type="text"
						:class="[
							error
								? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
								: 'focus:border-blue-500 focus:ring-blue-500',
							'block w-full rounded-md border-gray-300 bg-white text-gray-700 shadow-sm sm:text-sm'
						]"
						:value="inputValue"
						v-on="inputEvents"
					/>
					<div
						class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
						v-if="error"
					>
						<svg
							class="h-5 w-5 text-red-500"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
			</template>
		</v-date-picker>
		<p class="mt-1 text-sm text-red-600" v-if="error">{{ error }}</p>
	</div>
</template>

<script>
import { Switch } from '@headlessui/vue'

export default {
	components: { Switch },
	props: {
		modelValue: {
			type: [String, Date],
			required: false
		},
		label: {
			type: String,
			required: false
		},
		includeSwitch: {
			type: Boolean,
			default: false
		},
		includeTime: {
			type: Boolean,
			default: false
		},
		excludeDate: {
			type: Boolean,
			default: false
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	emits: ['update:modelValue', 'removeError'],
	data: () => ({}),
	computed: {
		date: {
			get() {
				return this.modelValue ? new Date(this.modelValue) : null
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		enabled: {
			get() {
				return this.modelValue ? true : false
			},
			set(value) {
				value
					? this.$emit('update:modelValue', new Date())
					: this.$emit('update:modelValue', null)
			}
		},
		dateMode() {
			return this.includeTime ? 'dateTime' : 'date'
		}
	},
	watch: {
		modelValue() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
