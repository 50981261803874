import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { useUsersStore } from './users'
import { State } from '@/types/siteConfig'
import axios from 'axios'

export const useSiteConfigStore = defineStore('siteConfig', {
	state: (): State => ({
		siteConfig: {},
		selectOptions: {
			menus: [],
			alert: ['top']
		}
	}),
	getters: {
		getConfiguration(state) {
			return state.siteConfig
		},
		getSelectOptions(state) {
			return state.selectOptions
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		mainStore() {
			return useMainStore()
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		async fetchConfiguration() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/config`,
					this.userConfig
				)
				this.siteConfig = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async submit() {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/config`,
					this.siteConfig,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Settings updated.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
