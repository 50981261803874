<template>
	<label
		:class="[
			{ 'w-full': fullWidth },
			'hover:bg-blue inline-flex cursor-pointer items-center rounded bg-cobalt py-2 pl-2.5 pr-3.5 text-white'
		]"
	>
		<DocumentPlusIcon class="mr-2 h-8 w-8" /><em>{{
			required ? `${label} *` : label
		}}</em>
		<input :accept="`.${fileType}`" class="hidden" type="file" />
	</label>
</template>

<script>
import { DocumentPlusIcon } from '@heroicons/vue/24/solid'
export default {
	name: 'FileInput',
	props: {
		label: {
			default: 'Choose a file...',
			required: false
		},
		fileType: {
			type: String,
			default: 'pdf',
			required: false
		},
		fullWidth: {
			type: Boolean,
			default: false,
			required: false
		},
		required: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	components: {
		DocumentPlusIcon
	}
}
</script>
