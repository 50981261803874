<template>
	<div class="absolute inset-0 z-50 flex items-center justify-center">
		<span class="absolute inset-0 bg-black opacity-50" @click="close"></span>
		<div class="relative ml-64 w-full max-w-4xl px-6">
			<form class="relative w-full rounded-md bg-gray-50 p-4">
				<h2 class="text-grey-900 mb-4 text-xl font-bold">Upload File</h2>
				<div class="mb-4">
					<label class="mb-1 block text-sm font-medium leading-5 text-gray-700">
						File
					</label>
					<input
						type="file"
						class=""
						@change="onFileUpload"
						v-if="!file"
						aria-label="file"
					/>
					<div
						class="flex items-center justify-between rounded border bg-white pl-3 leading-9 shadow-sm"
						v-if="file"
					>
						<span>{{ fileInfo.name }}</span>
						<div class="flex items-center justify-between space-x-2">
							<span>{{ fileInfo.size }}</span>
							<button
								type="button"
								class="focus:shadow-outline-red inline-flex items-center rounded-r border border-transparent bg-red-100 px-2.5 text-xs font-medium leading-9 text-red-700 transition duration-150 ease-in-out hover:bg-red-50 focus:border-red-300 focus:outline-none active:bg-red-200"
								@click="file = null"
							>
								Remove
							</button>
						</div>
					</div>
				</div>
				<div class="mb-4">
					<label class="block text-sm font-medium leading-5 text-gray-700">
						Upload Folder
					</label>
					<div class="mt-1 rounded-md shadow-sm">
						<select
							v-model="folderId"
							class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
						>
							<option :value="null">Select Folder</option>
							<option
								v-for="folder in folders"
								:key="folder.id"
								:value="folder.id"
							>
								{{ folder.title }}
							</option>
						</select>
					</div>
				</div>
				<TextInput v-model="title" label="Title" />
				<div class="mt-4 flex justify-end">
					<button
						type="button"
						class="inline-flex items-center rounded border border-transparent px-2.5 py-1.5 text-xs font-medium leading-4 text-white transition duration-150 ease-in-out focus:outline-none"
						:class="[
							file
								? 'focus:shadow-outline-gray bg-gray-600 hover:bg-gray-500 focus:border-gray-700 active:bg-gray-700'
								: 'cursor-not-allowed bg-gray-300'
						]"
						@click="upload"
					>
						Upload
					</button>
				</div>
			</form>
			<button
				type="button"
				class="absolute top-0 right-0 mr-8 mt-2 text-gray-400 transition hover:text-gray-600 focus:ring-2"
				@click="close"
			>
				<svg class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
					<path
						fill-rule="evenodd"
						d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
						clip-rule="evenodd"
					/>
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMediaStore } from '../../stores/media'
export default {
	name: 'FileUploader',
	data() {
		return {
			title: '',
			file: null,
			folderId: null
		}
	},
	methods: {
		onFileUpload(event) {
			this.file = event.target.files[0]
		},
		upload() {
			if (!this.file && !this.title && this.folderId) return
			const formData = new FormData()
			formData.append('file', this.file, this.file.name)
			formData.append('name', this.title)
			formData.append('folderId', this.folderId)
			this.mediaStore.upload(formData)
		},
		close() {
			this.mediaStore.toggleFileUploader()
		}
	},
	computed: {
		...mapStores(useMediaStore),
		fileInfo() {
			if (this.file) {
				const size =
					this.file.size > 1000000
						? `${(this.file.size / 1000000).toFixed(1)} Mb`
						: `${(this.file.size / 1000).toFixed(1)} Kb`
				return {
					name: this.file.name,
					type: this.file.type,
					size
				}
			}
			return {}
		},
		folders() {
			return this.mediaStore.getFolders
		}
	}
}
</script>
