<template>
	<div
		:class="[
			error ? 'mb-3' : 'mb-5',
			inlineLabel ? 'flex items-center' : '',
			'flex-1'
		]"
	>
		<label
			class="mb-1 block text-sm font-medium leading-5"
			:class="{ 'mr-2 whitespace-nowrap': inlineLabel }"
			>{{ label }}</label
		>
		<div class="relative flex w-full">
			<span
				v-if="addOn"
				class="w-fit cursor-default rounded-none rounded-l-md border border-gray-300 bg-gray-50 py-2 px-3 text-gray-700 shadow-sm sm:text-sm"
				>{{ addOn }}</span
			>
			<input
				:readonly="readonly"
				v-maska="mask"
				:type="type"
				:value="modelValue"
				@input="$emit('update:modelValue', $event.target.value)"
				:class="[
					addOn ? '-ml-px rounded-r-md' : 'rounded-md',
					error
						? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
						: 'focus:border-blue-500 focus:ring-blue-500',
					readonly
						? 'cursor-not-allowed bg-gray-200 text-gray-600'
						: 'bg-white text-gray-700',
					'block w-full border-gray-300 shadow-sm sm:text-sm'
				]"
				:aria-label="label"
			/>
			<div
				class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
				v-if="error"
			>
				<svg
					class="h-5 w-5 text-red-500"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fill-rule="evenodd"
						d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
		</div>
		<span class="text-xs italic text-gray-500" v-if="helpText">{{
			helpText
		}}</span>
		<p class="mt-1 text-sm text-red-600" v-if="error">{{ error }}</p>
	</div>
</template>

<script>
import { maska } from 'maska'

export default {
	name: 'TextInput',
	directives: { maska },
	emits: ['update:modelValue', 'removeError'],
	props: {
		mask: {
			type: String,
			required: false,
			default: ''
		},
		modelValue: {
			type: [String, Number],
			default: ''
		},
		readonly: {
			type: Boolean
		},
		label: {
			type: String
		},
		helpText: {
			type: String
		},
		error: {
			type: String,
			default: '',
			required: false
		},
		inlineLabel: {
			type: Boolean,
			default: false
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		addOn: {
			type: String,
			required: false,
			default: ''
		}
	},
	watch: {
		modelValue() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
