import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { useUsersStore } from './users'
import { State } from '@/types/menus'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import axios from 'axios'

export const useMenusStore = defineStore('menus', {
	state: (): State => ({
		menus: [],
		active: null,
		original: null,
		activeItemIndex: null,
		activeItem: {
			name: '',
			menuType: null,
			url: '',
			newTab: false,
			external: false,
			published: '',
			items: []
		},
		defaultMenu: {
			name: '',
			menuType: null,
			url: '',
			newTab: false,
			external: false,
			published: '',
			items: []
		},
		tempItem: {}
	}),
	getters: {
		usersStore() {
			return useUsersStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		isEdited(state) {
			return !isEqual(state.active, state.original)
		},
		getMenus(state) {
			return state.menus
		},
		getActive(state) {
			return state.active
		},
		getActiveItem(state) {
			return state.activeItem
		},
		mainStore() {
			return useMainStore()
		}
	},
	actions: {
		setActiveMenu(menu: any) {
			this.active = menu
		},
		addItem() {
			this.active.items.push(this.activeItem)
			if (this.tempItem) delete this.tempItem
			this.activeItem = structuredClone(this.defaultMenu)
		},
		cancelItem() {
			if (this.tempItem) delete this.tempItem
			this.activeItem = structuredClone(this.defaultMenu)
		},
		editItem(item: any) {
			this.tempItem = cloneDeep(item)
			this.activeItem = this.tempItem.item
		},
		updateItem() {
			this.tempItem.parent
				? this.active.items[this.tempItem.parent.index].items.splice(
						this.tempItem.index,
						1,
						this.activeItem
				  )
				: this.active.items.splice(this.tempItem.index, 1, this.activeItem)
			delete this.tempItem
			this.activeItem = structuredClone(this.defaultMenu)
		},
		removeItem(item: any) {
			!item.parent
				? this.active.items.splice(item.index, 1)
				: this.active.items[item.parent.index].items.splice(item.index, 1)
		},
		addMenu(menu: any) {
			this.menus.push(menu)
		},
		setMenus(menus: any) {
			this.menus = menus
			if (this.active) {
				this.active = this.menus.find((menu) => menu.id === this.active.id)
			}
		},
		removeMenu() {
			this.menus = this.menus.filter((menu) => menu.name !== this.active.name)
			this.active = null
		},
		async create() {
			await this.usersStore.checkLogin()
			try {
				const { name, published, items } = this.active
				await axios.post(
					`${process.env.VUE_APP_API_URL}/menus`,
					{
						name,
						published,
						items: items
					},
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Menu created.',
					timer: 3000
				})
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update() {
			await this.usersStore.checkLogin()
			try {
				const { name, published, items, id } = this.active
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/menus/${id}`,
					{
						name,
						published,
						items: items
					},
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Menu updated.',
					timer: 3000
				})
				// dispatch('getMenus')
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove() {
			if (this.active.id) {
				await this.usersStore.checkLogin()
				try {
					await axios.delete(
						`${process.env.VUE_APP_API_URL}/menus/${this.active.id}`,
						this.userConfig
					)
				} catch (error: any) {
					this.mainStore.handleErrors(error.response.data.error)
				}
			}
			this.removeMenu()
			this.mainStore.displayAlert({
				level: 'success',
				title: 'Menu deleted.',
				timer: 3000
			})
		},
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/menus`,
					this.userConfig
				)
				this.menus = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
