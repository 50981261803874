<template>
	<div class="flex-1">
		<span class="flex space-x-3">
			<TextInput
				label="Label"
				v-model="computedProps.label"
				:error="errors?.label"
				@removeError="$emit('removeError', ['label'])"
			/>
			<FormSelect
				label="Column Width"
				:options="[...Array(12)].map((item, index) => index + 1)"
				v-model="computedProps.width"
			/>
		</span>
		<div class="mb-5">
			<TextArea
				label="Select Option(s)"
				placeholder="Value 1, Alt Display Text&#10;Value 2, Alt Display Text&#10;Value 3, Alt Display Text"
				v-model="options"
				:error="errors?.options"
				@removeError="$emit('removeError', ['options'])"
			/>
			<div class="flex items-center justify-between">
				<div class="flex items-center space-x-2 text-sm italic">
					<span>Presents:</span>
					<button
						type="button"
						class="text-cobalt hover:text-kta-blue"
						@click="computedProps.options = states"
					>
						States
					</button>
				</div>
				<button
					type="button"
					class="text-kta-red hover:text-kta-red-dark"
					@click="computedProps.options = []"
				>
					Clear
				</button>
			</div>
		</div>
		<FormToggle label="Required" v-model="computedProps.required" />
	</div>
</template>

<script>
import { FormToggle, TextArea } from '@/components/utilities/form'
import States from '@/constants/States.js'
export default {
	name: 'SelectInputSettings',
	props: {
		props: {
			type: Object,
			required: true
		},
		errors: {
			type: Object,
			required: false
		}
	},
	emits: ['removeError'],
	components: {
		FormToggle,
		TextArea
	},
	methods: {},
	computed: {
		computedProps() {
			return this.props
		},
		preset() {
			return ''
		},
		states() {
			return States
		},
		options: {
			get() {
				return this.computedProps.options
					? this.computedProps.options
							.reduce((string, option) => {
								return option.optionName || option.nameGiven
									? `${string}${option.optionValue},${option.optionName}\n`
									: `${string}${option.optionValue}\n`
							}, '')
							.slice(0, -1)
					: []
			},
			set(value) {
				this.computedProps.options = value
					.replace(/(,[ \t]*)(.*)/g, ',$2')
					.split('\n')
					.map((value) => {
						if (value.includes(',')) {
							const option = value.split(/,(.*)/s)
							return {
								optionValue: option[0],
								optionName: option[1],
								nameGiven: true
							}
						} else {
							return {
								optionValue: value,
								optionName: '',
								nameGiven: false
							}
						}
					})
			}
		}
	}
}
</script>
