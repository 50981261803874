import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { State, ServiceArea } from '@/types/serviceAreas'
import { useUsersStore } from './users'
import axios from 'axios'

export const useServiceAreasStore = defineStore('serviceAreas', {
	state: (): State => ({
		serviceAreas: [],
		amenities: [],
		active: {
			name: '',
			image: {
				id: null,
				path: null
			},
			imageId: null,
			imagePosition: 'center',
			mile: '',
			content: '',
			position: { lat: null, lng: null },
			amenities: [],
			published: ''
		}
	}),
	getters: {
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		resetActive() {
			this.active = new ServiceArea()
		},
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/service-areas`,
					this.userConfig
				)
				this.serviceAreas = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchOne(id: number) {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/service-areas/${id}`,
					this.userConfig
				)
				this.active = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async fetchAmenities() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/amenities`,
					this.userConfig
				)
				this.amenities = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create() {
			await this.usersStore.checkLogin()
			const { amenities, ...data } = this.active
			const updatedAmenities = amenities?.map(({ image, ...rest }) => ({
				...rest
			}))

			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/service-areas`,
					{ ...data, amenities: updatedAmenities },
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Service Area created.',
					timer: 3000
				})
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update() {
			await this.usersStore.checkLogin()
			const { image, amenities, ...data } = this.active
			const serviceArea: { [key: string]: any } = { ...data }
			if (amenities) serviceArea.amenities = amenities.map(({ id }) => id)
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/service-areas/${this.active.id}`,
					serviceArea,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Service Area updated.',
					timer: 3000
				})
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove() {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/service-areas/${this.active.id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Service Area deleted.',
					timer: 3000
				})
				return true
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
