<template>
	<h2 class="mt-8 mb-4 font-bold">Preview</h2>
	<div
		class="mt-1 space-y-5 rounded border border-zinc-300 bg-white p-6 shadow"
	>
		<InfoWindow v-if="serviceArea" :serviceArea="serviceArea" />
		<span v-else class="text-gray-400"><em>No Info Window Preview</em></span>
	</div>
</template>

<script>
import InfoWindow from './InfoWindow'
export default {
	name: 'ServiceAreaPreview',
	props: {
		serviceArea: {
			type: Object,
			required: true
		}
	},
	components: {
		InfoWindow
	}
}
</script>
