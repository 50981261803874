<template>
	<div class="relative bg-white shadow sm:rounded-md">
		<div class="p-4 italic text-gray-500" v-if="items.length < 1">
			No items found.
		</div>
		<ul>
			<li
				v-for="(item, index) in items"
				:key="index"
				:class="[index > 0 ? 'border-t border-gray-200' : '']"
			>
				<component
					:is="to ? 'router-link' : 'div'"
					:to="to ? { name: to, params: { id: item.id } } : null"
				>
					<div
						:class="[
							to ? 'px-4 sm:px-6' : 'pl-4 pr-8 sm:pl-6 sm:pr-10',
							'relative grid-cols-2 py-4 sm:px-6 md:grid'
						]"
					>
						<div
							v-for="(prop, key) in removeId(item)"
							:key="key"
							class="overflow-hidden md:even:text-right"
						>
							<span class="mr-2 text-xs font-light uppercase text-gray-400">{{
								key
							}}</span>
							<span class="text-grey-600 truncate text-sm font-medium leading-5"
								>{{ prop }}
							</span>
						</div>
						<div
							class="fixed inset-0 z-10"
							v-if="!to && active === item.id"
							@click="toggleActive"
						></div>
						<div v-if="!to" class="absolute right-1 mt-4 shrink-0">
							<button
								class="relative z-0 inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
								@click="toggleActive(item.id)"
							>
								<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
									<path
										d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
									/>
								</svg>
							</button>
							<transition name="expand">
								<div
									class="absolute right-3 z-10 mt-1 w-48 origin-top-right rounded-md shadow-lg"
									v-show="active === item.id"
								>
									<div
										class="rounded-md border bg-white py-1 shadow-sm"
										role="menu"
										aria-orientation="vertical"
										aria-labelledby="user-menu"
									>
										<button
											type="button"
											class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
											role="menuitem"
											@click="editItem(item.id)"
										>
											Edit
										</button>
										<button
											type="button"
											class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
											role="menuitem"
											@click="confirm = true"
										>
											Remove
										</button>
									</div>
								</div>
							</transition>
						</div>
					</div>
				</component>
			</li>
		</ul>
		<ConfirmDialog :isOpen="confirm" @confirmed="removeItem" />
	</div>
</template>

<script>
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
export default {
	name: 'DisplayItems',
	components: {
		ConfirmDialog
	},
	props: {
		items: {
			type: Array,
			required: true
		},
		to: {
			type: String,
			default: '',
			required: false
		}
	},
	data: () => ({
		active: null,
		confirm: false
	}),
	methods: {
		removeId(item) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { id, ...props } = item
			return props
		},
		toggleActive(id) {
			if (!id) this.active = null
			this.active = this.active === id ? null : id
		},
		editItem(id) {
			this.$emit('editItem', id)
			this.active = null
		},
		removeItem(confirm) {
			if (confirm) {
				this.$emit('removeItem', this.active)
			}

			this.active = null
			this.confirm = false
		}
	}
}
</script>
<style scoped>
.expand-enter-active,
.expand-leave-active {
	transition: transform 0.5s, opacity 0.5s;
}
.expand-enter,
.expand-leave-to {
	opacity: 0;
	transform: scale(0.95);
}
</style>
