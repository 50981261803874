import { defineStore } from 'pinia'
import axios from 'axios'
import { useUsersStore } from './users'
import { useMainStore } from './main'
import { File, Folder, State } from '@/types/media'
import cloneDeep from 'lodash/cloneDeep'
import { format } from 'date-fns'

export const useMediaStore = defineStore('media', {
	state: (): State => ({
		showFileUploader: false,
		showFilePicker: false,
		activeFolder: null,
		files: [],
		folders: [],
		sortFiles: {
			by: 'added',
			direction: 'DESC'
		}
	}),
	getters: {
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		mainStore() {
			return useMainStore()
		},
		getActiveFolder(state) {
			return state.activeFolder
		},
		getImageFiles() {
			const allFiles: File[] = this.getFiles
			return allFiles.filter((file: File) => file.type.includes('image'))
		},
		getFiles(state): File[] {
			const files: File[] = cloneDeep(state.files).sort((a: File, b: File) => {
				return state.sortFiles.direction === 'DESC'
					? a[state.sortFiles.by] < b[state.sortFiles.by]
						? 1
						: -1
					: a[state.sortFiles.by] > b[state.sortFiles.by]
					? 1
					: -1
			})
			return files.map((file: File) => {
				file.added = format(new Date(file.added), 'MM/dd/yyyy h:mm a')
				file.size > 1000000
					? (file.size = `${(+file.size / 1000000).toFixed(2)} Mb`)
					: (file.size = `${(+file.size / 1000).toFixed(2)} Kb`)
				return file
			})
		},
		getFolders(state): Folder[] {
			return state.folders
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		setActiveFolder(id: number) {
			this.activeFolder = id
			id ? this.fetchFiles() : this.fetchAllFiles()
		},
		async fetchFolders() {
			await this.usersStore.checkLogin()
			const { data } = await axios.get(
				`${process.env.VUE_APP_API_URL}/media/folders`,
				this.userConfig
			)
			this.folders = data
		},
		async fetchAllFiles() {
			await this.usersStore.checkLogin()
			const { data } = await axios.get(
				`${process.env.VUE_APP_API_URL}/media/files`,
				this.userConfig
			)
			this.files = data
		},
		async fetchFiles() {
			await this.usersStore.checkLogin()
			if (this.activeFolder) {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/media/files/folder/${this.activeFolder}`,
					this.userConfig
				)
				this.files = data
			}
		},
		async upload(file: File) {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/media/files`,
					file,
					this.userConfig
				)
				this.showFileUploader = !this.showFileUploader
				this.activeFolder ? this.fetchFiles() : this.fetchAllFiles()
				this.mainStore.displayAlert({
					position: 'top',
					level: 'success',
					title: 'File Uploaded.',
					timer: 3000
				})
			} catch (error) {
				this.mainStore.displayAlert({
					position: 'top',
					level: 'error',
					title: 'Upload Failed.',
					text: error,
					timer: 5000
				})
			}
		},
		async updateFile(file: File) {
			await this.usersStore.checkLogin()
			const { id, ...data } = file
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/media/files/${id}`,
					data,
					this.userConfig
				)
				this.activeFolder ? this.fetchFiles() : this.fetchAllFiles()
				this.mainStore.displayAlert({
					position: 'top',
					level: 'success',
					title: 'File Updated.',
					timer: 3000
				})
			} catch (error) {
				this.mainStore.displayAlert({
					position: 'top',
					level: 'error',
					title: 'Update Failed.',
					text: error,
					timer: 5000
				})
			}
		},
		async createFolder(folder: Folder) {
			await this.usersStore.checkLogin()
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/media/folders`,
					folder,
					this.userConfig
				)
				this.fetchFolders()
			} catch (error: any) {
				this.mainStore.displayAlert({
					position: 'top',
					level: 'error',
					title: error.response.data.error.message,
					timer: 5000
				})
				this.mainStore.handleErrors(error.response.data)
			}
		},
		async deleteFile(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/media/files/${id}`,
					this.userConfig
				)

				this.mainStore.displayAlert({
					position: 'top',
					level: 'success',
					title: 'File deleted.',
					timer: 3000
				})
				this.fetchAllFiles()
			} catch (error: any) {
				this.mainStore.displayAlert({
					position: 'top',
					level: 'error',
					title: error.response.data.error.error,
					text: `Pages: ${error.response.data.error.message}`,
					timer: 5000
				})
				this.mainStore.handleErrors(error.response.data)
			}
		},
		async deleteFiles(ids: number[]) {
			await this.usersStore.checkLogin()
			const fileIds = Object.values(ids)
			try {
				await axios.post(
					`${process.env.VUE_APP_API_URL}/media/files/remove-multiple`,
					{ ids: fileIds },
					this.userConfig
				)
				this.mainStore.displayAlert({
					position: 'top',
					level: 'success',
					title: 'File(s) deleted.',
					timer: 3000
				})
				this.fetchAllFiles()
			} catch (error: any) {
				this.mainStore.displayAlert({
					position: 'top',
					level: 'error',
					title: error.response.data.error.message,
					timer: 5000
				})
				this.mainStore.handleErrors(error.response.data)
			}
		},
		async deleteFolder(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/media/folders/${id}`,
					this.userConfig
				)

				this.mainStore.displayAlert({
					position: 'top',
					level: 'success',
					title: 'Folder deleted.',
					timer: 3000
				})
				this.fetchFolders()
			} catch (error: any) {
				this.mainStore.displayAlert({
					position: 'top',
					level: 'error',
					title: error.response.data.error.message,
					timer: 5000
				})
				this.mainStore.handleErrors(error.response.data)
			}
		},
		async updateFolder(folder: Folder) {
			await this.usersStore.checkLogin()
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/media/folders/${folder.id}`,
					{ title: folder.title, name: folder.name },
					this.userConfig
				)
				this.fetchFolders()
			} catch (error: any) {
				this.mainStore.displayAlert({
					position: 'top',
					level: 'error',
					title: 'Unable to update folder.',
					timer: 5000
				})
				this.mainStore.handleErrors(error.response.data)
			}
		},
		toggleFileUploader() {
			this.showFileUploader = !this.showFileUploader
		},
		toggleFilePicker() {
			this.showFilePicker = !this.showFilePicker
		},
		setSortBy(prop: keyof File) {
			if (this.sortFiles.by !== prop) {
				this.sortFiles.by = prop
				this.sortFiles.direction === 'ASC'
			} else {
				this.sortFiles.direction =
					this.sortFiles.direction === 'ASC' ? 'DESC' : 'ASC'
			}
		}
	}
})
