<template>
	<div :class="`w-full ${span}`">
		<div v-if="label" :class="[{ 'font-bold': boldLabel }, 'text-black']">
			{{ required ? `${label} *` : label }}
		</div>
		<div
			v-if="description"
			:class="[{ 'font-bold': boldDescription }, 'text-extra-dark']"
		>
			{{ description }}
		</div>
		<ul
			:class="[
				orientation === 'stacked' ? 'flex-col space-y-2' : 'space-x-3',
				'content-ul-none mt-3 flex'
			]"
		>
			<li
				v-for="(option, index) in options"
				:key="index"
				class="content-li-none"
			>
				<label class="flex items-center">
					<input
						type="checkbox"
						class="h-5 w-5 rounded text-cobalt focus:ring-cobalt"
					/>
					<span class="ml-3 text-extra-dark">
						{{ option.optionName ? option.optionName : option.optionValue }}
					</span>
				</label>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormsStore } from '@/stores/forms'
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'WFCheckInput',
	props: {
		label: {
			type: String,
			required: false
		},
		width: {
			type: [String, Number],
			default: 1,
			required: false
		},
		description: {
			type: String,
			required: false
		},
		boldLabel: {
			type: Boolean,
			default: true,
			required: false
		},
		boldDescription: {
			type: Boolean,
			default: false,
			required: false
		},
		options: {
			type: Array,
			required: true
		},
		orientation: {
			type: String,
			default: 'stacked',
			required: false
		},
		required: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	computed: {
		...mapStores(useFormsStore),
		span() {
			return this.formsStore.span[this.width]
		}
	}
})
</script>
