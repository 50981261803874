<template>
	<PageLayout title="Links">
		<div class="flex items-start space-x-4" v-if="link">
			<TextInput
				:addOn="addOn"
				label="Old Link"
				v-model="link.old"
				:error="errors?.old"
				@removeError="removeError(['old'])"
			/>
			<LinkInput
				v-model:new="link.new"
				v-model:type="link.type"
				label="New Link"
				:error="errors?.new"
				@removeError="removeError(['new'])"
			/>
			<div class="place-self-center">
				<button
					v-if="editing"
					type="button"
					:class="[
						noErrors ? 'mt-1' : 'mb-3',
						'focus:shadow-outline-gray mr-2 inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700'
					]"
					@click="cancel"
				>
					Cancel
				</button>
				<button
					v-text="editing ? 'Update' : 'Add'"
					type="button"
					:class="[
						noErrors ? 'mt-1' : 'mb-3',
						'focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700'
					]"
					@click="submit(this.link.id)"
				></button>
			</div>
		</div>
		<DisplayItems
			:items="links"
			@editItem="editLink($event)"
			@removeItem="removeLink($event)"
		/>
	</PageLayout>
</template>

<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/main'
import { useLinksStore } from '@/stores/links'
import { format } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import LinkInput from '@/components/utilities/form/LinkInput.vue'
import DisplayItems from '@/views/layout/DisplayItems.vue'
export default {
	name: 'LinksManager',
	components: {
		DisplayItems,
		LinkInput
	},
	async created() {
		await this.linksStore.fetchLinks()
		this.linksStore.init()
	},
	data: () => ({
		editing: false,
		addOn: process.env.VUE_APP_CLIENT_URL
	}),
	methods: {
		editLink(id) {
			this.linksStore.setActive(id)
			this.editing = true
		},
		removeLink(id) {
			this.linksStore.remove(id)
		},
		async submit(id) {
			if (this.editing) {
				const response = await this.linksStore.update(id)
				if (response) this.editing = false
				return
			}

			this.linksStore.create()
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		cancel() {
			this.editing = false
			this.linksStore.init()
		}
	},
	computed: {
		...mapStores(useLinksStore, useMainStore),
		errors() {
			return this.mainStore.errors
		},
		noErrors() {
			return isEmpty(this.mainStore.errors)
		},
		links() {
			return this.linksStore.links.map((link) => {
				link.created = format(new Date(link.created), 'MM/dd/yyyy h:mm a')
				link.updated = format(new Date(link.updated), 'MM/dd/yyyy h:mm a')
				return link
			})
		},
		link() {
			return this.linksStore.active
		}
	}
}
</script>
