<template>
	<div class="fixed inset-0 z-40 flex items-center justify-center">
		<div
			class="absolute inset-0 bg-black opacity-20"
			@click="$emit('cancel')"
		></div>
		<div
			class="relative m-8 w-full max-w-4xl rounded-md border bg-white p-4 md:ml-72"
		>
			<div class="flex justify-end">
				<span class="inline-flex rounded-md shadow-sm">
					<button
						type="button"
						@click="upload"
						class="focus:shadow-outline-gray inline-flex items-center rounded border border-transparent bg-gray-600 px-2.5 py-1.5 text-xs font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
					>
						Upload
					</button>
				</span>
			</div>
			<div>
				<div class="mb-5 border-b border-gray-300 pb-2" v-if="imagesOnly">
					<div class="items-center lg:flex">
						<TextInput
							class="lg:mr-4"
							:value="fileName"
							errorKey=""
							readonly
							label="File"
						/>
						<TextInput v-model="title" label="Title" errorKey="" />
					</div>
					<div class="items-center lg:flex">
						<TextInput
							class="lg:mr-4"
							v-model="style"
							label="Style"
							v-if="showStyle"
							errorKey=""
						/>
						<TextInput v-model="alt" label="Alternate Text" errorKey="" />
					</div>
				</div>
				<div class="mb-4">
					<label class="block text-sm font-medium leading-5 text-gray-700"
						>Folder</label
					>
					<div class="rounded-md shadow-sm">
						<select
							v-model="activeFolder"
							class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
						>
							<option :value="null">All Files</option>
							<option
								v-for="folder in folders"
								:key="folder.id"
								:value="folder.id"
							>
								{{ folder.title }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div
				class="flex items-center justify-between border border-solid border-gray-900 bg-gray-900 px-4 py-3 text-sm text-gray-300"
			>
				<div class="w-1/3">Title/Name</div>
				<div class="w-3/12 pl-2">Type</div>
				<div class="w-1/6 pl-2">Size</div>
				<div class="w-3/12 pl-2">Date Added</div>
			</div>
			<ul
				class="max-h-60 overflow-y-scroll border border-solid border-gray-200 bg-white text-sm"
			>
				<li v-if="!files.length" class="py-2 px-4 italic text-gray-400">
					folder empty
				</li>
				<li
					v-for="(item, index) in files"
					:key="index"
					:class="[
						{ 'border-t border-gray-200': index > 0 },
						{ 'bg-gray-100': index % 2 },
						{ 'bg-blue-100': checkItem(item) },
						'flex cursor-pointer items-center justify-between p-2 text-gray-800 hover:bg-blue-100'
					]"
					@click="select(item)"
				>
					<div class="w-1/3">
						<span class="block">{{ item.name }}</span>
						<span class="block text-xs italic text-gray-400">{{
							item.filename
						}}</span>
					</div>
					<div class="w-3/12 pl-2">{{ item.type }}</div>
					<div class="w-1/6 pl-2">{{ item.size }}</div>
					<div class="w-3/12 pl-2">{{ item.added }}</div>
				</li>
			</ul>
			<div class="mt-4 flex items-center justify-end">
				<span class="inline-flex rounded-md shadow-sm">
					<button
						@click="$emit('cancel')"
						class="focus:shadow-outline-kta-blue mr-2 rounded border border-gray-300 px-2.5 py-1.5 text-xs font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
					>
						Cancel
					</button>
				</span>
				<span class="inline-flex rounded-md shadow-sm">
					<button
						type="button"
						@click="insert"
						class="focus:shadow-outline-gray inline-flex items-center rounded border border-transparent bg-gray-600 px-2.5 py-1.5 text-xs font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
					>
						Insert
					</button>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMediaStore } from '../../stores/media'
import isEqual from 'lodash/isEqual'
export default {
	created() {
		this.mediaStore.fetchFolders()
		this.mediaStore.fetchAllFiles()
	},
	name: 'FilePicker',
	props: {
		imagesOnly: {
			type: Boolean,
			required: false,
			default: false
		},
		showStyle: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data: () => ({
		alt: '',
		title: '',
		file: null,
		style: null,
		activeFolder: null
	}),
	computed: {
		...mapStores(useMediaStore),
		files() {
			return this.imagesOnly
				? this.mediaStore.getImageFiles
				: this.mediaStore.getFiles
		},
		fileName() {
			return this.file ? this.file.name : ''
		},
		folders() {
			return this.mediaStore.folders
		}
	},
	watch: {
		activeFolder(id) {
			this.mediaStore.setActiveFolder(id)
		}
	},
	methods: {
		checkItem(item) {
			return isEqual(item, this.file)
		},
		upload() {
			this.mediaStore.toggleFileUploader()
		},
		select(file) {
			this.file = file
			this.title = file.name
		},
		insert() {
			if (!this.file) return

			if (this.imagesOnly) {
				this.$emit('insertImage', {
					id: this.file.id,
					alt: this.alt,
					title: this.title,
					src: this.file.path,
					style: this.style
				})
			} else {
				this.$emit('insertLink', this.file.path)
			}
		}
	}
}
</script>
