<template>
	<div>
		<h5 v-if="label" class="text-sm">
			{{ label }}
			<span v-if="error" class="ml-2 text-sm font-normal italic text-kta-red"
				>Required</span
			>
		</h5>
		<ul
			:class="[
				orientation === 'stacked' ? 'flex-col space-y-2' : '!space-x-6',
				'content-ul-none mt-3 flex'
			]"
		>
			<li
				v-for="(option, index) in options"
				:key="index"
				class="content-li-none"
			>
				<label class="flex w-fit items-center">
					<input
						type="radio"
						class="h-5 w-5 text-gray-700 focus:ring-cobalt"
						v-model="value"
						:value="option.optionValue"
						@input="$emit('update:modelValue', $event.target.value)"
					/>
					<span
						:class="[
							{ italic: italicOptions },
							'ml-3 text-sm leading-tight text-black'
						]"
					>
						{{ option.optionName ? option.optionName : option.optionValue }}
					</span>
				</label>
				<slot v-if="option.slotName" :name="option.slotName"></slot>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'RadioInput',
	props: {
		modelValue: {
			required: true
		},
		label: {
			type: String,
			required: false
		},
		italicOptions: {
			type: Boolean,
			default: false,
			required: false
		},
		options: {
			type: Array,
			required: true
		},
		orientation: {
			type: String,
			default: 'stacked',
			required: false
		},
		slotName: {
			type: String,
			default: '',
			required: false
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	emits: ['update:modelValue', 'removeError'],
	computed: {
		value: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		}
	},
	watch: {
		value() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
