<template>
	<TransitionRoot appear :show="isOpen" as="template">
		<Dialog as="div" class="">
			<div class="fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-30">
				<div class="min-h-screen px-4 text-center">
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100"
						leave-to="opacity-0"
					>
						<DialogOverlay class="fixed inset-0" />
					</TransitionChild>

					<span class="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>

					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<div
							class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl border border-gray-100 bg-white p-6 text-left align-middle shadow-xl transition-all"
						>
							<DialogTitle
								as="h3"
								class="text-lg font-medium leading-6 text-gray-900"
							>
								Your Session is about to expire!
							</DialogTitle>
							<div class="mt-2">
								<p class="text-sm text-gray-500">
									You are about to be automatically signed out. Do you want to
									stay logged in?
								</p>
								<div class="flex justify-center text-3xl font-bold">
									{{ logoutTimer }}
								</div>
							</div>

							<div class="mt-4">
								<button
									type="button"
									class="mr-2 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
									@click="refreshTokens"
								>
									Stay
								</button>
								<button
									type="button"
									class="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
									@click="logout"
								>
									Logout
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogOverlay,
	DialogTitle
} from '@headlessui/vue'
import { mapStores } from 'pinia'
import { useUsersStore } from '../../stores/users'

export default {
	name: 'SeesionTimeoutDialog',
	data: () => ({
		isOpen: false,
		warningTimer: null
	}),
	components: {
		TransitionRoot,
		TransitionChild,
		Dialog,
		DialogOverlay,
		DialogTitle
	},
	computed: {
		...mapStores(useUsersStore),
		isLoggedIn() {
			return !!this.usersStore.accessToken
		},
		logoutTimer() {
			return `${Math.floor(this.warningTimer / 60)}:${(
				this.warningTimer % 60
			).toLocaleString('en-US', { minimumIntegerDigits: 2 })}`
		}
	},
	methods: {
		logout() {
			this.isOpen = false
			this.usersStore.logout()
		},
		async refreshTokens() {
			this.clearTimers()
			await this.usersStore.refreshTokens()
			this.isOpen = false
			this.startTimers()
		},
		clearTimers() {
			clearTimeout(this.timeout)
			clearTimeout(this.warning)
			clearInterval(this.countdown)
		},
		showWarning() {
			this.warningTimer = 2 * 60
			this.isOpen = true
			this.countdown = setInterval(() => {
				this.warningTimer--
			}, 1000)
		},
		startTimers() {
			this.warning = setTimeout(() => {
				this.showWarning()
			}, 28 * 60 * 1000)
			this.timeout = setTimeout(() => {
				this.logout()
			}, 30 * 60 * 1000)
		}
	},
	watch: {
		isLoggedIn(value) {
			value ? this.startTimers() : this.clearTimers()
		}
	}
}
</script>
