<template>
	<div :class="`grid w-full ${columns} items-end gap-5`">
		<slot></slot>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormsStore } from '@/stores/forms.ts'
export default {
	name: 'WFFormRow',
	computed: {
		...mapStores(useFormsStore),
		columns() {
			const columns = this.formsStore.active.columns
			return this.formsStore.cols[columns]
		}
	}
}
</script>
