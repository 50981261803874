<template>
	<div class="font-roboto">
		<div
			class="shadow-iw relative mx-auto max-h-[430px] w-fit max-w-[315px] rounded-lg sm:max-h-[600px] sm:max-w-[470px]"
		>
			<XMarkIcon
				class="absolute top-0.5 right-0.5 h-3.5 w-3.5 cursor-pointer text-gray-600 hover:text-black"
			/>
			<div>
				<img
					:class="`${imagePosition} hidden h-full max-h-[200px] w-full rounded-t-lg object-cover sm:block`"
					:src="serviceArea.image?.path"
					alt="Service Area Image"
				/>
				<div class="p-6 text-sm text-extra-dark">
					<h4 class="text-blue mb-1 text-lg font-bold leading-none">
						{{ serviceArea.name }}
					</h4>
					<p class="mb-4">Service Area • Mile {{ serviceArea.mile }}</p>
					<span
						class="mb-1 space-y-4 whitespace-pre-line"
						v-html="serviceArea.content"
					></span>
					<span class="flex flex-wrap space-x-4 space-y-5">
						<img
							class="inline-block h-9 first:mt-5"
							v-for="amenity in serviceArea.amenities"
							:key="amenity"
							:src="amenity.image?.path"
							alt="Amenity Logo"
						/>
					</span>
				</div>
			</div>
			<div class="triangle-iw"></div>
		</div>
	</div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/solid'
export default {
	name: 'InfoWindow',
	props: {
		serviceArea: {
			type: Object,
			required: true
		}
	},
	components: {
		XMarkIcon
	},
	computed: {
		imagePosition() {
			return `object-${this.serviceArea.imagePosition}`
		}
	}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
.font-roboto {
	font-family: 'Roboto', 'Arial', 'sans-serif';
}
.border-gray-iw {
	border-color: rgb(117, 117, 117);
}

.shadow-iw {
	box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}

.triangle-iw {
	background: linear-gradient(
			45deg,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0) 51%,
			rgba(255, 255, 255, 0) 100%
		)
		#fff;
	box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
	content: '';
	height: 15px;
	left: 50%;
	bottom: -15px;
	position: absolute;
	transform: translate(-50%, -50%) rotate(-45deg);
	width: 15px;
}
</style>
