<template>
	<Listbox v-model="selectedOption">
		<div :class="`relative w-full leading-none ${span}`">
			<ListboxLabel
				class="mb-2 inline-block leading-none text-dark"
				v-if="label"
			>
				{{ required ? `${label} *` : label }}</ListboxLabel
			>
			<ListboxButton
				class="text-md relative w-full cursor-default rounded border border-medium bg-white py-2 pl-3 pr-10 text-left sm:text-base"
			>
				<span class="block truncate text-sm text-dark sm:text-base">
					{{ selectedOption }}</span
				>
				<span
					class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
				>
					<ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				</span>
			</ListboxButton>
			<transition
				leave-active-class="transition duration-100 ease-in"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<ListboxOptions
					class="absolute bottom-12 z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-medium bg-white py-1 text-base ring-1 ring-black ring-opacity-5 scrollbar-hide focus:outline-none sm:text-sm"
				>
					<ListboxOption
						v-slot="{ active, selected }"
						v-for="(option, index) in options"
						:key="index"
						:value="option"
						as="template"
					>
						<li
							:class="[
								active ? 'bg-kta-blue text-white' : 'text-extra-dark',
								'content-li-none relative cursor-default select-none py-2 px-4'
							]"
						>
							<span
								:class="[
									selected ? 'font-semibold' : 'font-normal',
									'block truncate text-sm sm:text-base'
								]"
								>{{
									option.optionName ? option.optionName : option.optionValue
								}}</span
							>
							<span
								v-if="selected"
								class="text-blue absolute inset-y-0 left-0 flex items-center pl-3"
							>
							</span>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</transition>
		</div>
	</Listbox>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormsStore } from '@/stores/forms'
import {
	Listbox,
	ListboxLabel,
	ListboxButton,
	ListboxOptions,
	ListboxOption
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
export default {
	name: 'WFSelectInput',
	components: {
		Listbox,
		ListboxLabel,
		ListboxButton,
		ListboxOptions,
		ListboxOption,
		ChevronDownIcon
	},
	props: {
		label: {
			type: String,
			required: false
		},
		width: {
			type: [String, Number],
			default: 1,
			required: false
		},
		options: {
			type: Array,
			required: true
		},
		placeholder: {
			type: String,
			required: false
		},
		required: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data: () => ({
		selected: ''
	}),
	computed: {
		...mapStores(useFormsStore),
		span() {
			return this.formsStore.span[this.width]
		},
		selectedOption: {
			get() {
				return this.selected
					? this.selected
					: this.placeholder
					? this.placeholder
					: 'Select Option'
			},
			set(value) {
				this.selected = value.optionName ? value.optionName : value.optionValue
			}
		}
	}
}
</script>
