<template>
	<div>
		<!-- <span class="mb-4 flex flex-row space-x-3">
			<BuilderInput
				label="Label"
				:value="props.label"
				@input="updateComponent($event.target.value, 'label')"
			/>
			<BuilderInput
				label="File Type"
				:value="props.fileType"
				@input="updateComponent($event.target.value, 'fileType')"
			/>
		</span>
		<BuilderToggle
			label="Required"
			:enabled="props.required"
			@enabled="updateComponent($event, 'required')"
		/> -->
	</div>
</template>

<script>
// import BuilderInput from '@/components/formBuilder/utilities/BuilderInput.vue'
// import BuilderToggle from '@/components/formBuilder/utilities/BuilderToggle.vue'
export default {
	name: 'FileInput',
	props: {
		props: {
			type: Object,
			default: () => ({
				label: 'Choose a file...',
				fileType: 'pdf',
				required: false
			}),
			required: true
		}
	},
	emits: ['updateComponent'],
	components: {
		// BuilderInput,
		// BuilderToggle
	},
	data: () => ({
		type: 'FileInput',
		name: 'File'
	}),
	methods: {
		updateComponent(newVal, prop) {
			const newProps = this.props
			newProps[prop] = newVal
			this.$emit('updateComponent', {
				type: this.type,
				name: this.name,
				props: newProps
			})
		}
	}
}
</script>
