<template>
	<div class="flex" :class="[description ? 'items-start' : 'items-center']">
		<input
			:value="name"
			v-model="checked"
			:id="name"
			:name="name"
			type="checkbox"
			class="h-4 w-4"
			:class="[
				bgDark
					? 'rounded border-gray-300 text-blue-500 focus:ring-gray-500'
					: 'rounded border-gray-300 text-gray-600 focus:ring-gray-500',
				disabled ? 'cursor-not-allowed bg-gray-100' : ''
			]"
			:disabled="disabled"
		/>
		<div class="ml-2 text-sm">
			<label :for="name" :class="[disabled ? 'text-gray-400' : '', 'block']">
				{{ label }}
			</label>
			<p v-if="description" v-html="description" class="text-gray-500"></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FormCheckbox',
	props: {
		bgDark: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		description: {
			type: String,
			require: false
		},
		modelValue: {
			type: Boolean,
			required: true,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		checked: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		}
	}
}
</script>

<style></style>
