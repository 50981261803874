<template>
	<button
		class="absolute right-4 bottom-4 z-50 flex h-16 w-16 items-center justify-center rounded-full border-r border-gray-800 bg-gray-800 text-gray-400 hover:ring-8 focus:bg-gray-900 focus:text-gray-300 focus:outline-none md:hidden"
		aria-label="toggle sidebar"
		@click="toggleMenu"
	>
		<span class="flex flex-col items-center" v-if="!isActive">
			<svg
				class="h-6 w-6"
				stroke="currentColor"
				fill="none"
				viewBox="0 0 24 24"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M4 6h16M4 12h16M4 18h7"
				/>
			</svg>
			<span class="text-2xs uppercase tracking-wide">Menu</span>
		</span>
		<span class="flex flex-col items-center" v-else>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-6 w-6"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M6 18L18 6M6 6l12 12"
				/>
			</svg>
			<span class="text-2xs uppercase tracking-wide">Close</span>
		</span>
	</button>
	<div class="h-full">
		<div
			class="fixed inset-0 z-40 flex transform transition duration-300 ease-in-out md:relative md:h-full md:shrink-0"
			:class="[
				isActive ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
			]"
		>
			<div class="fixed inset-0 flex flex-col md:relative md:h-full md:w-64">
				<div
					class="fixed inset-0 bg-gray-600 opacity-75 md:hidden"
					@click="closeMenu"
					v-show="isActive"
				></div>
				<div class="relative h-0 w-full max-w-xs flex-1 flex-col md:flex">
					<div class="flex h-full flex-1 flex-col overflow-y-auto">
						<nav class="flex-1 space-y-1 bg-gray-800 px-2 py-4">
							<router-link
								:to="{ name: item.routeName }"
								v-slot="{ isActive }"
								v-for="(item, index) in items"
								:key="index"
							>
								<span
									:class="[
										isActive
											? 'bg-gray-900 text-white'
											: 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700',
										'group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none'
									]"
								>
									<component
										:is="item.iconName"
										class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
									/>
									{{ item.displayName }}
								</span>
							</router-link>
							<div class="border-t border-solid border-gray-700 pt-6">
								<router-link
									:to="{ name: 'SiteConfig' }"
									v-slot="{ isActive }"
									v-if="permissions.includes('config') || isSuperAdmin"
								>
									<span
										:class="[
											isActive
												? 'bg-gray-900 text-white'
												: 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
										]"
										class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
									>
										<Cog6ToothIcon
											class="inline-block h-5 w-5"
											aria-hidden="true"
										/>
										<span class="ml-4">Site Configurations</span>
									</span>
								</router-link>
								<router-link
									to="#"
									class="block rounded-md p-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-gray-100"
								>
									<GlobeAltIcon
										class="inline-block h-5 w-5"
										aria-hidden="true"
									/>
									<span class="ml-2">&larr; Go to site</span>
								</router-link>
								<div
									class="block cursor-pointer rounded-md p-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-gray-100"
									@click="logout"
								>
									<ArrowLeftOnRectangleIcon
										class="inline-block h-5 w-5"
										aria-hidden="true"
									/>
									<span class="ml-4">Log Out</span>
								</div>
							</div>
						</nav>
					</div>
				</div>
				<div class="w-14 shrink-0 md:hidden">
					<!-- Dummy element to force sidebar to shrink to fit close icon -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	ArrowLeftOnRectangleIcon,
	ClipboardDocumentCheckIcon,
	Cog6ToothIcon,
	RectangleStackIcon,
	DocumentDuplicateIcon,
	DocumentTextIcon,
	FaceSmileIcon,
	ForwardIcon,
	GlobeAltIcon,
	HomeIcon,
	LinkIcon,
	MapIcon,
	Bars3BottomLeftIcon,
	PhotoIcon,
	TagIcon,
	UserCircleIcon,
	UsersIcon
} from '@heroicons/vue/24/outline'
import { mapStores } from 'pinia'
import { useUsersStore } from '@/stores/users'
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'AdminSidebar',
	created() {
		this.usersStoreSubscription()
	},
	components: {
		ArrowLeftOnRectangleIcon,
		ClipboardDocumentCheckIcon,
		Cog6ToothIcon,
		RectangleStackIcon,
		DocumentDuplicateIcon,
		DocumentTextIcon,
		FaceSmileIcon,
		ForwardIcon,
		GlobeAltIcon,
		HomeIcon,
		LinkIcon,
		MapIcon,
		Bars3BottomLeftIcon,
		PhotoIcon,
		TagIcon,
		UserCircleIcon,
		UsersIcon
	},
	data: () => ({
		isActive: true,
		items: [
			{
				routeName: 'AdminDashboard',
				displayName: 'Dashboard',
				iconName: 'HomeIcon'
			},
			{
				routeName: 'PagesIndex',
				displayName: 'Pages',
				iconName: 'DocumentTextIcon'
			},
			{
				routeName: 'PostsIndex',
				displayName: 'Posts',
				iconName: 'DocumentDuplicateIcon'
			},
			{
				routeName: 'Tags',
				displayName: 'Tags',
				iconName: 'TagIcon'
			},
			{
				routeName: 'Media',
				displayName: 'Media',
				iconName: 'PhotoIcon'
			},
			{
				routeName: 'Menus',
				displayName: 'Menus',
				iconName: 'Bars3BottomLeftIcon'
			},
			{
				routeName: 'AccordionsIndex',
				displayName: 'Accordions',
				iconName: 'RectangleStackIcon'
			},
			{
				routeName: 'LeadershipIndex',
				displayName: 'Leadership',
				iconName: 'UserCircleIcon'
			},
			{
				routeName: 'FormsIndex',
				displayName: 'Forms',
				iconName: 'ClipboardDocumentCheckIcon'
			},
			{
				routeName: 'SlidesIndex',
				displayName: 'Slides',
				iconName: 'ForwardIcon'
			},
			{
				routeName: 'ServiceAreasIndex',
				displayName: 'Service Areas',
				iconName: 'MapIcon'
			},
			{
				routeName: 'AmenitiesIndex',
				displayName: 'Amenities',
				iconName: 'FaceSmileIcon'
			},
			{
				routeName: 'LinksManager',
				displayName: 'Links',
				iconName: 'LinkIcon'
			},
			{
				routeName: 'Users',
				displayName: 'Users',
				iconName: 'UsersIcon'
			}
		]
	}),
	computed: {
		...mapStores(useUsersStore),
		permissions() {
			// if (this.$store.getters['User/permissions']) {
			// 	return Object.keys(this.$store.getters['User/permissions'])
			// }
			return []
		},
		isSuperAdmin() {
			return true
			// return (
			// 	this.$store.getters['User/role'] &&
			// 	this.$store.getters['User/role'] === 'superadmin'
			// )
		}
	},
	methods: {
		logout() {
			this.usersStore.logout()
		},
		closeMenu() {
			// this.$store.commit('toggleMenu', false)
		},
		toggleMenu() {
			this.isActive = !this.isActive
		},
		usersStoreSubscription() {
			this.usersStore.$onAction(({ name }) => {
				if (name === 'logout' && this.$route.name !== 'Login') {
					this.$router.push({ name: 'Login' })
				}
			})
		}
	}
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
