<template>
	<div
		class="flex h-full flex-1 items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8"
	>
		<router-link
			to="/login"
			class="absolute right-3 top-3 text-cobalt underline"
			>Login</router-link
		>
		<div class="w-full max-w-md space-y-8">
			<h2
				class="mt-6 text-center text-3xl font-extrabold text-kta-blue"
				v-text="
					$route.name === 'PasswordReset'
						? 'Reset Password'
						: 'Verify Registration'
				"
			></h2>
			<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div
					class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
					v-show="!verificationSent"
				>
					<form @submit.prevent="sendVerification">
						<label class="mb-1 block text-sm leading-5">
							<span>Email</span>
							<input
								v-model="email"
								type="text"
								class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm sm:text-sm"
							/>
						</label>
						<button
							type="submit"
							class="relative mt-4 w-full rounded-md border bg-kta-blue py-2 px-4 text-center text-sm font-medium text-white hover:bg-cobalt focus:outline-none focus:ring-2 focus:ring-sky focus:ring-offset-2"
						>
							Submit
						</button>
					</form>
				</div>
				<div
					class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
					v-show="verificationSent"
				>
					<form @submit.prevent="updatePassword" class="space-y-5">
						<div>
							<label class="mb-4 block text-sm">Verification Code</label>
							<div
								class="flex items-center justify-center space-x-2"
								ref="verificationInputs"
							>
								<input
									name="verificationCode1"
									@maska="updateCode"
									v-maska="'#'"
									type="text"
									inputmode="numeric"
									:class="[
										errors.verificationCode
											? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
											: 'focus:border-blue-500 focus:ring-blue-500',
										'block h-12 w-12 rounded-md border-gray-300 text-center text-2xl shadow-sm'
									]"
								/>
								<input
									name="verificationCode2"
									@maska="updateCode"
									v-maska="'#'"
									type="text"
									inputmode="numeric"
									:class="[
										errors.verificationCode
											? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
											: 'focus:border-blue-500 focus:ring-blue-500',
										'block h-12 w-12 rounded-md border-gray-300 text-center text-2xl shadow-sm'
									]"
								/>
								<input
									name="verificationCode3"
									@maska="updateCode"
									v-maska="'#'"
									type="text"
									inputmode="numeric"
									:class="[
										errors.verificationCode
											? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
											: 'focus:border-blue-500 focus:ring-blue-500',
										'block h-12 w-12 rounded-md border-gray-300 text-center text-2xl shadow-sm'
									]"
								/>
								<input
									name="verificationCode4"
									@maska="updateCode"
									v-maska="'#'"
									type="text"
									inputmode="numeric"
									:class="[
										errors.verificationCode
											? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
											: 'focus:border-blue-500 focus:ring-blue-500',
										'block h-12 w-12 rounded-md border-gray-300 text-center text-2xl shadow-sm'
									]"
								/>
								<input
									name="verificationCode5"
									@maska="updateCode"
									v-maska="'#'"
									type="text"
									inputmode="numeric"
									:class="[
										errors.verificationCode
											? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
											: 'focus:border-blue-500 focus:ring-blue-500',
										'block h-12 w-12 rounded-md border-gray-300 text-center text-2xl shadow-sm'
									]"
								/>
								<input
									name="verificationCode6"
									@maska="updateCode"
									v-maska="'#'"
									type="text"
									inputmode="numeric"
									:class="[
										errors.verificationCode
											? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
											: 'focus:border-blue-500 focus:ring-blue-500',
										'block h-12 w-12 rounded-md border-gray-300 text-center text-2xl shadow-sm'
									]"
								/>
							</div>
							<p
								class="mt-1 text-sm text-red-600"
								v-if="errors.verificationCode"
							>
								{{ errors.verificationCode }}
							</p>
						</div>
						<TextInput
							ref="password"
							v-model="password"
							label="New Password"
							:error="errors.password"
							type="password"
						/>
						<TextInput
							v-model="confirmPassword"
							label="Confirm Password"
							:error="errors.confirmPassword"
							type="password"
						/>
						<button
							type="submit"
							class="relative w-full rounded-md border border-transparent bg-kta-blue py-2 px-4 text-center text-sm font-medium text-white hover:bg-cobalt focus:outline-none focus:ring-2 focus:ring-sky focus:ring-offset-2"
						>
							Submit
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { maska } from 'maska'
import isEmpty from 'lodash/isEmpty'
import { mapStores } from 'pinia'
import { useUsersStore } from '@/stores/users'
import { defineComponent } from 'vue'
import { useMainStore } from '../stores/main'
import TextInput from '@/components/utilities/form/TextInput.vue'

export default defineComponent({
	name: 'PasswordReset',
	created() {
		this.verificationSent = this.$route.name === 'Verify'
	},
	directives: { maska },
	data: () => ({
		email: '',
		verificationSent: false,
		inputs: null,
		verificationCode: '',
		password: '',
		confirmPassword: ''
	}),
	computed: {
		...mapStores(useUsersStore, useMainStore),
		errors() {
			return this.mainStore.errors
		}
	},
	methods: {
		updateCode(e) {
			if (!e.target.value.length) return
			this.verificationCode += e.target.value
			const items = Array.from(this.inputs)
			const index = items.findIndex((item) => item.name === e.target.name)
			if (this.inputs[index + 1]) {
				this.inputs[index + 1].focus()
			}
		},
		checkEmpty(object) {
			return isEmpty(object)
		},
		async sendVerification() {
			this.verificationSent = await this.usersStore.forgotPassword(this.email)
		},
		async updatePassword() {
			const link = await this.usersStore.resetPassword({
				verificationCode: this.verificationCode,
				password: this.password,
				confirmPassword: this.confirmPassword
			})
			this.$router.push(link)
		}
	},
	watch: {
		verificationSent(value) {
			if (value) {
				this.inputs = document.querySelectorAll('input')
			}
		},
		errors: {
			handler(value) {
				if (value.message) {
					this.$store.commit('displayAlert', {
						position: 'top',
						level: 'error',
						text: value.message,
						timer: 3000
					})
				}
			},
			deep: true
		}
	}
})
</script>
