import { File } from '@/types/media'

type User = {
	id: number
	email: string
	firstName: string
	lastName: string
	created: string
	updated: string
}

type History = {
	id: number
	from: string
	to: string
	user: User
	created: string
}

type Image = null | File

export class Page {
	id?: null | number = null
	headerTitle?: string = ''
	slug?: string = ''
	metaDescription?: string = ''
	body?: string = ''
	headerImage?: Image = null
	headerImageId: null | number = null
	history?: History[] = []
	published?: null | string = ''
	created?: string = ''
	updated?: string = ''
}
