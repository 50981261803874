<template>
	<PageLayout :title="pageTitle">
		<div v-if="isIndex">
			<div
				:class="[
					filter ? 'justify-between' : 'justify-end',
					'mb-4 flex items-center'
				]"
			>
				<span v-if="filter">{{ upperCaseFirst(filter) }}</span>
				<button
					v-if="sorted"
					@click="updateIndexes"
					class="focus:shadow-outline-gray mr-2 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Save Order
				</button>
				<router-link
					:to="{ name: 'SlidesCreate' }"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
			<div class="overflow-hidden bg-white shadow sm:rounded-md">
				<div class="p-4 italic text-gray-500" v-if="slides.length < 1">
					No slides found.
				</div>
				<draggable
					v-else
					tag="ul"
					handle=".handleRow"
					item-key="index"
					:list="slides"
					@change="sorted = true"
				>
					<template #item="{ element, index }">
						<li
							:class="[
								filter === getStatus(element) ? '' : '',
								index > 0 ? 'border-t border-gray-200' : '',
								'flex items-center'
							]"
						>
							<span
								class="handleRow flex h-20 items-center bg-gray-300 p-3 lg:h-12"
							>
								<Bars3Icon class="h-5 w-5" />
							</span>
							<span
								:class="[
									{ 'bg-blue-50': getStatus(element) === 'active' },
									{ 'bg-amber-50': getStatus(element) === 'scheduled' },
									{ 'bg-red-50': getStatus(element) === 'expired' },
									'grow'
								]"
							>
								<router-link
									:to="{
										name: 'SlidesEdit',
										params: { id: element.id }
									}"
									:class="[
										{ 'hover:bg-gray-100': getStatus(element) === 'draft' },
										{ 'hover:bg-blue-100': getStatus(element) === 'active' },
										{
											'hover:bg-amber-100': getStatus(element) === 'scheduled'
										},
										{ 'hover:bg-red-100': getStatus(element) === 'expired' },
										'flex items-center justify-between transition duration-150 ease-in-out focus:bg-gray-50 focus:outline-none'
									]"
								>
									<div
										class="block flex-1 items-center justify-between px-4 py-3 text-sm leading-6 text-gray-700 sm:px-6 lg:flex"
									>
										<div>
											<span
												class="mr-2 text-xs font-light uppercase text-gray-400"
												>Name</span
											>
											<span class="font-semibold text-gray-900">{{
												element.name
											}}</span>
										</div>
										<div>
											<span
												class="text-xs font-light uppercase text-gray-400"
												v-text="getStatus(element)"
											>
											</span>
										</div>
									</div>
									<div class="pr-4">
										<svg
											class="h-5 w-5 text-gray-400"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
											fill="currentColor"
											aria-hidden="true"
										>
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											/>
										</svg>
									</div>
								</router-link>
							</span>
						</li>
					</template>
				</draggable>
			</div>
			<div class="mt-4 flex justify-end">
				<button
					v-if="sorted"
					@click="updateIndexes"
					class="focus:shadow-outline-gray mr-2 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Save Order
				</button>
				<router-link
					:to="{ name: 'SlidesCreate' }"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
		</div>
		<router-view v-else />
	</PageLayout>
</template>

<script>
import { mapStores } from 'pinia'
import { useSlidesStore } from '@/stores/slides'
import { useMainStore } from '@/stores/main'
import { Bars3Icon } from '@heroicons/vue/24/solid'
import { getTime } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import draggable from 'vuedraggable'
export default {
	name: 'SlidesIndex',
	components: {
		Bars3Icon,
		draggable
	},
	beforeRouteUpdate() {
		this.slidesStore.fetchAll()
	},
	created() {
		this.slidesStore.fetchAll()
	},
	data: () => ({
		filter: '',
		sorted: false
	}),
	methods: {
		getStatus(element) {
			if (!element.published && !element.expires) {
				return 'draft'
			}
			if (this.isScheduled(element)) {
				return 'scheduled'
			}
			if (this.isExpired(element)) {
				return 'expired'
			}
			if (this.isActive(element)) {
				return 'active'
			}
			return 'error'
		},
		isActive(element) {
			const published = getTime(new Date(element.published))
			const expires = getTime(new Date(element.expires))
			return expires
				? published <= Date.now() && Date.now() < expires
				: published <= Date.now()
		},
		isExpired(element) {
			const expires = getTime(new Date(element.expires))
			return expires ? Date.now() >= expires : false
		},
		isScheduled(element) {
			const published = getTime(new Date(element.published))
			return published > Date.now()
		},
		updateIndexes() {
			this.slidesStore.reorder()
			this.sorted = false

			this.mainStore.displayAlert({
				level: 'success',
				title: 'Order saved.',
				timer: 3000
			})
		},
		upperCaseFirst(word) {
			return upperFirst(word)
		}
	},
	computed: {
		...mapStores(useSlidesStore, useMainStore),
		isIndex() {
			return this.$route.name === 'SlidesIndex'
		},
		pageTitle() {
			if (this.isIndex) {
				return 'Slides'
			}
			return this.$route.params.id ? 'Edit Slides' : 'Create Slide'
		},
		slides() {
			return this.slidesStore.getSlides
		}
	}
}
</script>
