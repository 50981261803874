<template>
	<div class="pt-2 pb-6 md:py-6">
		<div class="mx-auto mb-4 max-w-7xl px-4 sm:px-6 md:px-8">
			<h1 class="text-2xl font-semibold text-gray-900">Tags</h1>
		</div>
		<div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8" v-if="editing">
			<label class="block text-sm font-medium leading-5 text-gray-700"
				>Edit Tag</label
			>
			<div class="mt-1 flex rounded-md shadow-sm">
				<div class="relative flex-grow focus-within:z-10">
					<div
						class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
						:class="{ 'text-red-500': errors }"
					>
						<svg
							class="h-5 w-5"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
							/>
						</svg>
					</div>
					<input
						type="text"
						v-model="editing.name"
						@keypress.enter="update"
						@keypress.esc="cancel"
						class="block w-full rounded-md border-gray-300 pl-10 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:class="{
							'focus:shadow-outline-red border-red-300 text-red-900 placeholder-red-300 focus:border-red-300':
								errors
						}"
					/>
				</div>
			</div>
			<div class="mt-3 flex items-center justify-end">
				<span class="mr-3 inline-flex rounded-md shadow-sm">
					<button
						@click="update"
						type="button"
						class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
					>
						Update
					</button>
				</span>
				<span class="inline-flex rounded-md shadow-sm">
					<button
						@click="cancel"
						type="button"
						class="focus:shadow-outline-kta-blue inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
					>
						Cancel
					</button>
				</span>
			</div>
			<p
				v-if="errors"
				class="mt-2 text-sm text-red-600"
				id="tags-error"
				v-text="errors.tags"
			></p>
		</div>
		<div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8" v-else>
			<label class="block text-sm font-medium leading-5 text-gray-700"
				>Add Tags</label
			>
			<div class="mt-1 flex rounded-md shadow-sm">
				<div class="relative flex-grow focus-within:z-10">
					<div
						class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
						:class="{ 'text-red-500': errors }"
					>
						<svg
							class="h-5 w-5"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
							/>
						</svg>
					</div>
					<input
						type="text"
						v-model="newTags"
						@keypress.enter="add"
						class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 shadow-sm sm:text-sm sm:leading-5"
						:class="{
							'focus:shadow-outline-red border-red-300 text-red-900 placeholder-red-300 focus:border-red-300':
								errors
						}"
						placeholder="Human Resources, finance, ceo, ..."
					/>
					<div
						class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
						v-show="errors"
					>
						<svg
							class="h-5 w-5 text-red-500"
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path
								fill-rule="evenodd"
								d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
				<button
					class="focus:shadow-outline-kta-blue relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-8 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-white hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-100 active:text-gray-700"
					@click="add"
				>
					Add
				</button>
			</div>
			<p
				class="mt-2 text-sm text-red-600"
				id="tags-error"
				v-show="errors"
				v-text="errorMessage"
			></p>
			<p class="mt-2 text-sm text-gray-500" id="tags-description">
				Add one or more tags using a comma-separated list.
			</p>
		</div>

		<div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
			<p class="mt-6 text-sm italic text-gray-500" v-if="!hasTags">
				There are currently no tags.
			</p>
			<ul
				class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
			>
				<li
					class="col-span-1 flex rounded-md shadow-sm"
					v-for="tag in tags"
					:key="tag.id"
				>
					<div
						class="flex flex-1 items-center justify-between rounded-md border border-gray-200 bg-white"
					>
						<div class="flex-1 truncate px-4 py-2 text-sm leading-5">
							<div class="font-medium text-gray-900">{{ tag.name }}</div>
							<p class="text-gray-500">
								{{ tag.itemCount || 0 }} Current Items
							</p>
						</div>
						<div
							class="absolute inset-0"
							v-if="active === tag.id"
							@click="toggleActive"
						></div>
						<div class="relative shrink-0 pr-2">
							<button
								@click="toggleActive(tag.id)"
								class="relative z-0 inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
							>
								<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
									<path
										d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
									/>
								</svg>
							</button>
							<transition name="expand">
								<div
									class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md shadow-lg"
									v-show="active === tag.id"
								>
									<div
										class="shadow-xs rounded-md bg-white py-1"
										role="menu"
										aria-orientation="vertical"
										aria-labelledby="user-menu"
									>
										<button
											type="button"
											class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
											role="menuitem"
											@click="edit(tag)"
										>
											Edit
										</button>
										<button
											type="button"
											class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
											role="menuitem"
											@click="remove(tag.id)"
										>
											Remove
										</button>
									</div>
								</div>
							</transition>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useTagsStore } from '@/stores/tags'
export default {
	title: 'Tags - iKTA',
	name: 'TagsManager',
	created() {
		this.tagsStore.fetchAll()
	},
	data() {
		return {
			newTags: '',
			existingTags: [],
			active: null,
			editing: null
		}
	},
	methods: {
		toggleActive(id) {
			this.active === id ? (this.active = null) : (this.active = id)
		},
		cancel() {
			this.editing = null
		},
		edit(tag) {
			this.active = null
			this.editing = structuredClone(tag)
		},
		remove(id) {
			this.tagsStore.remove(id)
			this.active = null
		},
		update() {
			this.tagsStore.update(this.editing)
			this.editing = null
		},
		add() {
			this.tagsStore.create(this.newTags)
			this.newTags = ''
		}
	},
	computed: {
		...mapStores(useTagsStore),
		tags() {
			return this.tagsStore.tags
		},
		errors() {
			return null
		},
		hasTags() {
			return this.tags.length
		},
		errorMessage() {
			if (this.errors) {
				if ('tags' in this.errors) return this.errors.tags
				if ('message' in this.errors) return this.errors.message
			}
			return false
		}
	},
	watch: {
		newTags() {
			if (this.errors) {
				if ('tags' in this.errors) this.$store.commit('removeError', 'tags')
				if ('message' in this.errors)
					this.$store.commit('removeError', 'message')
			}
		}
	}
}
</script>
<style scoped>
.expand-enter-active,
.expand-leave-active {
	transition: transform 0.5s, opacity 0.5s;
}
.expand-enter,
.expand-leave-to {
	opacity: 0;
	transform: scale(0.95);
}
</style>
