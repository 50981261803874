
import { mapStores } from 'pinia'
import { useUsersStore } from '@/stores/users'
import { defineComponent } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { Level, User } from '@/types/users'
import { useMainStore } from '@/stores/main'
import { format } from 'date-fns'
import { Permissions } from '../../types/users'
type Data = {
	loaded: boolean
	original: User
	user: User
}

export default defineComponent({
	name: 'EditUser',
	async created() {
		if (this.$route.name === 'EditUser') {
			this.user = await this.usersStore.fetchOne(+this.$route.params.id)
			this.original = cloneDeep(this.user)
		}
		await this.usersStore.fetchPermissions()
		this.loaded = true
	},
	data: (): Data => ({
		loaded: false,
		original: new User(),
		user: new User()
	}),
	methods: {
		checkPermissionsState() {
			if (this.user.role === 'user') {
				this.user.permissions =
					this.original.role === 'user'
						? this.original.permissions
						: Object.keys(this.permissions).reduce(
								(obj: Permissions, key): Permissions => {
									obj[key] = []
									return obj
								},
								{}
						  )
			}
		},
		updatePermissions(type: string, action: Level) {
			if (!this.user.permissions[type].includes('read')) {
				action !== 'read'
					? this.user.permissions[type].push('read')
					: (this.user.permissions[type] = [])
			}
		},
		formatPhone(number: string) {
			const parts = number.match(/^(\d{3})(\d{3})(\d{4})$/)
			return parts ? `(${parts[1]}) ${parts[2]}-${parts[3]}` : number
		},
		async save() {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { id, created, updated, status, ...rest } = this.user
			const success = id
				? await this.usersStore.update(id, {
						id,
						status: this.original.status === 'pending' ? 'pending' : status,
						...rest
				  })
				: await this.usersStore.create({ ...rest })
			if (success) {
				await this.usersStore.fetchAll()
				this.$router.push({ name: 'Users' })
			}
		},
		async resendVerification() {
			if (this.user.email) {
				await this.usersStore.forgotPassword(this.user.email)
				this.mainStore.displayAlert({
					position: 'top-right',
					level: 'success',
					text: 'Verification sent.',
					timer: 3000
				})
				this.$router.push({ name: 'Users' })
			}
		},
		formatDate(date: string): string {
			return date ? format(new Date(date), 'yyyy-mm-dd') : ''
		}
	},
	computed: {
		...mapStores(useMainStore, useUsersStore),
		editing() {
			return this.$route.name === 'EditUser'
		},
		permissions() {
			return this.usersStore.getPermissions
		},
		edited() {
			return !isEqual(this.user, this.original)
		},
		statusColor() {
			if (this.user.status === 'active')
				return 'bg-green-200 text-green-600 border-green-300'
			if (this.user.status === 'pending')
				return 'bg-yellow-200 text-yellow-700 border-yellow-400'
			return 'bg-red-200 text-red-800 border-red-00'
		}
	}
})
