<template>
	<div class="flex items-start space-x-8">
		<div>
			<span class="relative mb-2">
				<TextInput
					label="Label"
					v-model="computedProps.label"
					:readonly="computedProps.options < 2"
				/>
				<FormCheckbox
					name="boldLabel"
					label="Bold"
					v-model="computedProps.boldLabel"
					class="absolute top-0 right-0"
				/>
			</span>
			<span class="relative mb-4">
				<TextInput
					label="Description"
					v-model="computedProps.description"
					:readonly="computedProps.options.length < 2"
				/>
				<FormCheckbox
					name="boldDescription"
					label="Bold"
					v-model="computedProps.boldDescription"
					class="absolute top-0 right-0"
				/>
			</span>
			<FormSelect
				label="Column Width"
				:options="[...Array(12)].map((item, index) => index + 1)"
				v-model="computedProps.width"
			/>
			<FormToggle label="Required" v-model="computedProps.required" />
		</div>
		<div class="space-y-1">
			<label class="block">Orientation</label>
			<label class="block"
				><input
					type="radio"
					class="mr-2"
					value="stacked"
					v-model="orientation"
				/>Stacked</label
			>
			<label class="block">
				<input
					type="radio"
					class="mr-2"
					value="inline"
					v-model="orientation"
				/>Inline</label
			>
		</div>
		<div class="grid grid-cols-3 gap-3">
			<label>Value(s)</label>
			<label>Alt Display Text</label>
			<span></span>

			<div class="col-span-3">
				<span
					v-for="(option, index) in computedProps.options"
					:key="index"
					class="grid grid-cols-3 gap-3"
				>
					<TextInput
						v-model="option.optionValue"
						:error="errors?.options?.[index]?.optionValue"
						@removeError="
							$emit('removeError', ['options', index, 'optionValue'])
						"
					/>
					<TextInput
						v-model="option.optionName"
						:error="errors?.options?.[index]?.optionName"
					/>
					<span class="mb-5 flex">
						<XMarkIcon
							v-if="computedProps.options.length > 1"
							class="h-4 w-4 cursor-pointer self-center text-gray-700 hover:text-gray-500"
							@click="removecheckbox(index)"
						/>
					</span>
				</span>
			</div>
			<div class="col-span-2 w-full text-right">
				<button
					type="button"
					class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
					@click="addcheckbox"
				>
					Add Option
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { FormToggle } from '@/components/utilities/form'
import { XMarkIcon } from '@heroicons/vue/24/solid'
export default {
	name: 'RadioInputSettings',
	props: {
		props: {
			type: Object,
			required: true
		},
		errors: {
			type: Object,
			required: false
		}
	},
	emits: ['updateProps'],
	components: {
		FormToggle,
		XMarkIcon
	},
	computed: {
		computedProps() {
			return this.props
		},
		orientation: {
			get() {
				return this.props.orientation
			},
			set(value) {
				this.updateProps(value, 'orientation')
			}
		}
	},
	methods: {
		addRadio() {
			this.options.push({ optionValue: '', optionName: '' })
		},
		removeRadio(index) {
			this.options.splice(index, 1)
		},
		updateProps(newVal, prop, index = null) {
			const newProps = this.props
			if (Number.isInteger(index)) {
				newProps.options[index][prop] = newVal
			} else {
				newProps[prop] = newVal
			}

			this.$emit('updateProps', newProps)
		}
	}
}
</script>
