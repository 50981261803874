<template>
	<PageLayout :title="pageTitle">
		<div v-if="isIndex">
			<div class="mb-4 flex items-center justify-end">
				<router-link
					:to="{ name: 'FormsCreate' }"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
			<div class="overflow-hidden bg-white shadow sm:rounded-md">
				<div class="p-4 italic text-gray-500" v-if="forms.length < 1">
					No forms found.
				</div>
				<ul>
					<li
						v-for="(form, index) in forms"
						:key="index"
						:class="[index > 0 ? 'border-t border-gray-200' : '']"
					>
						<div
							:class="[
								{ 'bg-blue-50': getStatus(form) === 'active' },
								{ 'bg-amber-50': getStatus(form) === 'scheduled' },
								{ 'bg-red-50': getStatus(form) === 'expired' },
								'flex items-center justify-between transition duration-150 ease-in-out'
							]"
						>
							<div
								class="block flex-1 items-center justify-between px-4 py-3 text-sm leading-6 text-gray-700 sm:px-6 lg:flex"
							>
								<div>
									<span class="mr-2 text-xs font-light uppercase text-gray-400"
										>Name</span
									>
									<span class="font-semibold text-gray-900">{{
										form.name
									}}</span>
									<span
										class="ml-4 mr-2 inline-block text-xs font-light uppercase text-gray-400"
										>Submissions</span
									>
									<span class="font-semibold text-gray-900">{{
										form.submissions.length
									}}</span>
								</div>
								<div>
									<span
										class="text-xs font-light uppercase text-gray-400"
										v-text="getStatus(form)"
									>
									</span>
								</div>
							</div>
							<div
								class="flex h-12 cursor-pointer items-center border-l py-3 px-4 text-gray-500 hover:text-gray-700"
								title="Copy Shortcode"
								@click="generateShortcode(form.id)"
							>
								<ClipboardDocumentIcon class="h-5 w-5" />
							</div>
							<router-link
								:to="{ path: `/forms/${form.id}/submissions`, meta: form.id }"
								class="flex h-12 items-center border-l py-3 px-4 text-gray-500 hover:text-gray-700"
								title="Submissions"
							>
								<ClipboardDocumentCheckIcon class="h-5 w-5" />
							</router-link>
							<router-link
								:to="{
									name: 'FormsEdit',
									params: { id: form.id }
								}"
								class="flex h-12 items-center border-l py-3 px-4 text-gray-400 hover:text-gray-700"
							>
								<svg
									class="h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
										clip-rule="evenodd"
									/>
								</svg>
							</router-link>
						</div>
					</li>
				</ul>
			</div>
			<div class="mt-4 flex justify-end">
				<router-link
					:to="{ name: 'FormsCreate' }"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
		</div>
		<router-view v-else />
	</PageLayout>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormsStore } from '@/stores/forms'
import { useMainStore } from '@/stores/main'
import {
	ClipboardDocumentIcon,
	ClipboardDocumentCheckIcon
} from '@heroicons/vue/24/outline'
import { getTime } from 'date-fns'
export default {
	name: 'FormsIndex',
	components: {
		ClipboardDocumentIcon,
		ClipboardDocumentCheckIcon
	},
	beforeRouteUpdate() {
		this.formsStore.fetchAll()
	},
	created() {
		this.formsStore.fetchAll()
	},
	methods: {
		generateShortcode(id) {
			this.mainStore.copyClipboard(`[Form :id="${id}"]`)
		},
		getStatus(element) {
			if (!element.published && !element.expires) {
				return 'draft'
			}
			if (this.isScheduled(element)) {
				return 'scheduled'
			}
			if (this.isExpired(element)) {
				return 'expired'
			}
			if (this.isActive(element)) {
				return 'active'
			}
			return 'error'
		},
		isActive(element) {
			const published = getTime(new Date(element.published))
			const expires = getTime(new Date(element.expires))
			return expires
				? published <= Date.now() && Date.now() < expires
				: published <= Date.now()
		},
		isExpired(element) {
			const expires = getTime(new Date(element.expires))
			return expires ? Date.now() >= expires : false
		},
		isScheduled(element) {
			const published = getTime(new Date(element.published))
			return published > Date.now()
		}
	},
	computed: {
		...mapStores(useFormsStore, useMainStore),
		isIndex() {
			return this.$route.name === 'FormsIndex'
		},
		pageTitle() {
			if (this.isIndex) {
				return 'Forms'
			}
			if (this.$route.meta.type) return this.$route.meta.type
			return this.$route.params.id ? 'Edit Form' : 'Create Form'
		},
		forms() {
			return this.formsStore.forms
		}
	}
}
</script>
