<template>
	<PageLayout :title="pageTitle">
		<div v-if="isIndex">
			<div class="mb-4 flex items-center justify-end">
				<router-link
					:to="{ name: 'ServiceAreasCreate' }"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
			<div class="overflow-hidden bg-white shadow sm:rounded-md">
				<div class="p-4 italic text-gray-500" v-if="serviceAreas.length < 1">
					No service areas found.
				</div>
				<ul>
					<li
						v-for="(serviceArea, index) in serviceAreas"
						:key="index"
						:class="[index > 0 ? 'border-t border-gray-200' : '']"
					>
						<router-link
							:to="{
								name: 'ServiceAreasEdit',
								params: { id: serviceArea.id }
							}"
							class="flex items-center justify-between transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
						>
							<div
								class="block flex-1 items-center justify-between px-4 py-3 text-sm leading-6 text-gray-700 sm:px-6 lg:flex"
							>
								<div>
									<span class="mr-2 text-xs font-light uppercase text-gray-400"
										>Name</span
									>
									<span class="font-semibold text-gray-900">{{
										serviceArea.name
									}}</span>
								</div>
								<div>
									<span class="mr-2 text-xs font-light uppercase text-gray-400"
										>ID#
									</span>
									<span class="font-semibold text-gray-900">{{
										serviceArea.id
									}}</span>
								</div>
							</div>
							<div class="pr-4">
								<svg
									class="h-5 w-5 text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</router-link>
					</li>
				</ul>
			</div>
			<div class="mt-4 flex justify-end">
				<router-link
					:to="{ name: 'ServiceAreasCreate' }"
					class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
				>
					Create
				</router-link>
			</div>
		</div>
		<router-view v-else />
	</PageLayout>
</template>

<script>
import { mapStores } from 'pinia'
import { useServiceAreasStore } from '@/stores/serviceAreas'
export default {
	name: 'ServiceAreasIndex',
	beforeRouteUpdate() {
		this.serviceAreasStore.fetchAll()
	},
	created() {
		this.serviceAreasStore.fetchAll()
	},
	computed: {
		...mapStores(useServiceAreasStore),
		isIndex() {
			return this.$route.name === 'ServiceAreasIndex'
		},
		pageTitle() {
			if (this.isIndex) {
				return 'Service Areas'
			}
			return this.$route.params.id ? 'Edit Service Area' : 'Create Service Area'
		},
		serviceAreas() {
			return this.serviceAreasStore.serviceAreas
		}
	}
}
</script>
