import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PageNotFound from '@/views/PageNotFound.vue'

import AdminLogin from '@/views/AdminLogin.vue'
import AdminDashboard from '@/views/AdminDashboard.vue'
import AccordionsIndex from '@/views/Accordions/Index.vue'
import AccordionCreate from '@/views/Accordions/Create.vue'
import AmenitiesIndex from '@/views/Amenities/Index.vue'
import AmenitiesCreate from '@/views/Amenities/Create.vue'
import FormsIndex from '@/views/Forms/Index.vue'
import FormsCreate from '@/views/Forms/Create.vue'
import FormsSubmissionsIndex from '@/views/Forms/SubmissionsIndex.vue'
import FormsSubmission from '@/views/Forms/Submission.vue'
import LinksManager from '@/views/LinksManager.vue'
import LeadershipIndex from '@/views/Leadership/Index.vue'
import LeadershipCreate from '@/views/Leadership/Create.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import PagesIndex from '@/views/Pages/Index.vue'
import PagesCreate from '@/views/Pages/Create.vue'
import PageHistory from '@/views/Pages/History.vue'
import PostsIndex from '@/views/Posts/Index.vue'
import PostsCreate from '@/views/Posts/Create.vue'
import ServiceAreasIndex from '@/views/ServiceAreas/Index.vue'
import ServiceAreasCreate from '@/views/ServiceAreas/Create.vue'
import SlidesIndex from '@/views/Slides/Index.vue'
import SlidesCreate from '@/views/Slides/Create.vue'

import EditUser from '@/views/Users/Edit.vue'
import Media from '@/views/Media.vue'
import Menus from '@/views/Menus.vue'
import SiteConfig from '@/views/SiteConfig.vue'
import TagsManager from '@/views/TagsManager.vue'
import Users from '@/views/Users/Index.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'Login',
		component: AdminLogin
	},
	{
		path: '',
		name: 'AdminDashboard',
		component: AdminDashboard
	},
	{
		path: '/accordions',
		name: 'AccordionsIndex',
		component: AccordionsIndex,
		children: [
			{
				path: '/accordions/create',
				name: 'AccordionCreate',
				component: AccordionCreate
			},
			{
				path: '/accordions/:id',
				name: 'AccordionEdit',
				component: AccordionCreate
			}
		]
	},
	{
		path: '/amenities',
		name: 'AmenitiesIndex',
		component: AmenitiesIndex,
		children: [
			{
				path: '/amenities/create',
				name: 'AmenitiesCreate',
				component: AmenitiesCreate
			},
			{
				path: '/amenities/:id',
				name: 'AmenitiesEdit',
				component: AmenitiesCreate
			}
		]
	},
	{
		path: '/forms',
		name: 'FormsIndex',
		component: FormsIndex,
		children: [
			{
				path: '/forms/create',
				name: 'FormsCreate',
				component: FormsCreate
			},
			{
				path: '/forms/:id',
				name: 'FormsEdit',
				component: FormsCreate
			},
			{
				path: '/forms/:id/submissions',
				name: 'FormsSubmissionsIndex',
				meta: { type: 'Form Submissions' },
				component: FormsSubmissionsIndex
			},
			{
				path: '/forms/:id/submissions/:submitId',
				name: 'FormsSubmission',
				meta: { type: 'Form Submission' },
				component: FormsSubmission
			}
		]
	},
	{
		path: '/links',
		name: 'LinksManager',
		component: LinksManager
	},
	{
		path: '/leadership',
		name: 'LeadershipIndex',
		component: LeadershipIndex,
		children: [
			{
				path: '/leadership/create',
				name: 'LeadershipCreate',
				component: LeadershipCreate
			},
			{
				path: '/leadership/:id',
				name: 'LeadershipEdit',
				component: LeadershipCreate
			}
		]
	},
	{
		path: '/pages',
		name: 'PagesIndex',
		component: PagesIndex,
		children: [
			{
				path: 'create',
				name: 'PagesCreate',
				component: PagesCreate
			},
			{
				path: ':id',
				name: 'PagesEdit',
				component: PagesCreate
			},
			{
				path: ':id/history',
				name: 'PageHistory',
				component: PageHistory
			}
		]
	},
	{
		path: '/password-reset',
		name: 'PasswordReset',
		component: PasswordReset
	},
	{
		path: '/verify',
		name: 'Verify',
		component: PasswordReset
	},
	{
		path: '/posts',
		name: 'PostsIndex',
		component: PostsIndex,
		children: [
			{
				path: '/posts/create',
				name: 'PostsCreate',
				component: PostsCreate
			},
			{
				path: '/posts/:id',
				name: 'PostsEdit',
				component: PostsCreate
			}
		]
	},
	{
		path: '/service-areas',
		name: 'ServiceAreasIndex',
		component: ServiceAreasIndex,
		children: [
			{
				path: '/service-areas/create',
				name: 'ServiceAreasCreate',
				component: ServiceAreasCreate
			},
			{
				path: '/service-areas/:id',
				name: 'ServiceAreasEdit',
				component: ServiceAreasCreate
			}
		]
	},
	{
		path: '/slides',
		name: 'SlidesIndex',
		component: SlidesIndex,
		children: [
			{
				path: '/slides/create',
				name: 'SlidesCreate',
				component: SlidesCreate
			},
			{
				path: '/slides/:id',
				name: 'SlidesEdit',
				component: SlidesCreate
			}
		]
	},
	{
		path: '/menus',
		name: 'Menus',
		component: Menus
	},
	{
		path: '/media',
		name: 'Media',
		component: Media
	},
	{
		path: '/media/folder/:id',
		name: 'MediaFolder',
		component: Media
	},
	{
		path: '/config',
		name: 'SiteConfig',
		component: SiteConfig
	},
	{
		path: '/tags',
		name: 'Tags',
		component: TagsManager
	},
	{
		path: '/users',
		name: 'Users',
		component: Users,
		children: [
			{
				path: '/users/create',
				name: 'CreateUser',
				component: EditUser
			},
			{
				path: '/users/:id',
				name: 'EditUser',
				component: EditUser
			}
		]
	},
	{
		path: '/:url(.*)',
		name: 'PageNotFound',
		component: PageNotFound
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

import { useMainStore } from '@/stores/main'
import { useUsersStore } from '@/stores/users'

router.beforeEach(async (to, from, next) => {
	const mainStore = useMainStore()
	const usersStore = useUsersStore()
	mainStore.removeErrors()
	if (!usersStore.accessToken) {
		const accessToken = localStorage.getItem('accessToken') || ''
		const refreshToken = localStorage.getItem('refreshToken') || ''
		usersStore.setTokens({ accessToken, refreshToken })
	}
	if (!usersStore.isLoggedIn() && !usersStore.isRefreshable()) {
		usersStore.logout()
	}
	if (!usersStore.isLoggedIn() && usersStore.isRefreshable()) {
		await usersStore.refreshTokens()
		next()
	}
	if (
		!usersStore.isLoggedIn() &&
		to.name !== 'Login' &&
		to.name !== 'PasswordReset' &&
		to.name !== 'Verify'
	) {
		if (to.path !== '/') {
			sessionStorage.setItem('from', to.path)
		}
		next('/login')
	}
	if (
		(usersStore.isLoggedIn() && to.name === 'Login') ||
		(usersStore.isLoggedIn() && to.name === 'Verify') ||
		(usersStore.isLoggedIn() && to.name === 'PasswordReset')
	) {
		next({ name: 'AdminDashboard' })
	} else {
		next()
	}
})

export default router
