import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { useUsersStore } from './users'
import axios from 'axios'

export type State = {
	tags: Tag[]
}

export type Tag = {
	id?: number
	name?: string
	created: string
	updated: string
	itemCount: number
}

export const useTagsStore = defineStore('tags', {
	state: (): State => ({
		tags: []
	}),
	getters: {
		mainStore() {
			return useMainStore()
		},
		userConfig() {
			const user = useUsersStore()
			return user.config
		},
		usersStore() {
			return useUsersStore()
		}
	},
	actions: {
		async fetchAll() {
			await this.usersStore.checkLogin()
			try {
				const { data } = await axios.get(
					`${process.env.VUE_APP_API_URL}/tags`,
					this.userConfig
				)
				this.tags = data
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async create(newTags: any) {
			await this.usersStore.checkLogin()
			const temp = newTags.replace(/,\s+/g, ',')
			const tags = temp.split(',')
			try {
				const { data } = await axios.post(
					`${process.env.VUE_APP_API_URL}/tags`,
					{ tags },
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Tag created.',
					timer: 3000
				})
				this.tags = [...this.tags, ...data]
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async update(tag: Partial<Tag>) {
			await this.usersStore.checkLogin()
			try {
				await axios.patch(
					`${process.env.VUE_APP_API_URL}/tags/${tag.id}`,
					{
						name: tag.name
					},
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Tag updated.',
					timer: 3000
				})

				const index = this.tags.findIndex(
					(item: Partial<Tag>) => item.id === tag.id
				)
				this.tags[index].name = tag.name
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		async remove(id: number) {
			await this.usersStore.checkLogin()
			try {
				await axios.delete(
					`${process.env.VUE_APP_API_URL}/tags/${id}`,
					this.userConfig
				)
				this.mainStore.displayAlert({
					level: 'success',
					title: 'Tag deleted.',
					timer: 3000
				})
				this.tags = this.tags.filter((tag) => tag.id !== id)
			} catch (error: any) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	}
})
