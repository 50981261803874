<template>
	<form @submit.prevent>
		<div>
			<div class="grid grid-cols-1 gap-4 md:grid-cols-2">
				<TextInput
					v-model="page.headerTitle"
					label="Title"
					:error="errors?.headerTitle"
					@keyup="updateSlug"
					@removeError="removeError(['headerTitle'])"
				/>
				<TextInput
					v-model="page.slug"
					label="Slug"
					:error="errors?.slug"
					@keypress="disableDynamicSlug"
					@removeError="removeError(['slug'])"
				/>
			</div>
			<div>
				<FormTextarea
					label="Meta Description (Social Media)"
					v-model="page.metaDescription"
					:error="errors?.metaDescription"
					@removeError="removeError(['metaDescription'])"
					:minLength="70"
					:maxLength="155"
					showCharacterCount
				/>
			</div>
			<SelectImage
				:image="page.headerImage"
				:imageId="page.headerImageId"
				:error="errors?.headerImageId"
				imageSize="large"
				sizeDisclaimer="1600px x 400px"
				@updateImage="updateImage"
				@updateImageId="updateImageId"
				@removeError="removeError(['headerImageId'])"
			/>
			<div class="mt-6">
				<label class="mb-1 block text-sm font-medium leading-5 text-gray-700">
					Body
				</label>
				<WysiwygEditor
					v-model="page.body"
					:error="errors?.body"
					@removeError="removeError(['body'])"
				/>
			</div>
			<DateSelector
				class="mt-4"
				v-model="page.published"
				label="Publish"
				includeSwitch
				includeTime
				:error="errors?.published"
				@removeError="removeError(['published'])"
			/>
		</div>
		<div class="mt-8">
			<ManageControls
				@cancel="cancel"
				@remove="remove"
				@submit="submit"
				:lastEdit="lastEdit"
			/>
		</div>
	</form>
</template>

<script>
import { mapStores } from 'pinia'
import { usePagesStore } from '@/stores/pages'
import { useMainStore } from '@/stores/main'
import { format } from 'date-fns'
import last from 'lodash/last'
import DateSelector from '@/components/utilities/form/DateSelector'
import ManageControls from '@/components/utilities/ManageControls'
import SelectImage from '@/components/utilities/form/SelectImage'
import WysiwygEditor from '@/components/utilities/form/WysiwygEditor.vue'
import FormTextarea from '@/components/utilities/form/FormTextarea.vue'

export default {
	name: 'CreateEditPage',
	async created() {
		if (this.$route.params.id) {
			await this.pagesStore.fetchOne(this.$route.params.id)
		}
	},
	beforeUnmount() {
		this.pagesStore.clearActive()
	},
	data: () => ({
		filePickerActive: false
	}),
	methods: {
		disableDynamicSlug() {
			this.pagesStore.dynamicSlug = false
		},
		updateSlug() {
			this.pagesStore.updateSlug()
			this.$forceUpdate()
		},
		updateImage(image) {
			this.page.headerImage = { ...image }
		},
		updateImageId(id) {
			this.page.headerImageId = id
		},
		cancel() {
			this.$router.push({ name: 'PagesIndex' })
		},
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async remove() {
			await this.pagesStore.remove(this.page.id)
			this.cancel()
		},
		async submit() {
			const success = this.editing
				? await this.pagesStore.update()
				: await this.pagesStore.create()

			if (success) this.cancel()
		}
	},
	computed: {
		...mapStores(usePagesStore, useMainStore),
		editing() {
			return !!this.$route.params.id
		},
		page() {
			return this.pagesStore.getActivePage
		},
		errors() {
			return this.mainStore.errors
		},
		lastEdit() {
			if (this.page.history.length) {
				const lastEdit = last(this.page.history)?.created
				return format(new Date(lastEdit), 'MM/dd/yyyy')
			}
			return false
		}
	},
	components: {
		DateSelector,
		FormTextarea,
		ManageControls,
		SelectImage,
		WysiwygEditor
	}
}
</script>
